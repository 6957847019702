<template>
    <div class="page-tab user-tab">
        <div class="page-tab__title__field">
            <h1 class="page-tab__title">Редактирование пользователя</h1>
            <c-button
                class="page-tab__title__back-btn"
                @click="goBack"
                text="Назад"
                buttonStyle="transparent"
            >
            </c-button>
        </div>

        <div class="page-tab__tile">
            <div class="page-tab__group page-tab__group_main">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title">Основные данные</span>
                </div>

                <div class="page-tab__group__content">
                    <c-input
                        label="Имя"
                        class="page-tab__edit__input page-tab__edit__input_name"
                        v-model.trim="udata.name"
                        :err="error.name"
                    ></c-input>

                    <c-input
                        label="Телефон"
                        class="page-tab__edit__input page-tab__edit__input_phone"
                        inputType="phone"
                        v-model.trim="udata.phone"
                        :err="error.phone"
                    ></c-input>

                    <c-select
                        class="page-tab__edit__input page-tab__edit__input_role"
                        label="Роль"
                        :options="view.roles"
                        selectOptionField="value"
                        viewLabel="text"
                        v-model="udata.role"
                    ></c-select>

                    <div class="avatar__field">
                        <label class="avatar__label">Аватар</label>
                        <image-picker
                            class="avatar__picker"
                            @input="setAvatar"
                            @error="errorOfUploadAvatar"
                            :preview="avatarPreview"
                        ></image-picker>
                    </div>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_address">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title">Местоположение</span>
                </div>
                <div class="page-tab__group__content">
                    <c-input
                        label="Город"
                        v-model.trim="udata.city"
                        class="page-tab__edit__input page-tab__edit__input_city"
                    ></c-input>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_settings">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title"
                        >Настройка уведомлений</span
                    >
                </div>

                <div class="page-tab__group__content">
                    <number-input
                        label="Радиус SOS сигналов"
                        class="page-tab__edit__input page-tab__edit__input_radiusOfSosRequest"
                        postFix="м"
                        :min="0"
                        v-model.number="udata.radiusOfSosRequest"
                    ></number-input>

                    <number-input
                        label="Радиус получения уведомлений о событиях"
                        class="page-tab__edit__input page-tab__edit__input_radiusOfEventRequest"
                        postFix="м"
                        :min="0"
                        v-model.number="udata.radiusOfEventRequest"
                    ></number-input>

                    <div class="checkbox-container">
                        <checkbox v-model="udata.pushOfEvents"></checkbox>
                        <label class="checkbox-container__label"
                            >Уведоления о событиях</label
                        >
                    </div>
                    <div class="checkbox-container">
                        <checkbox v-model="udata.pushOfSos"></checkbox>
                        <label class="checkbox-container__label"
                            >Уведоления о SOS-сигналах</label
                        >
                    </div>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_contacts">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title">Контакты</span>
                </div>

                <div class="page-tab__group__content">
                    <c-input
                        label="Whatsapp"
                        :value="whatsappLink"
                        @input="setLink($event, 'whatsapp')"
                        key="whatsapp"
                        :err="error.whatsapp"
                        class="page-tab__edit__input page-tab__edit__input_whatsapp"
                    ></c-input>
                    <c-input
                        label="Telegram"
                        :value="telegramLink"
                        @input="setLink($event, 'telegram')"
                        key="telegram"
                        :err="error.telegram"
                        class="page-tab__edit__input page-tab__edit__input_telegram"
                    ></c-input>
                    <c-input
                        label="Facebook"
                        :value="facebookLink"
                        @input="setLink($event, 'facebook')"
                        key="facebook"
                        :err="error.facebook"
                        class="page-tab__edit__input page-tab__edit__input_facebook"
                    ></c-input>
                    <c-input
                        label="Instagram"
                        :value="instagramLink"
                        @input="setLink($event, 'instagram')"
                        key="instagram"
                        :err="error.instagram"
                        class="page-tab__edit__input page-tab__edit__input_instagram"
                    ></c-input>
                    <c-input
                        label="VK"
                        :value="vkLink"
                        @input="setLink($event, 'vk')"
                        key="vk"
                        :err="error.vk"
                        class="page-tab__edit__input page-tab__edit__input_vk"
                    ></c-input>
                </div>
            </div>

            <creatable-list
                label="Гараж"
                mode="object"
                :value="udata.garage"
                v-on:input="setGarage"
                class="page-tab__cl-garage"
                :itemTemplate="generateEmptyTransport"
            >
                <template v-slot="data">
                    <div class="cl-item__content page-tab__cl-garage__content">
                        <div class="cl-item__number__field">
                            <div class="cl-item__number__container">
                                <span class="cl-item__number">{{
                                    data.item._listId + 1
                                }}</span>
                            </div>
                        </div>
                        <c-select
                            placeholder="Тип"
                            :value="data.item.icon"
                            :options="view.transportTypes"
                            :closeOnSelect="true"
                            :searchable="false"
                            selectOptionField="value"
                            imageField="icon"
                            :toCustomLabel="(data) => {return data.text}"
                            @input="
                                setTypeOfTransport(data.item._listId, $event)
                            "
                            :imageInSelected="true"
                            class="cl-item__select page-tab__cl-garage__input_icon"
                        >
                        </c-select>

                        <c-input
                            class="cl-item__select page-tab__cl-garage__input_description"
                            placeholder="Название"
                            :value="data.item.description"
                            @input="
                                setDescriptionOfTransport(
                                    data.item._listId,
                                    $event
                                )
                            "
                        ></c-input>

                        <div class="cl-item__controlls">
                            <c-button
                                v-if="data.item._onRemove"
                                class="cl-item__btn cl-item__btn-remove"
                                text="Удалить"
                                @click.native="
                                    data.item._onRemove(data.item._listId)
                                "
                            ></c-button>
                        </div>
                    </div>
                </template>
            </creatable-list>
            <c-textarea
                label="Описание"
                v-model.trim="udata.description"
            ></c-textarea>

            <div class="page-tab__edit__controlls">
                <c-button
                    class="page-tab__edit__controll"
                    text="Отменить"
                    @click="resetData"
                    buttonStyle="red"
                ></c-button>
                <c-button
                    class="page-tab__edit__controll"
                    text="Сохранить"
                    @click="updateUserData"
                    buttonStyle="green"
                ></c-button>
            </div>
        </div>
    </div>
</template>

<script>
//  Mixins
import UserMixin from "./../mixins/user";

//  Components
import NumberInput from "./../components/NumberInput.vue";
import Input from "./../components/Input.vue";
import Select from "./../components/Select.vue";
import CheckBox from "./../components/CheckBox.vue";
import CreatableList from "./../components/CreatableList.vue";
import Textarea from "./../components/Textarea.vue";
import SingleImagePicker from "./../components/SingleImageUploader.vue";
import Button from "../components/Button.vue";

//  CSS
import {} from "@/assets/style/edituser.less";

export default {
    name: "EditUser",
    mixins: [UserMixin],
    components: {
        "c-input": Input,
        "number-input": NumberInput,
        "c-select": Select,
        checkbox: CheckBox,
        "creatable-list": CreatableList,
        "c-textarea": Textarea,
        "image-picker": SingleImagePicker,
        "c-button": Button,
    },
    computed: {
        userId() {
            return this.$route.params.id;
        },
    },
    methods: {
        afterUpdateUserData() {
            if (this.user.role == "partner") {
                this.$router.replace({
                    name: "EditPartner",
                    params: {
                        id: this.userId,
                    },
                });
            }
        },
    },
};
</script>

<style lang='less'>
.page-tab {
    &__cl-garage {

        &__content {
            display: grid;
            grid-template-columns: 30px 100px 1fr 100px;
            grid-column-gap: 30px;
        }
        &__input {
            &_icon {
                
            }
        }
    }
}
</style>