export function checkAuth(store) {
    let headers = store.state.headers;
    if (!headers || !headers.deviceId || !headers.authdata) {
        throw new Error('Headers is empty');
    }

    let user = store.state.user;
    if (!user || user.role != 'admin')
        throw new Error('User data is incorrect');
    return;
}