<template>
    <div class="page-tab ticket-tab">
        <div class="page-tab__title__field">
            <h1 class="page-tab__title">Обращения</h1>
        </div>

        <div class="page-tab__table table">
            <div class="table__header">
                <div class="table__item__wrapper table-record">
                    <span class="table-record__text table-record__text_category"
                        >Категория</span
                    >
                    <span class="table-record__text table-record__text_subject"
                        >Тема</span
                    >
                    <span class="table-record__text table-record__text_email"
                        >Email</span
                    >
                    <span class="table-record__text table-record__text_date"
                        >Дата</span
                    >
                </div>
            </div>

            <div class="table__body">
                <div
                    class="table__item"
                    v-for="index in 10"
                    :key="index"
                    :index="index"
                >
                    <div
                        class="table__item__wrapper table-record"
                        v-if="
                            tickets[index - 1] &&
                            typeof tickets[index - 1] == 'object'
                        "
                        @click="openTicketPopUp(tickets[index - 1])"
                    >
                        <span
                            class="table-record__text table-record__text_category"
                            :title="getTitleCategory(tickets[index-1])"
                        >{{getViewCategory(tickets[index - 1])}}</span>

                        <span
                            class="table-record__text table-record__text_subject"
                            >{{ getViewSubject(tickets[index - 1]) }}</span
                        >
                        <span
                            class="table-record__text table-record__text_email"
                            >{{ getViewEmail(tickets[index - 1]) }}</span
                        >
                        <span
                            class="table-record__text table-record__text_date"
                            >{{ getViewDate(tickets[index - 1]) }}</span
                        >
                    </div>
                </div>
            </div>
            <transition name='table__loader-animation'>
                <div class="table__loader__field" v-if="states.processing">
                    <img class="table__loader" src='@/assets/img/loader.gif'>
                </div>
            </transition>
        </div>

        <div class="page-tab__paginator">
            <inline-select
                :options="view.paginationTemplate"
                :value="currentPage"
                @input="changePage"
                class="page-tab__pagination-selector"
                :isVisibleAllDevider="false"
                :disabled="states.processing"
            ></inline-select>
        </div>
    </div>
</template>

<script>
//  Mixin
import TicketsEngine from "../mixins/tickets";

//  Components
import InlineSelect from "../components/InlineSelect.vue";

//  Pipe
import Pipe from "../pipe";

export default {
    name: "Tickets",
    mixins: [TicketsEngine],
    components: {
        "inline-select": InlineSelect,
    },
    data() {
        return {};
    },
    async created() {
        await this.$_getTicketsByFilter();
        await this.$_rebuildPagination();
        await this.$nextTick();
        this.states.processing = false;
        this.states.inited = true;
    },
    methods: {
        openTicketPopUp(ticket){
            Pipe.openTicket(ticket, 'view');
        }
    },
};
</script>

<style lang='less'>
.page-tab.ticket-tab {
    .table {
        margin-top: 40px;
        @column-areas: 2fr 2fr 1fr 100px;

        .table-record {
            display: grid;
            grid-template-columns: @column-areas;
            grid-template-rows: 100%;
            cursor: pointer;


            &__text {
                &_date{
                    padding-right: 20px;
                }
            }
        }
    }

    &__paginator {
    }

    .page-tab {

    }
}
</style>