<template>
    <div class="page-tab user-tab">
        <div class="page-tab__title__field">
            <h1 class="page-tab__title">Пользователи</h1>
        </div>

        <div class="page-tab__filters">
            <div class="page-tab__filters__item filter">
                <search-input
                    class="page-tab__filter page-tab__filter_search-input page-tab__search-input user-tab__search-input"
                    v-model="filter.search"
                    placeholder="Введите имя, email, телефон"
                    :entrBtnClickMode="true"
                ></search-input>
            </div>

            <div class="page-tab__filters__item filter">
                <c-select
                    class="page-tab__filter page-tab__filter_select user-tab__select-role"
                    :options="view.roles"
                    :multiple="true"
                    placeholder="Роли"
                    selectOptionField="value"
                    viewLabel="text"
                    v-model="filter.role"
                ></c-select>
            </div>

            <div class="page-tab__filters__item filter">
                <c-select
                    class="page-tab__filter page-tab__filter_select user-tab__select-states"
                    :options="view.states"
                    :multiple="true"
                    placeholder="Статус"
                    selectOptionField="value"
                    viewLabel="text"
                    v-model="filter.state"
                ></c-select>
            </div>
        </div>

        <div class="page-tab__table table">
            <div class="table__header">
                <div class="table__item__wrapper table-record">
                    <span class="table-record__text table-record__text_status"
                        >Статус</span
                    >
                    <span class="table-record__text table-record__text_platform"
                        >Платформа</span
                    >
                    <span class="table-record__text table-record__text_name"
                        >Имя</span
                    >
                    <span class="table-record__text table-record__text_email"
                        >Email</span
                    >
                    <span class="table-record__text table-record__text_phone"
                        >Телефон</span
                    >
                    <span class="table-record__text table-record__text_role"
                        >Роль</span
                    >
                    <span
                        class="table-record__text table-record__text_h-align-center table-record__text_actions"
                        >Действия</span
                    >
                </div>
            </div>

            <div class="table__body">
                <div
                    class="table__item"
                    v-for="index in 10"
                    :key="index"
                    :index="index"
                >
                    <div
                        class="table__item__wrapper table-record"
                        v-if="
                            users[index - 1] &&
                            typeof users[index - 1] == 'object'
                        "
                    >
                        <span
                            class="table-record__text table-record__text_status"
                            v-html="getStatusForTable(users[index - 1])"
                        ></span>
                        <div
                            class="table-record__cell table-record__cell_platform"
                        >
                            <div
                                class="table-record__image-container"
                                :title="getPlatformAsText(users[index - 1])"
                            >
                                <img
                                    class="table-record__image"
                                    :src="getImageByPlatform(users[index - 1])"
                                    :alt="users[index - 1].platform"
                                />
                            </div>
                        </div>

                        <span
                            class="table-record__text table-record__text_name"
                            >{{ getViewName(users[index - 1]) }}</span
                        >
                        <span
                            class="table-record__text table-record__text_email"
                            >{{ getViewEmail(users[index - 1]) }}</span
                        >
                        <span
                            class="table-record__text table-record__text_phone"
                            >{{ getViewPhone(users[index - 1]) }}</span
                        >
                        <span
                            class="table-record__text table-record__text_role"
                            >{{ getViewRole(users[index - 1]) }}</span
                        >
                        <div
                            class="table-record__cell table-record__cell_actions"
                        >
                            <div
                                class="table-record__action-btn"
                                title="Редактировать"
                                @click="goToProfile(users[index - 1].id)"
                            >
                                <img
                                    class="table-record__action-btn__icon table-record__action-btn__icon_edit"
                                    src="@/assets/img/edit.svg"
                                    alt="edit"
                                />
                            </div>
                            <div
                                class="table-record__action-btn"
                                v-if="
                                    !users[index - 1].isDeleted &&
                                    users[index - 1].role != 'admin'
                                "
                                :title="
                                    users[index - 1].isBlocked
                                        ? 'Разблокировать'
                                        : 'Заблокировать'
                                "
                                @click="alertBlockUser(users[index - 1])"
                            >
                                <img
                                    class="table-record__action-btn__icon table-record__action-btn__icon_block"
                                    src="@/assets/img/error.svg"
                                    alt="block"
                                />
                            </div>
                            <div
                                class="table-record__action-btn"
                                @click="alertDeleteUser(users[index - 1])"
                                v-if="
                                    !users[index - 1].isDeleted &&
                                    users[index - 1].role != 'admin'
                                "
                            >
                                <img
                                    class="table-record__action-btn__icon table-record__action-btn__icon_delete"
                                    src="@/assets/img/delete.svg"
                                    alt="delete"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <transition name='table__loader-animation'>
                <div class="table__loader__field" v-if="states.processing">
                    <img class="table__loader" src='@/assets/img/loader.gif'>
                </div>
            </transition>
        </div>

        <div class="page-tab__paginator">
            <inline-select
                :options="view.paginationTemplate"
                :value="currentPage"
                @input="changePage"
                class="page-tab__pagination-selector"
                :isVisibleAllDevider="false"
                :disabled="states.processing"
            ></inline-select>
        </div>
    </div>
</template>

<script>
//  Mixin
import UsersEngine from "../mixins/users";

//  Components
import InlineSelect from "../components/InlineSelect.vue";
import SearchInput from "../components/SearchInput.vue";
import Select from "../components/Select.vue";

export default {
    name: "AdminUsers",
    mixins: [UsersEngine],
    components: {
        "inline-select": InlineSelect,
        "search-input": SearchInput,
        "c-select": Select,
    },
    data() {
        return {};
    },
    async created() {
        await this.$_getUsersByFilter();
        await this.$_rebuildPagination();
        await this.$nextTick();
        this.states.processing = false;
        this.states.inited = true;
    },
    methods: {
        getStatusForTable(user) {
            let status = this.getStatus(user);
            let classTable = {
                удален: "status_deleted",
                заблокирован: "status_blocked",
                ожидает: "status_wait",
                активирован: "status_active",
            };

            return `<span class="${
                classTable[status.toLowerCase()]
            }">${status}</span>`;
        },
        goToProfile(id) {
            this.$router.push({
                name: "EditUser",
                params: {
                    id,
                },
            });
        },
    },
};
</script>

<style lang='less'>
.page-tab.user-tab {
    .table {
        margin-top: 40px;
        @column-areas: 0.8fr 0.6fr 1fr 1fr 1fr 1fr 0.6fr;

        .table-record {
            display: grid;
            grid-template-columns: @column-areas;
            grid-template-rows: 100%;

            &__image {
                &-container {
                    width: 100px;
                    height: 15px;
                    text-align: left;
                }
            }

            .status {
                &_active {
                    color: #20d38f;
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
                }

                &_blocked {
                    color: #fc546e;
                }

                &_wait {
                    color: #6c5ecf;
                }

                &_deleted {
                    color: #3d3e41;
                }
            }
        }
    }

    &__paginator {
    }

    .page-tab {
        &__filters {
            display: grid;
            grid-template-columns: 4fr 1.5fr 1.5fr;
            grid-column-gap: 40px;
        }
    }
}
</style>