<template>
    <div
        class="input-number"
        :class="{
            'input-number_error': err.length > 0,
        }"
    >
        <label class="input-number__label" v-if="label">{{ label }}</label>

        <div
            class="input-number__input-field"
            :class="{ 'input-number__input-field_with-prefix': preFix }"
        >
            <div class="input-number__input-prefix__field" v-if="preFix">
                <span class="input-number__input-prefix__text">{{
                    preFix
                }}</span>
            </div>
            <div class="input-number__input-container">
                <input
                    class="input-number__input input-number__input_hidden"
                    type="text"
                    ref="input"
                    v-on:keydown="incValue"
                    v-on:input="inputChanged"
                    v-on:blur="checkMinMax"
                />
                <div class="input-number__input input-number__input_view">
                    <span class="input-number__input__mask">{{
                        maskValue
                    }}</span>
                    <slot name="post-fix"></slot>
                </div>
            </div>
        </div>
        <div class="input-number__error-field">
            <transition name="error-field_animation">
                <div v-if="err" class="error-field">
                    <span class="error-field__message">{{ err }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Beauty from "@/assets/js/beauty";

export default {
    name: "NumberInput",
    data() {
        return {};
    },
    computed: {
        maskValue: function () {
            let val = Beauty.beautyNumber(this.value);
            if (this.postFix) {
                val += ` ${this.postFix}`;
            }
            return val;
        },
    },
    methods: {
        inputChanged: function (e) {
            this.$refs.input.value = this.formatValue(e.target.value);
            this.emitValue(e.target.value);
        },
        emitValue: function (value) {
            this.$emit("input", this.nudeValue(value));
        },
        nudeValue: function (value) {
            let isBelowZero = value.toString()[0] === "-" ? true : false;

            return (
                Number(value.toString().replace(/[\D]/gi, "")) *
                (isBelowZero && this.min < 0 ? -1 : 1)
            );
        },
        formatValue: function (value) {
            return Beauty.beautyNumber(this.nudeValue(value).toString());
        },
        checkMinMax: function () {
            if (this.value > this.max) this.emitValue(this.max);
            else if (this.value < this.min) this.emitValue(this.min);
            return;
        },
        incValue: function (e) {
            if (e.keyCode == 38 && this.value + this.step <= this.max) {
                e.preventDefault();
                this.$emit("input", this.value + this.step);
            } else if (e.keyCode == 40 && this.value - this.step >= this.min) {
                e.preventDefault();
                this.$emit("input", this.value - this.step);
            } else if (
                e.keyCode >= 48 &&
                e.keyCode <= 57 &&
                Number(this.value + e.key) > this.max
            ) {
                e.preventDefault();
                this.$emit("input", this.max);
            }
            return;
        },
    },
    watch: {
        value: function () {
            this.$refs.input.value = this.formatValue(this.value);
        },
    },
    mounted() {
        this.$refs.input.value = this.formatValue(this.value);
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: Number.MIN_SAFE_INTEGER,
        },
        max: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER,
        },
        label: {
            type: String,
            default: undefined,
        },
        err: {
            type: String,
            default: "",
        },
        postFix: {
            type: String || Array,
            default: undefined,
        },
        preFix: {
            type: String,
            default: undefined,
        },
        step: {
            type: Number,
            default: 1,
        },
        scale: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.input-number {
    display: block;
    padding: 0;
    margin: 0;
    text-align: left;

    &_error {
        .input-number__input-field {
            border-color: #ff7676;
        }
    }

    &__label {
        font-family: @font-family;
        font-size: 14rem;
        display: block;
        margin-bottom: 6.62px;
    }

    &__input {
        border: none;

        &_hidden {
            position: absolute;
            top: -2px;
            width: 100%;
            height: calc(~"100% + 4px");
            padding: 0px;
            z-index: 1;
            background: transparent;
            color: transparent;
            caret-color: black;
            font-size: inherit;
            font-family: inherit;
        }

        &_view {
            z-index: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: inherit;
            font-family: inherit;
        }

        &__mask {
            width: auto;
            font-style: normal;
            font-weight: normal;
            border: none;
            outline: none;
            display: inline-block;
            font-size: inherit;
            font-family: inherit;
            letter-spacing: normal;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
        }

        &-field {
            display: flex;
            border: 1px solid #c6cbd6;
            box-sizing: border-box;
            border-radius: 7px;
            padding: 10px 14px 12px;
            font-size: 16rem;
            font-family: @font-family;
            font-weight: 700;
            line-height: 27px;
            width: 100%;
            transition: border 250ms ease-in-out;
            -moz-appearance: textfield;
            position: relative;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            &_with-prefix {
                .input-number__input {
                    &_hidden {
                        left: 0;
                        padding: 0;
                    }
                }
            }
        }

        &-prefix {
            &__field {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
            }

            &__text {
                font-size: inherit;
                font-family: inherit;
                color: inherit;
            }
        }

        &-container {
            flex-grow: 1;
            position: relative;
        }
    }

    &__error-field {
        margin-top: 6px;
        height: 20px;
        position: relative;
    }
}

.error-field {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &__message {
        color: #ff7676;
        font-size: 12rem;
        line-height: 15rem;
        font-family: @font-family
    }
}

.error-field_animation {
    &-enter-active,
    &-leave-active {
        transition-property: all;
        transition-timing-function: ease-in-out;
        transition-duration: 250ms;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
