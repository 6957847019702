<template>
    <div
        class="textarea-with-label"
        :class="{
            'textarea-with-label_error': err.length > 0,
            'textarea-with-label_mobile': false,
        }"
    >
        <label class="textarea-with-label__label">{{ label }}</label>

        <div class="textarea-with-label__area-field">
            <textarea
                :name="name"
                class="textarea-with-label__area"
                :class="{ 'textarea-with-label__area_resize': resize }"
                :required="isRequired"
                :autocomplete="autocompleteField"
                v-model="text"
                ref="textarea"
                :placeholder="placeholder"
            ></textarea>
            <textarea
                v-if="!resize"
                :value="text + 'WWWWWWWWW'"
                class="textarea-with-label__area textarea-with-label__area_invisible"
                ref="invisible"
            ></textarea>
            <transition name="textarea-with-label__error-icon-animation">
                <div class="textarea-with-label__error-icon" v-if="err.length > 0"></div>
            </transition>
        </div>
        <div class="textarea-with-label__error-field">
            <transition name="error-field_animation">
                <div v-if="err" class="error-field">
                    <span class="error-field__message">{{ err }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextareaWithLabel",
    data() {
        return {
            text: "",
        };
    },
    created() {
        this.text = this.value;
        setTimeout(() => {
            this.resizeArea();
        }, 200);
    },
    watch: {
        text: async function () {
            this.$emit("input", this.text);
            await this.$nextTick();
            this.resizeArea();
        },
        value: function () {
            if (this.value != this.text) this.text = this.value;
        },
    },
    methods: {
        resizeArea: function () {
            if (this.resize == false) {
                let minHeight = Number.parseInt(
                    getComputedStyle(this.$refs.textarea).minHeight || 1
                );
                let newHeight =
                    Math.max(25 + this.$refs.invisible.scrollHeight, minHeight) + "px";
                if (this.$refs.textarea.style.height != newHeight)
                    this.$refs.textarea.style.height = newHeight;
            }
        },
    },
    props: {
        label: String,
        name: {
            type: String,
            default: undefined
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        value: {
            type: String,
            default: "",
        },
        resize: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        err: {
            type: String,
            default: "",
        },
        autocompleteField: {
            type: String,
            default: "new-password",
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.textarea-with-label {
    display: block;
    padding: 0px;
    margin: 0px;
    text-align: left;
    &__area-field {
        position: relative;
    }

    &__error-field {
        margin-top: 6px;
        height: 20px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
    }

    &_error {
        .textarea-with-label {
            &__area {
                border-color: #ff7676;
            }
        }
    }

    &__label {
        font-family: @font-family;
        font-size: 14rem;
        line-height: 21rem;
        color: #3c434a;
        margin-bottom: 8px;
        display: block;
    }

    &__area {
        display: block;
        border: 1px solid #c6cbd6;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        font-size: 16rem;
        line-height: 27rem;
        width: calc(~"100% - 2px");
        transition: border 250ms ease-in-out;
        font-family: @font-family;
        color: #3c434a;
        resize: none;
        transition: height 500ms ease-in-out;
        z-index: 1;
        overflow: hidden;

        &_invisible {
            transition: none;
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;
            visibility: hidden;
            z-index: 0;
            height: 1px;
            width: 100%;
        }

        &:focus {
            outline: none;
        }
    }

    &__error-icon {
        top: 15rem;
        right: 17rem;
        width: 20rem;
        height: 20rem;
        position: absolute;
        background-image: url(~"~@/assets/img/cancel_red.svg");
        background-repeat: no-repeat;
        background-position: center center;
        transform: scale(1);
        &-animation {
            &-enter-active,
            &-leave-active {
                transition-property: all;
                transition-timing-function: ease-in-out;
                transition-duration: 250ms;
            }

            &-enter,
            &-leave-to {
                transform: scale(0);
            }
        }
    }
}

.error-field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    &__message {
        color: #ff7676;
        font-size: 12rem;
        line-height: 15rem;
        font-family: @font-family;
    }
}

.error-field_animation {
    &-enter-active,
    &-leave-active {
        transition-property: all;
        transition-timing-function: ease-in-out;
        transition-duration: 250ms;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

.textarea-with-label.textarea-with-label_mobile {
    .textarea-with-label {
        &__label {
            font-size: 12rem;
            line-height: 19rem;
            margin-bottom: 3px;
        }

        &__area {
            background: #f5f5f7;
            border-radius: 7px;
            padding: ~"min(3.2vw, 15px)";
            font-size: 12rem;
            line-height: 19rem;
        }

        &__error-field {
            margin-top: 3px;
            height: 14rem;
        }

        .error-field {
            &__message {
                font-size: 10rem;
                line-height: 14rem;
            }
        }
    }
}
</style>
