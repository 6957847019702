<template>
    <div
        class="input__container"
        :class="[
            { input__container_error: err, input__container_disabled: disable },
        ]"
    >
        <label v-if="label" class="input__label">{{ label }}</label>
        <div class="input__field">
            <input
                v-model="temporaryValue"
                :type="itype"
                v-mask="mask"
                :placeholder="visiblePlaceholder"
                :readonly="readonly"
                :autocomplete="autocompleteField"
                :min="minValue"
                :max="maxValue"
                :disabled="disable"
                class="input"
                :class="[`input_${itype}`]"
            />
            <transition
                v-if="inputType != 'password'"
                name="input__error-icon-animation"
            >
                <div class="input__error-icon" v-if="err.length"></div>
            </transition>
            <div
                v-if="isPassword"
                class="input__switch-btn"
                :class="{
                    'input__switch-btn_enable': showPassword,
                }"
                v-on:click="changeInputView"
            ></div>
        </div>
        <div
            class="input__error__field"
            :class="[{ input__error__field_visible: err }]"
        >
            <span class="input__error">{{ err }}</span>
        </div>
    </div>
</template>

<script>
//  Directives
import { VueMaskDirective } from "v-mask";

//  Tools
import Beauty from "../assets/js/beauty";

export default {
    name: "Input",
    data() {
        return {
            showPassword: false,
            mask: "",
            temporaryValue: "",
            visiblePlaceholder: "",
        };
    },
    mounted() {
        this.visiblePlaceholder = this.placeholder;
        if (this.inputType == "phone") {
            this.mask = "7### ###-##-##";
            if (!this.visiblePlaceholder)
                this.visiblePlaceholder = "7___ ___-__-__";
        } else if (this.itype == "number") {
            if (this.value) {
                let mutationValue = Number(this.value);
                if (
                    Number.isFinite(mutationValue) &&
                    this.max > mutationValue &&
                    this.min > mutationValue
                )
                    this.temporaryValue = mutationValue;
                else this.temporaryValue = this.min;
            }
        }
        else if (this.value) {
            this.temporaryValue = this.value;
        }
    },
    computed: {
        itype() {
            switch (this.inputType) {
                case "password":
                    if (this.showPassword) return "text";
                    return "password";
                case "number":
                    return "number";
                default:
                    return "text";
            }
        },
        isPassword() {
            return this.inputType == "password";
        },
        minValue() {
            if (this.itype == "number") {
                return this.min;
            }
            return undefined;
        },
        maxValue() {
            if (this.itype == "number") {
                return this.max;
            }
            return undefined;
        },
    },
    methods: {
        changeInputView() {
            this.showPassword = !this.showPassword;
        },
        setValue() {
            let value = this.value;
            if (this.inputType == "phone") {
                value = Beauty.phoneToMask(value);
            } else if (this.itype == "number") {
                value = Number(value);
            }
            if (value != this.temporaryValue) this.temporaryValue = value;
        },
    },
    watch: {
        temporaryValue() {
            let value = this.temporaryValue;
            if (this.inputType == "phone") {
                value = value.replace(/ |-/g, "");
                if (value == "7") {
                    this.temporaryValue = "";
                }
            } else if (this.itype == "number") {
                value = Number(value);
            }

            this.$emit("input", value);
        },
        value() {
            this.setValue();
        },
    },
    props: {
        label: {
            type: String,
            default: undefined
        },
        value: {
            type: [String, Number],
            default: "",
        },
        inputType: {
            type: String,
            default: "text",
        },
        err: {
            type: String,
            default: "",
        },
        autocompleteField: {
            type: String,
            default: "new-password",
        },
        placeholder: {
            type: String,
            default: "",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disable: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: Number.MIN_SAFE_INTEGER,
        },
        max: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER,
        },
    },
    directives: {
        mask: VueMaskDirective,
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
@error-color: #ff5050;
@text-color: #3c434a;

.input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0px;
    margin: 0px;

    &,
    &::placeholder {
        font-family: @font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16rem;
        line-height: 27rem;
        color: @text-color;
    }

    &__container {
        width: 100%;
        text-align: left;
    }

    &__label {
        display: block;
        margin-bottom: 6px;
        font-size: 14rem;
        font-family: @font-family;
        color: @text-color;
    }

    &__field {
        border-radius: 5px;
        padding: 11px 14px;
        border: 1px solid #c6cbd6;
        background: #fff;
        min-height: 40px;
        box-sizing: border-box;
        position: relative;
    }

    &__switch-btn {
        position: absolute;
        border-left: 1px solid #c6cbd6;
        width: 40rem;
        height: 100%;
        top: 0px;
        right: 0px;
        cursor: pointer;
        user-select: none;
        background-image: url(~"~@/assets/img/eye.svg");
        background-repeat: no-repeat;
        background-position: center center;

        &_enable {
            background-image: url(~"~@/assets/img/eye-slash.svg");
        }
    }

    &__error {
        color: @error-color;
        font-size: 12rem;
        line-height: 15rem;
        font-family: @font-family;
        font-weight: 600;
        &__field {
            height: auto;
            margin-top: 0px;
            transform: scale(0);
            transform-origin: left top;
            transition: all 500ms ease-in-out;

            &_visible {
                margin-top: 10px;
                transform: scale(1);
            }
        }
    }

    &__error-icon {
        bottom: 15rem;
        right: 10rem;
        width: 20rem;
        height: 20rem;
        position: absolute;
        background-image: url(~"~@/assets/img/cancel_red.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        &-animation {
            &-enter-active,
            &-leave-active {
                transition-property: all;
                transition-timing-function: ease-in-out;
                transition-duration: 250ms;
            }

            &-enter,
            &-leave-to {
                transform: scale(0);
            }
        }
    }

    &__container_error {
        .input {
            &__label {
                color: @error-color;
            }

            &__field {
                border-color: @error-color;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
            }

            &__switch-btn {
                border-color: @error-color;
            }
        }
    }

    &__container_disabled {
        .input {
            &:disabled {
                background: transparent;
            }
            &__field {
                background: #E5E9EA;
            }
        }
    }
}
</style>