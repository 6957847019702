<template>
    <div class="single-image-picker">
        <div
            class="single-image-picker__preview__field"
            ref="preview"
            :class="{ 'single-image-picker__preview__field_empty': !preview }"
        >
            <img
                v-if="preview"
                class="single-image-picker__preview"
                :src="preview"
                alt="preview"
            />
        </div>
        <input
            class="single-image-picker__input"
            ref="input"
            type="file"
            title="Загрузить новое изображение"
            accept="image/png, image/jpg, image/jpeg"
            @change="setNewImage"
        />
        <div class="single-image-picker__curtain" @click="emitClick">
            <img
                class="single-image-picker__curtain__icon"
                src="@/assets/img/camera.svg"
                alt=""
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "SingleImageUploader",
    methods: {
        setNewImage(e) {
            const file = e.target.files[0];
            if (!file) {
                this.$refs.input.value = "";
                return;
            }

            if (!file.type.includes("image/")) {
                this.$refs.input.value = "";
                this.$emit("error", "Выбран некорректный файл");
                return;
            }

            if (typeof FileReader !== "function") {
                this.$refs.input.value = "";
                this.$emit("error", "Невозможно открыть файл");
                return;
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                this.$emit("input", {
                    preview: event.target.result,
                    file: new File([file], file.name, { type: file.type }),
                });
                this.$refs.input.value = "";
            };

            reader.readAsDataURL(file);
        },
        emitClick: function () {
            this.$refs.input.click();
        },
    },
    props: {
        preview: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.single-image-picker {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #c6cbd6;
    border-radius: 99999999px;
    overflow: hidden;
    cursor: pointer;

    &__preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: 1;
        position: relative;

        &__field {
            position: absolute;
            top: 0px;
            left: 0px;
            pointer-events: none;
            cursor: pointer;
            width: 100%;
            height: 100%;

            &_empty {
                &::before {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    content: "Нет изображения";
                    color: #3c434a;
                    background: rgba(60, 67, 74, 0.1);
                    font-size: 14rem;
                    font-family: @font-family;
                    font-weight: 600;
                    position: absolute;
                    z-index: 0;
                }
            }
        }
    }

    &:hover {
        .single-image-picker {
            &__curtain {
                bottom: 0;
            }
        }
    }

    &__input {
        opacity: 0;
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
        cursor: pointer;
    }

    &__cropper {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
    }

    &__curtain {
        position: absolute;
        bottom: -30%;
        left: 0;
        background: rgba(198, 203, 214, 0.3);
        width: 100%;
        height: 30%;
        transition-delay: 0;
        transition-duration: 250ms;
        transition-property: bottom;
        transition-timing-function: ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: none;

        &__icon {
            width: 20px;
            max-height: 20px;
            object-fit: cover;
            object-position: center center;
        }
    }

    &_mobile {
        position: relative;

        &:hover {
            .single-image-picker {
                &__curtain {
                    bottom: 2px;
                }
            }
        }

        .single-image-picker {
            &__curtain {
                position: absolute;
                width: 22px;
                height: 22px;
                background: #f5f5f7;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
                border-radius: 22px;
                bottom: 2px;
                right: 5px;
                left: unset;
                top: unset;

                &__icon {
                    font-size: 11rem;
                    color: #a6acba;
                }
            }
        }
    }
}
</style>
