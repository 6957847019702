<template>
    <div class="inline-select" :class="{ 'inline-select_disable': disabled }">
        <div v-if="label" class="inline-select__label__field">
            <label class="inline-select__label">{{ label }}</label>
        </div>

        <div class="inline-select__input__field">
            <div class="inline-select__input">
                <div
                    class="inline-select__input__item"
                    v-for="(item, index) in options"
                    :key="_uid + '-' + index"
                    @click="selectItem(index)"
                    :class="{
                        'inline-select__input__item_selected': checkActive(
                            item
                        ),
                    }"
                >
                    <span class="inline-select__input__item__text">{{
                        getText(item)
                    }}</span>
                    <div
                        class="inline-select__input__item__devider"
                        v-if="
                            !(
                                checkActive(item) ||
                                (options[index + 1] &&
                                    !checkActive(item) &&
                                    checkActive(options[index + 1])) ||
                                index == options.length - 1
                            ) ||
                            (isVisibleAllDevider && index != options.length - 1)
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InlineSelect",
    methods: {
        selectItem(index) {
            if (this.disabled) {
                return;
            }
            if (
                typeof this.options[index] == "object" &&
                "value" in this.options[index]
            ) {
                this.$emit("input", this.options[index].value);
                return;
            }
            this.$emit("input", this.options[index]);
        },
        getText(item) {
            if (typeof item == "object") {
                if ("text" in item) return item.text;
                return item.value;
            }

            return item;
        },
        checkActive(item) {
            if (typeof this.value == "undefined") {
                if (item.value == this.default) return true;
                return false;
            } else {
                if (item.value == this.value) return true;
                return false;
            }
        },
    },
    props: {
        label: {
            type: String,
            default: undefined,
        },
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        value: {
            type: [Number, String],
            default: undefined,
        },
        default: {
            type: Number,
            default: 0,
        },
        isVisibleAllDevider: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.inline-select {
    &__input {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        &__field {
            padding: 4px;
            width: 100%;
            background: rgba(228, 229, 240, 0.7);
            border-radius: 5px;
            box-sizing: border-box;
        }

        &__item {
            flex-grow: 1;
            text-align: center;
            padding: 7px 20px;
            min-width: 40px;
            box-sizing: border-box;
            position: relative;
            user-select: none;
            cursor: pointer;

            &_selected {
                background: #fff;
                border-radius: 4px;
            }

            &__text {
                font-family: @font-family;
                font-style: normal;
                font-weight: normal;
                font-size: 14rem;
                line-height: 22rem;
                color: #3c4061;
            }

            &__devider {
                position: absolute;
                right: 0;
                top: calc(~"7px + 0.75rem");
                height: 20.5rem;
                width: 1px;
                background: #c6cbd6;
            }
        }
    }

    &_disable {
        filter: grayscale(1);
        .inline-select {
            &__input {
                cursor: default;
                &__item {
                    cursor: default;
                }
            }
        }
    }
}
</style>
