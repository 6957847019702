<template>
    <div
        class="search-input"
        :id="searchInputId"
        :class="{ 'search-input_mobile': false }"
    >
        <div class="search-input__wrapper">
            <input
                class="search-input__input"
                :placeholder="placeholder"
                :value="value"
                @input="changeData"
                ref="input"
                :autocomplete="autocomplete"
            />
            <div
                class="search-input__icon__field"
                :class="{ 'search-input__icon__field_active': emitBtnClick }"
                @click="emitBtnClick"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchInput",
    data() {
        return {
            keyHandler: undefined,
        };
    },
    computed: {
        searchInputId() {
            return `search-input-${Math.trunc(Math.random() * 1000000)}`;
        },
    },
    methods: {
        changeData() {
            this.$emit("input", this.$refs.input.value);
        },
        emitBtnClick() {
            if (this.btnClickMode) {
                this.$emit("click");
            }
        },
        handleKey() {
            if (this.entrBtnClickMode) {
                return (event) => {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                        this.$emit("click");
                    }
                };
            }
            return undefined;
        },
        changeEnterButtonPressState(state) {
            if (state) {
                this.$refs.input.addEventListener("keyup", this.$_keyHandler);
            } else {
                this.$refs.input.removeEventListener(
                    "keyup",
                    this.$_keyHandler
                );
            }
        },
        $_keyHandler(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                this.$emit("click");
            }
        },
    },
    watch: {
        entrBtnClickMode() {
            this.changeEnterButtonPressState(this.entrBtnClickMode);
        },
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        btnClickMode: {
            type: Boolean,
            default: false,
        },
        entrBtnClickMode: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: "new-password",
        },
    },
    mounted() {
        if (this.entrBtnClickMode) this.changeEnterButtonPressState(true);
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.search-input {
    display: block;
    padding: 0;
    margin: 0;
    text-align: left;
    position: relative;

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #c6cbd6;
        box-sizing: border-box;
        border-radius: 7px;
    }

    &__input {
        padding: 10px 0px 12px 14px;
        background: transparent;
        height: 100%;
        box-sizing: border-box;
        flex-grow: 1;
        border: none;
        font-size: 16rem;
        font-family: @font-family;
        font-weight: 500;

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-family: @font-family;
            font-size: 16rem;
            color: #adadad;
        }
    }

    &__icon {
        &__field {
            padding: 0px 14px;
            width: 2%;
            height: 100%;
            cursor: default;
            user-select: none;
            background-image: url(~"~@/assets/img/gray_loupe.svg");
            background-repeat: no-repeat;
            background-position: center;

            &_active {
                cursor: pointer;
            }
        }
    }
}

.search-input.search-input_mobile {
    .search-input {
        &__wrapper {
            display: grid;
            grid-template-columns: 14.4fr 2.44fr;
            grid-template-rows: 100%;
        }

        &__input {
            grid-area: ~"1/1/2/2";
            padding: 2.5px 0px 2.5px 5px;
        }

        &__icon {
            &__field {
                min-width: unset;
                width: 100%;
                box-sizing: border-box;
                grid-area: ~"1/2/2/3";
                background-size: 40%;
            }
        }
    }
}
</style>
