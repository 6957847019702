//  Libs
import { isEqual } from "lodash";
import Moment from "moment-timezone";

//  Vuelidate
// import { isUsualPhone } from "../assets/js/vuelidateExtensions";

//  Mixins
import basis from "./basis/userBasis";

export default {
    mixins: [basis],
    data() {
        return {
            udata: {
                textAddress: "",
                categories: [],
                photos: [],
                isSubscribe: undefined,
                subscriptionExpiration: undefined,
            },
            error: {
                phonelink: "",
                weblink: "",
            },
            view: {
                categories: [],
            },
        };
    },
    computed: {
        currentSubscribeState() {
            return this.udata.isSubscribe ? "Активна" : "Отключена";
        },
        webLink() {
            let item = this.$_getContactLinkByType("weblink");
            if (item) {
                return item.link;
            }
            return "";
        },
        phoneLink() {
            let item = this.$_getContactLinkByType("phone");
            if (item) {
                return item.link;
            }
            return "";
        },
        photos() {
            return [].concat(this.udata.photos, this.user.photos);
        },
    },
    async created() {
        this.view.categories = await this.$connector.getCategories();
    },
    methods: {
        getPhotoPreview(photo) {
            if (typeof photo == "string") return photo;
            if (typeof photo == "object") {
                if ("original" in photo)
                    return photo.original;
                if ("preview" in photo)
                    return photo.preview;
            }
            return "";
        },
        removePhoto(photo) {
            if ("preview" in photo) {
                let index = this.udata.photos.findIndex(
                    (item) => item.preview == photo.preview
                );
                if (!~index) return;
                this.udata.photos.splice(index, 1);
                if (this.afterRemovePhoto) this.afterRemovePhoto(photo);
                return;
            } else {
                let deviceData = this.$store.state.headers;
                this.$connector
                    .deletePartnerPhoto(
                        this.user.id,
                        this.$_getPhotoId(photo.original),
                        deviceData
                    )
                    .then(() => {
                        let index = this.user.photos.findIndex(
                            (item) => item == photo
                        );
                        if (!~index) return;
                        this.user.photos.splice(index, 1);
                        if (this.afterRemovePhoto) this.afterRemovePhoto(photo);
                    })
                    .catch((err) => {
                        if (this.afterFailRemovePhoto)
                            this.afterFailRemovePhoto(photo, err);
                    });
            }
        },
        $_setUserData(data) {
            for (let key in this.udata) {
                switch (key) {
                    case "city":
                        this.udata[key] = data.address[key];
                        break;
                    case "textAddress":
                        this.udata[key] = data.address.full;
                        break;
                    case "subscriptionExpiration":
                        this.udata[key] = Moment(
                            data.subscriptionExpiration
                        ).format("DD.MM.YYYY");
                        break;

                    case "pushOfEvents":
                    case "pushOfSos":
                    case "radiusOfSosRequest":
                    case "radiusOfEventRequest":
                        this.udata[key] = data.settings[key];
                        break;

                    case "photos":
                        this.udata[key] = [];
                        break;
                    case "avatar":
                        break;

                    default:
                        this.udata[key] = data[key];
                        break;
                }
            }
            return;
        },
        $_getFormData() {
            let data = new FormData();
            for (let key in this.udata) {
                switch (key) {
                    case "isSubscribe":
                        data.append(key, this.udata.isSubscribe);
                        break;
                    case "subscriptionExpiration": {
                        if (this.udata.isSubscribe) {
                            let time = Moment(this.udata[key], "DD.MM.YYYY")
                                .endOf("day")
                                .valueOf();
                            data.append(key, time);
                        }
                        break;
                    }
                    case "avatar":
                        if (this.udata.avatar != undefined)
                            data.append(
                                "avatar",
                                this.udata.avatar.file,
                                this.udata.avatar.file.name
                            );
                        break;
                    case "photos":
                        if (
                            this.udata.photos != undefined &&
                            this.udata.photos.length
                        )
                            for (let photo of this.udata.photos) {
                                data.append(
                                    "photos",
                                    photo.file,
                                    photo.file.name
                                );
                            }
                        break;
                    case "garage": {
                        if (!this.udata.garage || this.udata.garage.length == 0)
                            break;
                        let list = this.udata.garage
                            .filter(
                                (item) =>
                                    item &&
                                    item.description &&
                                    item.description.trim().length > 0
                            )
                            .map((item) => {
                                return JSON.stringify({
                                    description: item.description.trim(),
                                    icon: item.icon ? item.icon : "moto",
                                });
                            });
                        if (list.length != 0)
                            list.forEach((item) => {
                                data.append("garage", item);
                            });
                        else data.append("garage", "{!empty}");
                        break;
                    }
                    case "categories": {
                        if (!this.udata[key] || this.udata[key].length == 0)
                            break;
                        let list = this.udata[key]
                            .filter((item) => item && item.trim().length > 0)
                            .map((item) => item.trim());
                        if (list.length != 0)
                            list.forEach((item) => {
                                data.append(key, item);
                            });
                        else data.append(key, "{!empty}");
                        break;
                    }
                    case "contacts": {
                        try {
                            let temp = this.udata[key]
                                .filter((item) => typeof item == "object")
                                .map((item) => JSON.stringify(item));
                            if (temp.length > 0)
                                temp.forEach((item) => {
                                    data.append("contacts", item);
                                });
                        } catch (err) {
                            console.log(err);
                        }
                        break;
                    }
                    default:
                        if (
                            (typeof this.udata[key] == "string" ||
                                this.udata[key] instanceof Array) &&
                            this.udata[key].length &&
                            !isEqual(this.udata[key], this.user[key])
                        ) {
                            data.append(key, this.udata[key]);
                        } else if (
                            typeof this.udata[key] == "number" &&
                            Number.isFinite(this.udata[key])
                        ) {
                            data.append(key, this.udata[key]);
                        }
                        break;
                }
            }

            return data;
        },
        async validForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$_checkPhone();
                this.$_checkContact("whatsappLink", "whatsapp");
                this.$_checkContact("telegramLink", "telegram");
                this.$_checkContact("facebookLink", "facebook");
                this.$_checkContact("instagramLink", "instagram");
                this.$_checkContact("vkLink", "vk");
                this.$_checkContact("webLink", "weblink");
                this.$_checkContact("phoneLink", "phoneLink");
                return false;
            }

            if (this.user.phone != this.udata.phone) {
                let deviceData = this.$store.state.headers;
                let res = await this.$connector.checkPhone(
                    this.udata.phone,
                    deviceData
                );
                return res;
            }

            return true;
        },
        $_checkContactPhone() {
            if (!this.$v.phoneLink.isUsualPhone) {
                this.error.phonelink = "Укажите корректный номер телефона";
                return;
            }

            this.error.phonelink = "";
            return;
        },
        $_getPhotoId(string) {
            let parts = string.split("/");
            return parts[parts.length - 1];
        },
    }
};
