<template>
    <div class="drag-and-drop" :class="{ 'drag-and-drop_mobile': false }">
        <input
            :multiple="isMultiple ? 'true' : undefined"
            type="file"
            class="drag-and-drop__input"
            v-on:change="filesSelectedByInput"
            ref="dragAndDropInput"
            accept="image/jpg,image/png,image/jpeg"
        />
        <div
            class="drag-and-drop__drag-and-drop-area drag-and-drop-area"
            :class="{
                'drag-and-drop-area_error': err.length > 0,
                'drag-and-drop-area_dragging': dragCounter > 0,
            }"
            v-on:dragenter="fileEnterArea"
            v-on:dragleave="fileLeaveArea"
            v-on:dragover="fileOverArea"
            v-on:drop="filesDroppedIntoArea"
        >
            <slot></slot>
        </div>
        <div class="drag-and-drop__error-field">
            <transition name="error-field-animation">
                <div v-if="err" class="error-field">
                    <span class="error-field__message">{{ err }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "DragAndDrop",
    data() {
        return {
            dragAndDropFiles: [],
            dragCounter: 0,
            sizeLimit: 10,
            extensions: ["jpg", "jpeg", "png"],
        };
    },
    methods: {
        async readFiles(files) {
            return new Promise((resolve) => {
                if (!files || !files.length) return resolve([]);

                let readedFiles = [];
                let rejectedFilesCount = 0;

                files.forEach((file) => {
                    let fileExt = file.name.split(".")[1].toLowerCase();
                    let fileSize = file.size / 1024 / 1024;

                    if (this.extensions.indexOf(fileExt) === -1) {
                        this.$emit("dndFileError", "forbidden extension");
                        rejectedFilesCount++;
                        return;
                    }
                    if (fileSize > this.sizeLimit) {
                        this.$emit("dndFileError", "forbidden size");
                        rejectedFilesCount++;
                        return;
                    }

                    let reader = new FileReader();

                    reader.onload = function (ev) {
                        let readedFile = {
                            b64: ev.target.result,
                            blob: file,
                        };

                        readedFiles.push(readedFile);

                        if (
                            files.length - rejectedFilesCount ===
                            readedFiles.length
                        )
                            resolve(readedFiles);
                    };

                    reader.readAsDataURL(file);
                });

                if (rejectedFilesCount === files.length) resolve([]);
            });
        },
        clearInput() {
            this.$refs.dragAndDropInput.value = "";
        },
        async filesSelectedByInput(e) {
            let readedFiles = await this.readFiles(e.target.files);
            this.clearInput();

            if (!readedFiles || !readedFiles.length) return;

            this.dragAndDropFiles = readedFiles;
            readedFiles.forEach((file) => {
                if (
                    this.fileUploadedHandler &&
                    typeof this.fileUploadedHandler == "function"
                )
                    this.fileUploadedHandler(this.dragAndDropFiles, file);
            });
        },
        fileEnterArea(e) {
            this.dragCounter++;
            e.preventDefault();
        },
        fileOverArea(e) {
            e.preventDefault();
        },
        fileLeaveArea() {
            this.dragCounter--;
        },
        async filesDroppedIntoArea(e) {
            e.preventDefault();
            this.dragCounter = 0;

            let readedFiles = await this.readFiles(e.dataTransfer.files);
            this.clearInput();

            if (!readedFiles || !readedFiles.length) return;

            this.dragAndDropFiles = readedFiles;
            readedFiles.forEach((file) => {
                if (
                    this.fileUploadedHandler &&
                    typeof this.fileUploadedHandler == "function"
                )
                    this.fileUploadedHandler(this.dragAndDropFiles, file);
            });
        },
    },
    props: {
        err: {
            type: String,
            default: "",
        },
        fileUploadedHandler: Function,
        inputClickTrigger: Number,
        isMultiple: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        inputClickTrigger: function () {
            this.$refs.dragAndDropInput.click();
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.drag-and-drop {
    display: block;
    position: relative;
    overflow: hidden;

    &__input {
        opacity: 0;
        position: absolute;
        top: -1000%;
        left: -1000%;
    }

    .drag-and-drop-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        align-items: center;
        border: 1px dashed #c6cbd6;
        border-radius: 5px;
        transition: background 0.2s, border 0.2s;
    }

    .drag-and-drop-area:hover {
        border: 1px dashed #54dca9;
    }

    .drag-and-drop-area_error {
        border-color: #ff7676;
    }

    .drag-and-drop-area_dragging {
        background: #9085e11c;
    }

    &__error-field {
        position: relative;
        display: block;
        margin-top: 6px;
        height: 20px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .error-field {
        &__message {
            color: #ff7676;
            font-size: 12rem;
            line-height: 15rem;
            font-family: @font-family;
        }
    }

    .error-field_animation {
        &-enter-active,
        &-leave-active {
            transition-property: all;
            transition-timing-function: ease-in-out;
            transition-duration: 250ms;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }
}

.drag-and-drop_mobile {
    .drag-and-drop-area {
        border: 1px solid #c6cbd6;
        border-radius: 7px;
        background: #f5f5f7;
    }

    .drag-and-drop-area:hover {
        border: 1px solid #9085e1;
    }

    .drag-and-drop-area_error {
        border-color: #ff7676;
    }

    .drag-and-drop-area_dragging {
        background: #f5f5f7;
    }

    .error-field {
        top: 3px;
        left: 3px;
        width: ~"calc(100% - 3px)";

        &__message {
            font-size: 10rem;
            line-height: 11rem;
        }
    }
}
</style>
