<template>
    <div
        class="check-box"
        v-on:click.prevent="changeState"
        :class="{
            [`check-box_${styleType}`]: styleType,
            'check-box_active': value,
            'check-box__toggle': type == 'toggle',
        }"
    >
        <transition
            name="check-box__animation"
            v-if="!type || type == 'checkbox'"
        >
            <div v-if="value" class="check-box__inner-container"></div>
        </transition>
        <div
            class="check-box__toggle__inner"
            v-if="type && type == 'toggle'"
        ></div>
    </div>
</template>

<script>
export default {
    name: "CheckBox",
    methods: {
        changeState: function () {
            this.$emit("input", !this.value);
        },
    },
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        styleType: {
            type: String,
            default: undefined,
        },
        type: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="less">
.check-box {
    width: 24px;
    height: 24px;
    border: 1px solid #c6cbd6;
    background: #fff;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &_active {
        &.check-box__toggle {
            background: #20D38F;
            .check-box__toggle {
                &__inner {
                    transform: translateX(calc(~"100% + 6px"));
                }
            }
        }
    }

    &__toggle {
        border-radius: 999999px;
        width: 48px;
        padding: 2px;
        transition: all 0.250s ease-in-out 0ms;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &__inner {
            width: 18px;
            height: 18px;
            background: #fff;
            // border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            border-radius: 9999999px;
            transform: translateX(0);
            transition: all 0.250s ease-in-out 0ms;
        }
    }

    &__animation {
        &-enter-active,
        &-leave-active {
            transition: all 250ms ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }

    &__inner-container {
        position: absolute;
        top: 0px;
        left: 0px;

        background: transparent;
        height: 24px;
        width: 24px;
        background-image: url(~"~@/assets/img/check.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 70% 70%;
        overflow: hidden;
    }
}
</style>
