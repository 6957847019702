//  Libs

//  Vuelidate
import { isPhoneIfExist } from "../../assets/js/vuelidateExtensions";
import { required, minValue } from "vuelidate/lib/validators";

//  Pipe
import Pipe from "./../../pipe";

export default {
    data() {
        return {
            user: {},
            udata: {
                role: "",
                phone: "",
                email: "",
                name: "",
                avatar: undefined,
                pushOfEvents: undefined,
                pushOfSos: undefined,
                radiusOfSosRequest: undefined,
                radiusOfEventRequest: undefined,
                city: "",
                contacts: [],
                description: "",
                garage: [],
            },
            error: {
                name: "",
                phone: "",
                whatsapp: "",
                telegram: "",
                facebook: "",
                instagram: "",
                vk: "",
            },
            state: {
                processing: false,
                inited: false,
            },
            view: {
                roles: [
                    {
                        text: "Пользователь",
                        value: "user",
                    },
                    {
                        text: "Партнер",
                        value: "partner",
                    },
                    {
                        text: "Администратор",
                        value: "admin",
                    },
                ],
                transportTypes: [{
                    icon: require('@/assets/img/moto.svg'),
                    value: 'moto'
                },{
                    icon: require('@/assets/img/atv.svg'),
                    value: 'atv'
                }, {
                    icon: require('@/assets/img/snowmobile.svg'),
                    value: 'snowmobile'
                }]
            },
        };
    },
    computed: {
        whatsappLink() {
            let item = this.$_getContactLinkByType("whatsapp");
            if (item) {
                return item.link;
            }
            return "";
        },
        telegramLink() {
            let item = this.$_getContactLinkByType("telegram");
            if (item) {
                return item.link;
            }
            return "";
        },
        facebookLink() {
            let item = this.$_getContactLinkByType("facebook");
            if (item) {
                return item.link;
            }
            return "";
        },
        instagramLink() {
            let item = this.$_getContactLinkByType("instagram");
            if (item) {
                return item.link;
            }
            return "";
        },
        vkLink() {
            let item = this.$_getContactLinkByType("vk");
            if (item) {
                return item.link;
            }
            return "";
        },
        avatarPreview() {
            if (this.udata.avatar) {
                return this.udata.avatar.preview;
            }
            if (!this.user.avatar)
                return undefined;
            if (this.user.avatar.original.startsWith("http")) return this.user.avatar.original;
            return `${this.$hostname}/media/user/${this.user.avatar.original}`;
        },
    },
    async created() {
        this.user = await this.getUserData();
        this.$_setUserData(this.user);
        await this.$nextTick();
        this.state.inited = true;
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        async getUserData() {
            let deviceData = this.$store.state.headers;
            let user = await this.$connector.getUser(this.userId, deviceData);
            return user;
        },
        setLink(value, type) {
            let index = this.udata.contacts.findIndex(
                (item) => item.type == type
            );
            if (!~index) {
                this.udata.contacts.push({ type, link: value });
                return;
            }
            this.udata.contacts[index].link = value;
        },
        setAvatar(data) {
            this.udata.avatar = data;
        },
        setTypeOfTransport(index, type) {
            let item = this.udata.garage.splice(index, 1)[0];
            if (typeof item != 'object') {
                item = {
                    icon: type,
                    description: undefined
                }
            } else {
                item.icon = type;
            }
            this.udata.garage.splice(index, 0, item);
        },
        setDescriptionOfTransport(index, desc) {
            let item = this.udata.garage.splice(index, 1)[0];
            if (typeof item != 'object') {
                item = {
                    icon: 'moto',
                    description: desc
                }
            } else {
                item.description = desc;
            }
            
            this.udata.garage.splice(index, 0, item);
        },
        setGarage(list) {
            this.udata.garage = list;
        },
        generateEmptyTransport() {
            return {
                icon: 'moto',
                description: undefined
            }
        },
        async updateUserData() {
            this.state.processing = true;
            if (!await this.validForm()) {
                return;
            }
            let data = this.$_getFormData();
            let deviceData = this.$store.state.headers;
            try {
                data = await this.$connector.updateUser(
                    this.userId,
                    data,
                    deviceData
                );
                Pipe.popUp(
                    Pipe.pipeList.popUp.types.complete,
                    "Успешно",
                    "Данные успешно обновлены"
                );
                this.user = data;
                this.$_setUserData(data);
                if (this.afterUpdateUserData) {
                    await this.afterUpdateUserData();
                }
                await this.$nextTick();
                this.state.processing = false;
            } catch (err) {
                console.log(err);
                Pipe.popUp(
                    Pipe.pipeList.popUp.types.error,
                    "Ошибка",
                    "Не удалось обновить"
                );
            }
        },
        errorOfUploadAvatar(message) {
            Pipe.popUp(Pipe.pipeList.popUp.types.error, 'Ошибка', message);
        },
        resetData() {
            this.$_setUserData(this.user);
        },
        goBack() {
            this.$router.back();
        },
        //==================//
        //     PRIVATE      //
        //==================//
        //  getter
        $_getContactLinkByType(type) {
            return this.udata.contacts.find((item) => item.type == type);
        },
        //  validators
        async $_checkPhone() {
            if (this.udata.phone && !this.$v.udata.phone.isPhone) {
                this.error.phone = "Указан некорректный номер телефона";
                return;
            }
            if (this.udata.phone != this.user.phone) {
                let deviceData = this.$store.state.headers;
                let res = await this.$connector.checkPhone(
                    this.udata.phone,
                    deviceData
                );
                if (!res) {
                    this.error.phone = "Этот телефон уже занят";
                    return;
                }
            }
            this.error.phone = "";
        },
        $_checkContact(name, type) {
            
            // if (!this.$v[name].isLinkIfExist) {
            //     this.error[type] = "Укажите корректную ссылку";
            //     return;
            // }

            this.error[type] = "";
            return;
        },
    },

    watch: {
        "udata.phone"() {
            if (!this.state.inited || this.state.processing) return;
            this.$_checkPhone();
        },
        whatsappLink() {
            this.$_checkContact("whatsappLink", "whatsapp");
        },
        telegramLink() {
            this.$_checkContact("telegramLink", "telegram");
        },
        facebookLink() {
            this.$_checkContact("facebookLink", "facebook");
        },
        instagramLink() {
            this.$_checkContact("instagramLink", "instagram");
        },
        vkLink() {
            this.$_checkContact("vkLink", "vk");
        }
    },
    validations: {
        udata: {
            name: {
                required,
            },
            radiusOfSosRequest: {
                minValue: minValue(0),
            },
            phone: {
                isPhone: isPhoneIfExist,
            },
        }
    },
};
