<template>
    <div class="relay-page">
        <a :href="url" class="relay-page__href">
            <div class="relay-page__btn">Продолжить</div>
        </a>
    </div>
</template>

<script>
export default {
    name: "AuthRelay",
    data() {
        return {
            url: "",
        };
    },
    created() {
        const eruda = window.eruda;
        const econsole = eruda ? eruda.get("console") : console;
        let query = this.$route.query;
        const url = `riders://${query.service}?state=${query.state}&code=${query.code}`;
        this.url = url;
        document.location.href = url;
        econsole.log("relocated");
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.relay-page {
    background: #f0f4f5;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__href {
        text-decoration: none;
    }

    &__btn {
        background: #20d38f;
        padding: 10px 20px;
        font-size: 16rem;
        font-family: @font-family;
        color: #fff;
        font-weight: bolder;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    }
}
</style>