import moment from "moment";

function beautyPhone(phone) {
    if (!phone) return "";

    phone = phone
        .toString()
        .replace(/-|_/g,'')
        .replace("+", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "");

    return `+${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(
        4,
        7
    )}-${phone.slice(7, 9)}-${phone.slice(9, 11)}`;
}

function phoneToMask(phone) {
    if (!phone) return "";

    phone = phone
        .toString()
        .replace("+", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "");

    return `${phone.slice(0, 4)} ${phone.slice(4, 7)}-${phone.slice(
        7,
        9
    )}-${phone.slice(9, 11)}`;
} 

function fullName(name, options) {
    let firstName = name.firstName;
    let midName = name.midName;
    let lastName = name.lastName;

    if (!firstName || !lastName) return "";

    firstName =
        firstName.trim()[0].toUpperCase() +
        firstName.trim().slice(1).toLowerCase();
    lastName =
        lastName.trim()[0].toUpperCase() +
        lastName.trim().slice(1).toLowerCase();
    if (midName)
        midName =
            midName.trim()[0].toUpperCase() +
            midName.trim().slice(1).toLowerCase();

    if (options && options.short)
        return `${lastName} ${firstName[0]}.${midName ? midName[0] + "." : ""}`;

    return `${lastName} ${firstName} ${midName ? midName : ""}`.trim();
}

function transformToParagraphView(text) {
    return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
}

function beautyNumber(number, rankBorder = 3) {
    if (!number && number !== 0)
        return "";

    number = number.toString().replace(" ", "");

    let beauty = [];
    let rankCounter = -1;
    for (let i = number.length - 1; i >= 0; i--) {
        rankCounter++;

        if (rankCounter >= rankBorder) {
            beauty.unshift(" ");
            rankCounter = 0;
        }

        beauty.unshift(number[i]);
    }

    return beauty.join("");
}

// function beautyTime(value, short = false) {
//     if (!value && value !== 0) return "";

//     let data = {
//         hour: Math.trunc(value / (60 * 60 * 1000)),
//         minute: Math.trunc((value / (60 * 1000)) % 60),
//     };

//     let hourSpan;
//     let minSpan;
//     if (!short) {
//         hourSpan = decOfNum(data.hour, ["час", "часа", "часов"]);
//         minSpan = decOfNum(data.minute, ["минута", "минуты", "минут"]);
//     } else {
//         hourSpan = 'ч';
//         minSpan = 'мин';
//     }
//     let res = '';
//     if (data.hour != 0) {
//         res = `${data.hour} ${hourSpan} `;
//     }
//     if (data.minute != 0) {
//         res += data.minute + ' ' + minSpan;
//     }
//     return res;
// }

function beautyDate(value) {
    return moment(value).format('DD.MM.YYYY');
}

export default {
    beautyPhone,
    phoneToMask,
    fullName,
    transformToParagraphView,
    beautyNumber,
    //   beautyTime,
    beautyDate,
};

// import decOfNum from "@/assets/js/titles";
