var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-select",class:{ 'inline-select_disable': _vm.disabled }},[(_vm.label)?_c('div',{staticClass:"inline-select__label__field"},[_c('label',{staticClass:"inline-select__label"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('div',{staticClass:"inline-select__input__field"},[_c('div',{staticClass:"inline-select__input"},_vm._l((_vm.options),function(item,index){return _c('div',{key:_vm._uid + '-' + index,staticClass:"inline-select__input__item",class:{
                    'inline-select__input__item_selected': _vm.checkActive(
                        item
                    ),
                },on:{"click":function($event){return _vm.selectItem(index)}}},[_c('span',{staticClass:"inline-select__input__item__text"},[_vm._v(_vm._s(_vm.getText(item)))]),(
                        !(
                            _vm.checkActive(item) ||
                            (_vm.options[index + 1] &&
                                !_vm.checkActive(item) &&
                                _vm.checkActive(_vm.options[index + 1])) ||
                            index == _vm.options.length - 1
                        ) ||
                        (_vm.isVisibleAllDevider && index != _vm.options.length - 1)
                    )?_c('div',{staticClass:"inline-select__input__item__devider"}):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }