import axios from "axios";

axios.defaults.withCredentials = true;

class Connector {
    static domain;

    static async authorization(login, password) {
        const url = `${this.domain}/auth/login`;
        let res = await axios.post(url, {
            login,
            password,
        });
        if (res.status != 200) {
            let err = new Error("Bad status code");
            err.response = res;
            throw err;
        }

        if (res.data.user.role != "admin") {
            let err = new Error("Bad user");
            delete res.data;
            err.response = res;
            throw err;
        }

        return res.data;
    }

    static async getPartners(params, deviceData) {
        const url = `${this.domain}/partner`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.get(url, {
            params,
            responseType: "json",
            headers,
        });

        return res.data;
    }

    static async getUsers(params, deviceData) {
        const url = `${this.domain}/user`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.get(url, {
            params,
            responseType: "json",
            headers,
        });

        return res.data;
    }

    static async getUser(id, deviceData) {
        const url = `${this.domain}/user/${id}`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.get(url, {
            responseType: "json",
            headers,
        });

        return res.data;
    }

    static async updateUser(userId, data, deviceData) {
        const url = `${this.domain}/user/${userId}`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.patch(url, data, {
            responseType: "json",
            headers,
        });

        return res.data;
    }

    static async deleteUser(userId, deviceData) {
        const url = `${this.domain}/user/${userId}`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.delete(url, {
            responseType: "json",
            headers,
        });

        return res.data;
    }

    static async changeStateUser(userId, newState, deviceData) {
        const url = `${this.domain}/user/${userId}/block/${newState}`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.patch(
            url,
            {},
            {
                responseType: "json",
                headers,
            }
        );

        return res.data;
    }

    static async resetStep1(login) {
        const url = `${this.domain}/auth/reset`;

        let res = await axios.post(url, {
            login
        }, {
            responseType: "json",
        });

        return res.data;
    }

    static async logout(deviceData) {
        const url = `${this.domain}/auth/logout`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        let res = await axios.patch(url, {
            responseType: "json",
            headers,
        });

        return res.data;
    }

    static async resetConfirm(data) {
        const url = `${this.domain}/auth/reset/confirm`;

        let res = await axios.patch(url, data);
        return {
            code: res.status,
            data: res.data
        }
    }

    static async checkPhone(phone, deviceData) {
        const url = `${this.domain}/auth/login/check/${phone}`;
        let headers = this._getHeadersFromDeviceData(deviceData);

        try {
            await axios.get(url, {
                responseType: "json",
                headers,
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    static async getCategories() {
        const url = `${this.domain}/category`;
        let res = await axios.get(url, {
            responseType: "json",
        });

        return res.data;
    }

    static async deletePartnerPhoto(partnerId, photoId, deviceData) {
        const url = `${this.domain}/partner/${partnerId}/photo/${photoId}`;
        let headers = this._getHeadersFromDeviceData(deviceData);
        let res = await axios.delete(url, {
            responseType: 'json',
            headers
        });

        return res.data;
    }

    static async sendCode(login) {
        const url = `${this.domain}/auth/code/send`;
        let res = await axios.patch(url, { login });
        return res.data;
    }

    static async validateCode(login, code) {
        const url = `${this.domain}/auth/code/validate`;
        let res = await axios.post(url, { login, code });
        return res.data;
    }

    static async getSupportTickets(params, deviceData) {
        const url = `${this.domain}/support`;
        let headers = this._getHeadersFromDeviceData(deviceData);
        let res = await axios.get(url, {
            params,
            responseType: 'json',
            headers
        });

        return res.data;
    }

    static _setDomain(domain) {
        this.domain = domain;
    }

    static _getHeadersFromDeviceData(deviceData) {
        return {
            deviceid: deviceData.deviceId,
            Authorization: `Bearer ${deviceData.authdata}`,
        };
    }
}

export default Connector;
