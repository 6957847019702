//  Libs
import { debounce} from "lodash";
import Beauty from "../assets/js/beauty";
//  basis
import list from "./basis/userListBasis";

//  Pipe
import Pipe from "./../pipe";

export default {
    mixins: [list],
    data() {
        return {
            partners: [],
            pagination: {
                limit: 10
            },
            filter: {
                search: "",
                isSubscribe: true,
                state: [],
            },
            additional: {
                debounceSearchPartner: null,
            },
            states: {
                inited: false,
                processing: true,
                isEndOfPartners: false,
                countOfPartners: 0,
            },
        };
    },
    computed: {
        list() {
            return this.partners;
        },
        count() {
            return this.states.countOfPartners;
        },
    },
    created() {
        this.additional.debounceSearchPartner = debounce(
            this.selectPartnerByNewFilter,
            1500
        );
        this.$_setAppliedFilterFromQuery();
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        //  view
        getSubscriptionStatus(user) {
            if (user && user.role == "partner") {
                return user.isSubscribe ? "Оплачена" : "Не оплачена";
            }
            return "Не установлено";
        },
        getSubscriptionExpiration(user) {
            if (user && user.role == "partner" && user.isSubscribe) {
                return Beauty.beautyDate(user.subscriptionExpiration);
            }
            return "";
        },
        alertDeleteUser(partner) {
            const that = this;
            Pipe.openDecisionPopUp({
                title: "Удаление партнера",
                message: `Вы действительно хотите удалить партнера ${this.getViewName(
                    partner
                )}?`,
                leftButtonText: "Нет",
                leftButtonHandler() {
                    Pipe.closeDecisionPopUp();
                },
                rightButtonText: "Да",
                async rightButtonHandler() {
                    try {
                        Pipe.closeDecisionPopUp();
                        that.states.processing = true;
                        await that.$_deleteUser(partner.id);
                        that.partners = [];
                        await that.$_getPartnersByFilter();
                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.complete,
                            "Успешно",
                            "Партнер успешно удален"
                        );
                    } catch (err) {
                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.error,
                            "Ошибка",
                            "Неудалось удалить партнера"
                        );
                    } finally {
                        await that.$nextTick();
                        that.states.processing = false;
                    }
                },
            });
        },
        alertBlockUser(partner) {
            const that = this;
            let title = "Блокировка партнера";
            let message = `Вы действительно хотите заблокировать партнера ${this.getViewName(
                partner
            )}?`;
            if (partner.isBlocked) {
                title = "Разблокировка партнера";
                message = `Вы действительно хотите разблокировать партнера ${this.getViewName(
                    partner
                )}?`;
            }
            Pipe.openDecisionPopUp({
                title,
                message,
                leftButtonText: "Нет",
                leftButtonHandler() {
                    Pipe.closeDecisionPopUp();
                },
                rightButtonText: "Да",
                async rightButtonHandler() {
                    try {
                        Pipe.closeDecisionPopUp();
                        that.states.processing = true;
                        await that.$_changeStateOfUser(
                            partner.id,
                            !partner.isBlocked
                        );
                        that.partners = [];
                        await that.$_getPartnersByFilter();
                        let message = `Партнер ${that.getViewName(partner)}`;
                        message += partner.isBlocked
                            ? " разблокирован"
                            : " заблокирован";
                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.complete,
                            "Успешно",
                            message
                        );
                    } catch (err) {
                        let message = partner.isBlocked
                            ? "Неудалось разблокировать партнера"
                            : "Неудалось заблокировать партнера";

                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.error,
                            "Ошибка",
                            message
                        );
                    } finally {
                        await that.$nextTick();
                        that.states.processing = false;
                    }
                },
            });
        },
        async getMorePartner() {
            this.states.processing = true;
            await this.$nextTick();
            this.pagination.skip += this.pagination.limit;
            await this.$_getPartnersByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        async changePage(value) {
            this.pagination.skip = value;
            await this.$_getPartnersByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        async selectPartnerByNewFilter() {
            this.pagination.skip = 0;
            await this.$_getPartnersByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        //==================//
        //     PRIVATE      //
        //==================//
        //  shortcuts
        $_emitNewSearch() {
            this.states.processing = true;
            this.additional.debounceSearchPartner();
        },
        //  query
        $_setAppliedFilterFromQuery() {
            let query = this.$route.query;
            for (let key in query) {
                switch (key) {
                    case "state":
                    case "role":
                        this.filter[key] =
                            query[key] instanceof Array
                                ? query[key]
                                : [query[key]];
                        break;
                    case "search":
                        this.filter[key] = query[key];
                        break;
                }
            }
        },
        $_getAppliedFilter() {
            let data = { withBlocked: true};
            for (let key in this.filter) {
                switch (key) {
                    case "state":
                        if (!this.filter[key] || this.filter[key].length == 0) {
                            data[key] = ["prereg", "active"];
                        } else data[key] = this.filter[key];
                        break;
                    case 'isSubscribe':
                        if (this.filter[key])
                            data.withUnsubscribe = true;
                        break;
                    default:
                        data[key] = this.filter[key];
                        break;
                }
            }
            return data;
        },
        //  request
        async $_getPartnersByFilter() {
            this.partners = [];
            this.$_setFilter();
            let filter = Object.assign(
                {},
                this.$_getAppliedFilter(),
                this.pagination,
                this.sort
            );

            let headers = this.$store.state.headers;
            let data = await this.$connector.getPartners(filter, headers);
            this.partners = this.partners.concat(data.list);
            this.states.countOfPartners = data.count;
            if (this.partners.length >= data.count) {
                this.states.isEndOfPartners = true;
            } else {
                this.states.isEndOfPartners = false;
            }
            this.$_rebuildPagination();
        },
    },

    watch: {
        async "sort.sortField"() {
            this.$_emitNewSearch();
        },
        async "sort.sortDirection"() {
            this.$_emitNewSearch();
        },
        async "filter.search"() {
            this.$_emitNewSearch();
        },
        async "filter.state"() {
            this.$_emitNewSearch();
        },
        async "filter.isSubscribe"() {
            this.$_emitNewSearch();
        },
    },
};
