<template>
    <div class="creatable-list">
        <div v-if="label" class="creatable-list__label__field">
            <span class="creatable-list__label">{{ label }}</span>
        </div>

        <div
            class="creatable-list__container"
            v-on:dragover.prevent="replaceItem"
        >
            <div
                v-for="(item, index) in list"
                :key="item._listId"
                class="creatable-list__container__item cl-item"
                draggable="true"
                v-on:dragstart="startMove(item._listId)"
                v-on:dragend="stopMove"
                :drag-index="item._listId"
            >
                <div class="cl-item__wrapper" v-if="slotMode">
                    <slot v-bind:item="item"></slot>
                </div>
                <div class="cl-item__wrapper" v-else>
                    <div class="cl-item__label__field" v-if="itemLabel">
                        <span class="cl-item__label">{{ itemLabel }}</span>
                    </div>
                    <div class="cl-item__content">
                        <div class="cl-item__number__field">
                            <div class="cl-item__number__container">
                                <span class="cl-item__number">{{
                                    index + 1
                                }}</span>
                            </div>
                        </div>
                        <input-with-label
                            :placeholder="placeholder"
                            v-model="item.value"
                            class="cl-item__input"
                            :err="getItemErr(index)"
                        >
                        </input-with-label>
                        <div class="cl-item__controlls">
                            <c-button
                                v-if="list.length > 1"
                                class="cl-item__btn cl-item__btn-remove"
                                text="Удалить"
                                v-on:click.native="deleteItem(item._listId)"
                                :imageSrc="
                                    require('@/assets/img/circle_white_red_cancel_icon.svg')
                                "
                            ></c-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="creatable-list__add__field"
            v-if="mode == 'simple' || addHandler == undefined"
        >
            <c-button
                class="creatable-list__add__btn"
                v-if="list.length != maxSize"
                v-on:click.native="addNewItem"
                text="Добавить"
                buttonStyle="transparentPurple"
                :imageSrc="
                    require('@/assets/img/addble-input-button-image.svg')
                "
            ></c-button>
        </div>

        <div class="creatable-list__error__field">
            <transition name="error-field_animation">
                <div v-if="err" class="error-field">
                    <span class="error-field__message">{{ err }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
//  Components
import Input from "./Input.vue";
import Button from "./Button.vue";

//  Libs
import lodash from "lodash";

export default {
    name: "CreatableList",
    components: {
        "input-with-label": Input,
        "c-button": Button,
    },
    data() {
        return {
            list: [],
            states: {
                valueIsSetting: false,
                dragable: null,
            },
        };
    },
    computed: {
        slotMode() {
            return !!Object.keys(this.$scopedSlots).length;
        },
    },
    created() {
        this.$_createListByValue();
        if (this.list.length == 0) this.addNewItem();
    },
    methods: {
        /*****************************/
        /*          PUBLIC           */
        /*****************************/
        addNewItem() {
            let content;
            if (!this.itemTemplate) {
                content = { _listId: this.list.length };
            } else {
                content = new this.itemTemplate();
                content._listId = this.list.length;
            }

            if (this.mode != "simple") {
                content._onRemove = this.deleteItem;
            }
            this.list.push(content);
        },
        deleteItem(id) {
            let index = this.list.findIndex((item) => item._listId == id);
            if (!~index) return;
            this.list.splice(index, 1);
        },
        startMove(id) {
            this.states.dragable = id;
        },
        stopMove() {
            this.states.dragable = null;
            this.$emit("input", this.$_getListValue());
        },
        replaceItem(event) {
            let slave = this.$_getCurrentElem(event);
            let index;
            if (
                !slave ||
                (index = slave.getAttribute("drag-index")) ==
                    this.states.dragable
            ) {
                return;
            }

            this.$_swapItemInList(this.states.dragable, index);
        },
        getItemErr(index) {
            if (this.itemErr && this.itemErr.err && this.itemErr.index == index)
                return this.itemErr.err;
            return;
        },

        /*****************************/
        /*          PRIVATE          */
        /*****************************/

        async $_createListByValue() {
            this.list = [];
            if (this.mode == "simple") {
                this.list = this.value.map((item, index) => {
                    return {
                        _listId: index,
                        value: item,
                    };
                });
            } else {
                this.list = this.value.map((item, index) => {
                    return Object.assign({}, item, {
                        _listId: index,
                        _onRemove: this.deleteItem,
                    });
                });
            }
            await this.$nextTick();
            this.states.valueIsSetting = false;
        },
        $_getListValue() {
            if (this.mode == "simple") {
                return this.list.map((item) => item.value);
            }

            return this.list.map((item) => {
                let sub = lodash.cloneDeep(item);
                delete sub._listId;
                delete sub._onRemove;
                delete sub._onAdd;
                return sub;
            });
        },
        $_getCurrentElem(event) {
            let elem = event.path.find(
                (item) =>
                    item &&
                    item.classList &&
                    item.classList.contains &&
                    item.classList.contains("creatable-list__container__item")
            );
            return elem;
        },
        $_swapItemInList(id1, id2) {
            let position1 = this.list.findIndex((item) => item._listId == id1);
            let swap = this.list[position1];
            let position2 = this.list.findIndex((item) => item._listId == id2);
            swap = this.list.splice(position2, 1, swap);
            this.list.splice(position1, 1, swap[0]);
        },
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.states.valueIsSetting = true;
                if (this.value.length > 0) {
                    this.$_createListByValue();
                    return;
                }

                if (this.list.length == 0) {
                    this.addNewItem();
                }
                this.states.valueIsSetting = false;
            },
        },
        list: {
            deep: true,
            handler() {
                if (this.states.valueIsSetting || this.states.dragable !== null)
                    return;
                this.$emit("input", this.$_getListValue());
            },
        },
    },
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            },
        },
        mode: {
            type: String,
            default: "simple",
        },
        addHandler: {
            type: String, //[undefined, 'delegate']
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        itemLabel: {
            type: String,
            default: undefined,
        },
        itemTemplate: {
            type: Function,
            default: undefined,
        },
        maxSize: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER,
        },
        err: {
            type: String,
            default: "",
        },
        itemErr: {
            type: Object,
            default: () => {
                return {
                    index: 0,
                    err: "",
                };
            },
        },
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
@margin-left: 68rem;
.creatable-list {
    text-align: left;

    &__label {
        font-family: @font-family;
        font-weight: 600;
        font-size: 16rem;
        line-height: 31rem;
        color: #3c4061;

        &__field {
            width: calc(~"100% - @{margin-left}");
        }
    }

    &__container {
        margin-top: 17px;
    }

    &__add {
        &__field {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        &__btn {
            &.button {
                padding-left: 42px;
                padding-right: 42px;
                border-width: 2px;
            }
        }
    }

    &__error__field {
        margin-top: 6px;
        height: 20px;
        position: relative;
    }
}

.cl-item {
    width: 100%;
    @line-main-heigth: 50rem;
    margin-bottom: 20px;
    cursor: move;
    &__wrapper {
        width: 100%;
    }

    &__label {
        font-family: "Montserrat";
        font-size: 16rem;
        line-height: 27rem;
        color: #6b748b;

        &__field {
            margin-left: @margin-left;
            width: 100% - @margin-left;
            text-align: left;
            margin-bottom: 5px;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__number {
        font-family: @font-family;
        font-size: 14rem;
        line-height: 21rem;
        color: #3c434a;

        &__field {
            width: @margin-left;
            text-align: left;
        }

        &__container {
            @circle-diameter: 40rem;
            width: @circle-diameter;
            height: @circle-diameter;
            margin-top: (@line-main-heigth - @circle-diameter) / 2;
            background: transparent;
            border-radius: 999999px;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

    &__input {
        flex-grow: 1;
        margin-right: 20rem;

        .input-with-label {
            &__label {
                display: none;
            }
        }
    }

    &__controlls {
        .button {
            background: transparent;
            padding: 0px;
            height: @line-main-heigth;
        }
    }

    &__btn {
        margin-right: 20rem;
        &-remove {
            width: auto;

            .button {
                &__text {
                    color: #ff7676;
                    margin-left: 5px;
                    border-bottom: 1px solid #ff7676;
                }
            }
        }

        &-add {
            .button {
                &__text {
                    color: #9085e1;
                    margin-left: 5px;
                    border-bottom: 1px solid #9085e1;
                }
            }
        }
    }
}
</style>