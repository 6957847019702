//  Libs
import { isEqual } from "lodash";

//  Mixins
import basis from "./basis/userBasis";

export default {
    mixins: [basis],
    methods: {
        $_setUserData(data) {
            for (let key in this.udata) {
                switch (key) {
                    case "city":
                        this.udata[key] = data.address[key];
                        break;

                    case "pushOfEvents":
                    case "pushOfSos":
                    case "radiusOfSosRequest":
                    case "radiusOfEventRequest":
                        this.udata[key] = data.settings[key];
                        break;
                    case "avatar":
                        break;
                    default:
                        this.udata[key] = data[key];
                        break;
                }
            }
            return;
        },
        $_getFormData() {
            let data = new FormData();
            for (let key in this.udata) {
                switch (key) {
                    case "avatar":
                        if (this.udata.avatar != undefined)
                            data.append(
                                "avatar",
                                this.udata.avatar.file,
                                this.udata.avatar.file.name
                            );
                        break;
                    case "garage": {
                        if (!this.udata.garage || this.udata.garage.length == 0)
                            break;
                        let list = this.udata.garage
                            .filter((item) => item && item.description && item.description.trim().length > 0)
                            .map((item) => {
                                return JSON.stringify({
                                    description: item.description.trim(),
                                    icon: item.icon ? item.icon : 'moto'
                                });
                            });
                        if (list.length != 0)
                            list.forEach((item) => {
                                data.append("garage", item);
                            });
                        else data.append("garage", "{!empty}");
                        break;
                    }
                    case "contacts": {
                        try {
                            let temp = this.udata[key]
                                .filter((item) => typeof item == "object")
                                .map((item) => JSON.stringify(item));
                            if (temp.length > 0)
                                temp.forEach((item) => {
                                    data.append("contacts", item);
                                });
                        } catch (err) {
                            console.log(err);
                        }
                        break;
                    }
                    default:
                        if (
                            (typeof this.udata[key] == "string" ||
                                this.udata[key] instanceof Array) &&
                            this.udata[key].length &&
                            !isEqual(this.udata[key], this.user[key])
                        ) {
                            data.append(key, this.udata[key]);
                        } else if (
                            typeof this.udata[key] == "number" &&
                            Number.isFinite(this.udata[key])
                        ) {
                            data.append(key, this.udata[key]);
                        }
                        break;
                }
            }

            return data;
        },
        async validForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$_checkPhone();
                this.$_checkContact("whatsappLink", "whatsapp");
                this.$_checkContact("telegramLink", "telegram");
                this.$_checkContact("facebookLink", "facebook");
                this.$_checkContact("instagramLink", "instagram");
                this.$_checkContact("vkLink", "vk");
                return false;
            }

            if (this.udata.phone && this.user.phone != this.udata.phone) {
                let deviceData = this.$store.state.headers;
                let res = await this.$connector.checkPhone(
                    this.udata.phone,
                    deviceData
                );
                return res;
            }

            return true;
        },
    },
};
