//  Libs
import { isEqual, debounce } from "lodash";
import Beauty from "../assets/js/beauty";

//  Pipe
// import Pipe from "./../pipe";


export default {
    name: "TicketsEngine",
    data() {
        return {
            pagination: {
                skip: 0,
                limit: 10,
            },
            sort: {
                sortField: "created",
                sortDirection: -1,
            },
            tickets: [],
            view: {
                paginationTemplate: [],
            },
            additional: {
                debounceSearchTicket: null,
            },
            states: {
                inited: false,
                processing: true,
                isEndOfUsers: false,
                countOfUsers: 0,
            },
        };
    },
    computed: {
        currentPage() {
            return this.pagination.skip;
        },
        list() {
            return this.users;
        },
        count() {
            return this.states.countOfUsers;
        },
    },
    created() {
        this.additional.debounceSearchTicket = debounce(
            this.selectTicketsByNewFilter,
            1500
        );
        this.$_setAppliedFilterFromQuery();
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        getViewCategory(ticket) {
            if (ticket && ticket.category) {
                const visilbeLength = 50;
                let text = Beauty.transformToParagraphView(
                    ticket.category
                ).slice(0, visilbeLength);
                return ticket.category.length > visilbeLength
                    ? text.slice(0, visilbeLength - 3) + "..."
                    : text;
            }
            return "Не установлено";
        },
        getTitleCategory(ticket) {
            if (ticket && ticket.category) {
                return Beauty.transformToParagraphView(ticket.category);
            }
            return "Не установлено";
        },
        getViewEmail(ticket) {
            if (ticket && ticket.email) return ticket.email;
            return "Не установлено";
        },
        getViewSubject(ticket) {
            if (ticket && ticket.subject) {
                const visilbeLength = 50;
                let text = Beauty.transformToParagraphView(
                    ticket.subject
                ).slice(0, visilbeLength);
                return ticket.subject.length > visilbeLength
                    ? text.slice(0, visilbeLength - 3) + "..."
                    : text;
            }
            return "Не установлено";
        },
        getViewDate(ticket) {
            if (ticket && ticket.created) {
                return Beauty.beautyDate(ticket.created);
            }
            return "Не установлено";
        },
        async getMoreTicket() {
            this.states.processing = true;
            await this.$nextTick();
            this.pagination.skip += this.pagination.limit;
            await this.$_getTicketsByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        async changePage(value) {
            this.pagination.skip = value;
            await this.$_getTicketsByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        async selectTicketsByNewFilter() {
            this.pagination.skip = 0;
            await this.$_getTicketsByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },

        //==================//
        //     PRIVATE      //
        //==================//
        //  shortcuts
        $_emitNewSearch() {
            this.states.processing = true;
            this.additional.debounceSearchTicket();
        },
        //  query
        $_setAppliedFilterFromQuery() {
            let query = this.$route.query;
            for (let key in query) {
                switch (key) {
                    case "state":
                    case "role":
                        this.filter[key] =
                            query[key] instanceof Array
                                ? query[key]
                                : [query[key]];
                        break;
                    case "search":
                        this.filter[key] = query[key];
                        break;
                }
            }
        },
        //  request
        async $_getTicketsByFilter() {
            this.tickets = [];
            this.$_setFilter();
            let filter = Object.assign(
                {withCreatorData: true},
                this.pagination,
                this.sort
            );

            let headers = this.$store.state.headers;
            let data = await this.$connector.getSupportTickets(filter, headers);
            this.tickets = this.tickets.concat(data.list);
            this.states.countOfUsers = data.count;
            if (this.tickets.length >= data.count) {
                this.states.isEndOfUsers = true;
            } else {
                this.states.isEndOfUsers = false;
            }
            this.$_rebuildPagination();
        },

        //  handlers
        $_rebuildPagination() {
            let page = this.currentPage;
            let countPage = Math.ceil(this.count / this.pagination.limit);
            if (countPage == 0) countPage++;
            if (page < 3) {
                this.view.paginationTemplate = [];
                for (let i = 0; i < Math.min(5, countPage); i++) {
                    this.view.paginationTemplate.push({
                        text: (i + 1).toString(),
                        value: i * this.pagination.limit,
                    });
                }
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Следующая",
                        value: (page + 1) * this.pagination.limit,
                    });
            } else if (page >= countPage - 3) {
                this.view.paginationTemplate = [];
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Предыдущая",
                        value: (page - 1) * this.pagination.limit,
                    });
                for (let i = Math.max(0, countPage - 5); i < countPage; i++) {
                    this.view.paginationTemplate.push({
                        text: (i + 1).toString(),
                        value: i * this.pagination.limit,
                    });
                }
            } else {
                this.view.paginationTemplate = [];
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Предыдущая",
                        value: (page - 1) * this.pagination.limit,
                    });
                for (let i = Math.max(0, page - 2); i < page + 3; i++) {
                    this.view.paginationTemplate.push({
                        text: (i + 1).toString(),
                        value: i * this.pagination.limit,
                    });
                }
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Следующая",
                        value: (page + 1) * this.pagination.limit,
                    });
            }
        },

        //  query
        $_setFilter() {
            if (!this.states.inited) return;
            let query = Object.assign(
                {},
                this.filter,
                this.pagination,
                this.sort
            );
            for (let key in query) {
                if (query[key] instanceof Array) {
                    if (query[key].length == 1) query[key] = query[key][0];
                    if (query[key].length == 0) delete query[key];
                } else if (query[key] !== 0 && !query[key]) delete query[key];
                if (typeof query[key] == "number")
                    query[key] = query[key].toString();
            }

            let lastQuery = this.$route.query;

            if (!isEqual(lastQuery, query))
                this.$router
                    .push({
                        query,
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        }
    },
    watch: {
        async "sort.sortField"() {
            this.$_emitNewSearch();
        },
        async "sort.sortDirection"() {
            this.$_emitNewSearch();
        }
    },
};

