<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
//  CSS
import {} from "@/assets/style/general.less";
export default {};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    position: relative;
}

html {
    font-size: 1px;
    width: 100%;
    height: auto;
}

body{
    margin: 0px;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
