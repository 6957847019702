<template>
    <div
        class="button"
        :class="[`button__direction_${imageDirection}`, styles]"
        @click="click"
        :disabled="disable"
    >
        <span v-if="text" class="button__text">{{ text }}</span>
        <img v-if="image" :src="image" :alt="text" />
    </div>
</template>

<script>
export default {
    name: "Button",
    computed: {
        styles() {
            if (!this.buttonStyle) {
                return "";
            }
            let aStyles = this.buttonStyle.split(" ");
            return aStyles.map((item) => item.toLowerCase());
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
    props: {
        text: {
            type: String,
            default: undefined,
        },
        image: {
            type: String,
            default: undefined,
        },
        imageDirection: {
            type: String,
            default: "right",
        },
        buttonStyle: {
            type: String,
            default: undefined,
        },
        disable: {
            type: Boolean,
            default: undefined,
        },
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: background 250ms ease-in-out 0ms;
    background: #2271b1;
    padding: 14px 12px;
    border-radius: 7px;
    color: #fff;

    &.greenform {
        border-radius: 0px;
        padding: 0px;
        background: #20d38f;

        &:hover {
            background: #23c085;
        }
    }

    &.red {
        background: #fc546e;

        &:hover {
            background: #eb5757;
        }
    }

    &.green {
        background: #20d38f;

        &:hover {
            background: #23c085;
        }
    }

    &.transparent {
        background: transparent;
        padding: 0px;
        color: #3d3e41;
        border-bottom: 1px solid #3d3e41;
        border-radius: 0px;

        &:hover {
            background: transparent;
            color: #686C6D;
            border-color: #686C6D;
        }
    }

    &.upload {
        background: transparent;
        border: 1px dashed #c3c4c7;
        background: transparent;
        transition: box-shadow 250ms ease-in-out 0ms;
        .button__text {
            color: #3d3e41;
            font-style: italic;
        }

        &:hover {
            background: transparent;
            box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
        }
    }

    &__text {
        font-family: @font-family;
        font-size: 14rem;
        font-weight: normal;
        line-height: 21rem;
    }

    &:hover {
        background: #135e96;
    }
}
</style>