<template>
    <div
        class="decision-popup"
        :class="{
            'decision-popup_closed': isClosed,
        }"
    >
        <div class="decision-popup__substrate" v-on:click="closePopup"></div>
        <div class="decision-popup__decision-block decision-block">
            <div class="decision-block__exit-button-wrapper">
                <div
                    class="decision-block__exit-button exit-button"
                    v-on:click="closePopup"
                >
                    <img class="exit-button__image" src="@/assets/img/close.svg"/>
                </div>
            </div>
            <div v-if="title" class="decision-block__title-wrapper">
                <span class="decision-block__title">{{ title }}</span>
            </div>
            <div v-if="message" class="decision-block__message-wrapper">
                <span class="decision-block__message">{{ message }}</span>
            </div>
            <div class="decision-block__buttons-wrapper">
                <custom-button
                    class="decision-block__left-button"
                    :text="leftButtonText"
                    v-on:click.native="leftButtonHandler"
                    buttonStyle="red"
                ></custom-button>
                <custom-button
                    class="decision-block__right-button"
                    :text="rightButtonText"
                    v-on:click.native="rightButtonHandler"
                    buttonStyle="green"
                ></custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "./../Button.vue";
import Pipe from "./../../pipe";

export default {
    name: "DecisionPopUp",
    data() {
        return {
            title: "",
            message: "",
            leftButtonText: "",
            leftButtonHandler: function () {},
            rightButtonText: "",
            rightButtonHandler: function () {},
            isClosed: true,
        };
    },
    components: {
        "custom-button": Button,
    },
    methods: {
        openPopup() {
            this.isClosed = false;
        },
        closePopup() {
            this.isClosed = true;
            this.title = "";
            this.message = "";
            this.leftButtonText = "";
            this.leftButtonHandler = function () {};
            this.rightButtonText = "";
            this.rightButtonHandler = function () {};
        },
        _handleSystemMessage(data) {
            if (data.eventType == "open") {
                this.title = data.title ? data.title : "";
                this.message = data.message ? data.message : "";
                this.leftButtonText = data.leftButtonText;
                this.leftButtonHandler = data.leftButtonHandler;
                this.rightButtonText = data.rightButtonText;
                this.rightButtonHandler = data.rightButtonHandler;

                this.openPopup();
            } else if (data.eventType == "close") {
                this.closePopup();
            }
        },
    },
    created() {
        Pipe.$on(Pipe.pipeList.decisionPopUp.name, this._handleSystemMessage);
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.decision-popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    &__substrate {
        display: block;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .decision-block {
        display: inline-block;
        width: 500px;
        min-width: 320px;
        height: auto;
        background: white;
        border-radius: 10px;
        padding: 17px;
        z-index: 11;

        &__exit-button-wrapper {
            display: block;
            width: 100%;
            height: auto;
            text-align: right;
        }

        .exit-button {
            display: inline-block;
            width: auto;
            height: auto;
            cursor: pointer;

            &__image {
                display: block;
                max-width: 17.5px;
                max-height: 17.5px;
                width: 17.5px;
                object-fit: contain;
                object-position: center center;
            }
        }

        &__title-wrapper {
            display: block;
            width: ~"calc(100% - 46px)";
            height: auto;
            padding: 23px 23px 0 23px;
        }

        &__title {
            display: block;
            width: 100%;
            text-align: left;
            font-family: @font-family;
            font-weight: bolder;
            font-size: 24rem;
            line-height: 170%;
            color: #3c4061;
        }

        &__message-wrapper {
            display: block;
            width: ~"calc(100% - 46px)";
            height: auto;
            padding: 23px;
        }

        &__message {
            display: block;
            width: 100%;
            text-align: left;
            font-family: @font-family;
            font-size: 20rem;
            line-height: 140%;
            color: #3c4061;
        }

        &__buttons-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 23px;
            width: ~"calc(100% - 46px)";
        }

        &__left-button {
            min-width: 25%;
            max-width: 49%;
        }

        &__right-button {
            min-width: 25%;
            max-width: 49%;
        }
    }
}

.decision-popup_closed {
    display: none;
}

.decision-popup_mobile {
    .decision-block {
        border-radius: 10px;
        padding: 2.5%;

        .exit-button {
            &__image {
                width: 12px;
                height: 12px;
            }
        }

        &__title-wrapper {
            width: 100%;
            padding: 5px 0 0 0;
        }

        &__title {
            font-size: 18rem;
        }

        &__message-wrapper {
            width: 100%;
            padding: 22px 0 0 0;
        }

        &__message {
            font-size: 14rem;
        }

        &__buttons-wrapper {
            padding: 34px 0 0 0;
            width: 100%;
        }

        &__left-button,
        &__right-button {
            width: 48.575%;
            min-width: unset;
            max-width: 420px;
        }
    }
}
</style>