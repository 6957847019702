function isPhone(value) {
    if (!value || value.length < 11) return false;
    return true;
}

function isPhoneIfExist(value) {
    if (value && value.length < 11) return false;
    return true;
}

function isExist(value) {
    return value;
}

function isLink(value) {
    return /(^http(s{0,1}):\/\/)|(^$)/.test(value);
}

function isLinkIfExist(value) {
    if (!value) return true;
    return isLink(value);
}

function isUsualPhone(value) {
    return /^[0-9+_\-() ]*$/.test(value);
}

module.exports = {
    isPhone,
    isExist,
    isLink,
    isLinkIfExist,
    isUsualPhone,
    isPhoneIfExist
};
