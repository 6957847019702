//  Libs
import { debounce } from "lodash";

//  Basis
import basis from "./basis/userListBasis";

//  Pipe
import Pipe from "./../pipe";

export default {
    mixins: [basis],
    data() {
        return {
            users: [],
            filter: {
                role: [],
                search: "",
                state: [],
            },
            additional: {
                debounceSearchUser: null,
            },
            states: {
                inited: false,
                processing: true,
                isEndOfUsers: false,
                countOfUsers: 0,
            },
            view: {
                roles: [
                    {
                        text: "Пользователь",
                        value: "user",
                    },
                    {
                        text: "Администратор",
                        value: "admin",
                    },
                ],
            },
        };
    },
    computed: {
        list() {
            return this.users;
        },
        count() {
            return this.states.countOfUsers;
        },
    },
    created() {
        this.additional.debounceSearchUser = debounce(
            this.selectUserByNewFilter,
            1500
        );
        this.$_setAppliedFilterFromQuery();
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        //  view
        alertDeleteUser(user) {
            const that = this;
            Pipe.openDecisionPopUp({
                title: "Удаление пользователя",
                message: `Вы действительно хотите удалить пользователя ${this.getViewName(
                    user
                )}?`,
                leftButtonText: "Нет",
                leftButtonHandler() {
                    Pipe.closeDecisionPopUp();
                },
                rightButtonText: "Да",
                async rightButtonHandler() {
                    try {
                        Pipe.closeDecisionPopUp();
                        that.states.processing = true;
                        await that.$_deleteUser(user.id);
                        that.users = [];
                        await that.$_getUsersByFilter();
                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.complete,
                            "Успешно",
                            "Пользователь успешно удален"
                        );
                    } catch (err) {
                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.error,
                            "Ошибка",
                            "Неудалось удалить пользователя"
                        );
                    } finally {
                        await that.$nextTick();
                        that.states.processing = false;
                    }
                },
            });
        },
        alertBlockUser(user) {
            const that = this;
            let title = "Блокировка пользователя";
            let message = `Вы действительно хотите заблокировать пользователя ${this.getViewName(
                user
            )}?`;
            if (user.isBlocked) {
                title = "Разблокировка пользователя";
                message = `Вы действительно хотите разблокировать пользователя ${this.getViewName(
                    user
                )}?`;
            }
            Pipe.openDecisionPopUp({
                title,
                message,
                leftButtonText: "Нет",
                leftButtonHandler() {
                    Pipe.closeDecisionPopUp();
                },
                rightButtonText: "Да",
                async rightButtonHandler() {
                    try {
                        Pipe.closeDecisionPopUp();
                        that.states.processing = true;
                        await that.$_changeStateOfUser(
                            user.id,
                            !user.isBlocked
                        );
                        that.users = [];
                        await that.$_getUsersByFilter();
                        let message = `Пользователь ${that.getViewName(user)}`;
                        message += user.isBlocked
                            ? " разблокирован"
                            : " заблокирован";
                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.complete,
                            "Успешно",
                            message
                        );
                    } catch (err) {
                        let message = user.isBlocked
                            ? "Неудалось разблокировать пользователя"
                            : "Неудалось заблокировать пользователя";

                        Pipe.popUp(
                            Pipe.pipeList.popUp.types.error,
                            "Ошибка",
                            message
                        );
                    } finally {
                        await that.$nextTick();
                        that.states.processing = false;
                    }
                },
            });
        },
        async getMoreUser() {
            this.states.processing = true;
            await this.$nextTick();
            this.pagination.skip += this.pagination.limit;
            await this.$_getUsersByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        async changePage(value) {
            this.pagination.skip = value;
            await this.$_getUsersByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        async selectUserByNewFilter() {
            this.pagination.skip = 0;
            await this.$_getUsersByFilter();
            await this.$nextTick();
            this.states.processing = false;
        },
        //==================//
        //     PRIVATE      //
        //==================//
        //  shortcuts
        $_emitNewSearch() {
            this.states.processing = true;
            this.additional.debounceSearchUser();
        },
        //  query
        $_setAppliedFilterFromQuery() {
            let query = this.$route.query;
            for (let key in query) {
                switch (key) {
                    case "state":
                    case "role":
                        this.filter[key] =
                            query[key] instanceof Array
                                ? query[key]
                                : [query[key]];
                        break;
                    case "search":
                        this.filter[key] = query[key];
                        break;
                }
            }
        },
        $_getAppliedFilter() {
            let data = { withDeleted: true, withBlocked: true };
            for (let key in this.filter) {
                switch (key) {
                    case "role":
                        if (!this.filter[key] || this.filter[key].length == 0) {
                            data[key] = ["admin", "user"];
                        } else data[key] = this.filter[key];
                        break;
                    case "state":
                        if (!this.filter[key] || this.filter[key].length == 0) {
                            data[key] = ["prereg", "active"];
                        } else data[key] = this.filter[key];
                        break;
                    default:
                        data[key] = this.filter[key];
                        break;
                }
            }
            return data;
        },
        //  request
        async $_getUsersByFilter() {
            this.users = [];
            this.$_setFilter();
            let filter = Object.assign(
                {},
                this.$_getAppliedFilter(),
                this.pagination,
                this.sort
            );

            let headers = this.$store.state.headers;
            let data = await this.$connector.getUsers(filter, headers);
            this.users = this.users.concat(data.list);
            this.states.countOfUsers = data.count;
            if (this.users.length >= data.count) {
                this.states.isEndOfUsers = true;
            } else {
                this.states.isEndOfUsers = false;
            }
            this.$_rebuildPagination();
        },
    },

    watch: {
        async "sort.sortField"() {
            this.$_emitNewSearch();
        },
        async "sort.sortDirection"() {
            this.$_emitNewSearch();
        },
        async "filter.search"() {
            this.$_emitNewSearch();
        },
        async "filter.state"() {
            this.$_emitNewSearch();
        },
        async "filter.role"() {
            this.$_emitNewSearch();
        },
    },
};
