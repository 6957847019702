import Vue from "vue";

const pipe = new Vue({
    data() {
        return {
            pipeList: {
                decisionPopUp: {
                    name: "decision-popup",
                },
                popUp: {
                    name: "system-popup",
                    types: {
                        error: "error",
                        warn: "warn",
                        complete: "complete",
                        info: "info",
                    },
                },
                ticketPopUp: {
                    name: "ticket-popup",
                },
            },
        };
    },
    methods: {
        popUp(type, title, message) {
            this.$emit(this.pipeList.popUp.name, {
                type,
                title,
                message,
            });
        },
        openDecisionPopUp(data) {
            this.$emit(
                this.pipeList.decisionPopUp.name,
                Object.assign({ eventType: "open" }, data)
            );
        },
        closeDecisionPopUp() {
            this.$emit(this.pipeList.decisionPopUp.name, {
                eventType: "close",
            });
        },
        openTicket(ticket, op) {
            this.$emit(
                this.pipeList.ticketPopUp.name,
                Object.assign({ eventType: "open", action: op }, { ticket })
            );
        },
    },
});

export default pipe;
