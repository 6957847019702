<template>
    <div class="popup-container" :class="{ 'popup-container_mobile': false }">
        <div
            v-if="isPerformed"
            class="popup-container__content popup-message"
            :class="[
                getMessageClass(),
                { 'popup-message_visible': animation.isVisible },
            ]"
        >
            <div class="popup-message__icon-container">
                <img
                    v-if="icon"
                    :src="icon"
                    alt="icon"
                    class="popup-message__icon"
                />
            </div>
            <div class="popup-message__title-container">
                <span class="popup-message__title-container__title">{{
                    title
                }}</span>
            </div>
            <div class="popup-message__desc-container">
                <span class="popup-message__desc-container__desc">{{
                    message
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Pipe from "./../../pipe";
import AttentionImage from "@/assets/img/attention.svg";
import CompleteImage from "@/assets/img/complete.svg";

export default {
    name: "PopUp",
    data() {
        return {
            isPerformed: false,
            title: "A",
            message: "B",
            icon: AttentionImage,
            type: "error",
            queue: [],
            animation: {
                transitionDuration: 520,
                visibleDuration: {
                    [Pipe.pipeList.popUp.types.error]: 5000,
                    [Pipe.pipeList.popUp.types.warn]: 5000,
                    [Pipe.pipeList.popUp.types.complete]: 3000,
                    [Pipe.pipeList.popUp.types.info]: 3000,
                },
                isVisible: true,
                isActive: true,
                isForce: false,
                timer: null,
            },
        };
    },
    created() {
        Pipe.$on(Pipe.pipeList.popUp.name, this._handleSystemMessage);
    },
    watch: {
        $route: function () {
            this.queue = [];
            clearTimeout(this.animation.timer);
            this.isPerformed = false;
            this.animation.isForce = false;
            this.animation.isVisible = false;
            this.animation.isActive = false;
        },
        "animation.isActive": function () {
            if (this.animation.isActive) {
                setTimeout(() => {
                    this.animation.isVisible = true;
                    let visibleTime = this.animation.visibleDuration[this.type];
                    if (this.queue.length > 0) {
                        visibleTime = 2040;
                    }
                    clearTimeout(this.animation.timer);
                    this.animation.timer = setTimeout(() => {
                        this.animation.isVisible = false;
                        clearTimeout(this.animation.timer);
                        this.animation.timer = setTimeout(() => {
                            this.animation.isActive = false;
                        }, this.animation.transitionDuration);
                    }, visibleTime - this.animation.transitionDuration);
                }, 50);
            } else {
                this._handleQueue();
            }
        },
    },
    methods: {
        _handleSystemMessage: function (data) {
            this.queue.push(data);
            if (!this.isPerformed) {
                this.isPerformed = true;
                this._handleQueue();
                return;
            }
            return this._fastChange();
        },
        getMessageClass: function () {
            return `popup-message_${this.type}`;
        },
        _handleQueue: function () {
            let message = this.queue.shift();
            if (message) this.showMessage(message);
            else {
                this.isPerformed = false;
                this.animation.isForce = false;
            }
        },
        _fastChange: function () {
            if (
                this.animation.isActive &&
                this.animation.isVisible &&
                this.queue.length > 0 &&
                !this.animation.isForce
            ) {
                this.animation.isForce = true;
                clearTimeout(this.animation.timer);
                this.animation.isVisible = false;
                this.animation.timer = setTimeout(() => {
                    this.animation.isActive = false;
                }, this.animation.transitionDuration);
            }
        },
        showMessage: function (data) {
            this.message = data.message;
            this.type = data.type;
            this.title = data.title;

            switch (this.type) {
                case "error":
                    this.icon = AttentionImage;
                    break;
                case "complete":
                    this.icon = CompleteImage;
                    break;
                default:
                    this.icon = null;
            }
            this.animation.isActive = true;
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.popup-container {
    position: fixed;
    bottom: 1vh;
    right: 1vw;
    width: auto;
    height: auto;
    z-index: 12;
}

.popup-message {
    padding: 10px 10px 10px 20px;
    display: grid;
    grid-template-areas: "image title" "message message";
    grid-template-columns: 22px auto;
    grid-template-rows: auto auto;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 5px rgba(60, 64, 97, 0.1);
    text-align: left;
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
    transition-delay: 0ms;
    transition-property: transform;
    transform: translateX(calc(~"100% + 1vw"));

    &_visible {
        transform: translateX(0);
    }

    &_error {
        background: #fef0ed;
        border-color: #ff7676;
    }

    &_complete {
        background: #e4e5f0;
        border-color: #9085e1;
    }

    &__icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
        &-container {
            width: 24px;
            height: 24px;
        }
    }

    &__title-container {
        grid-area: title;
        margin-left: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        &__title {
            font-size: 16rem;
            font-family: @font-family;
            font-weight: bold;
            color: #3c4061;
        }
    }

    &__desc-container {
        margin-top: 5px;
        grid-area: message;

        &__desc {
            font-size: 14rem;
            font-family: @font-family;
            font-weight: 500;
            color: #3c4061;
        }
    }
}
@keyframes moveFromRight {
    from {
        transform: translateX(calc(~"100% + 1vw"));
    }
    to {
        transform: translateX(0%);
    }
}

.popup-message_animation {
    &-enter-active {
        animation-name: moveFromRight;

        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
    }

    &-leave-active {
        animation-name: moveFromRight;
        animation-direction: reverse;
        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
    }
}

.popup-container_mobile {
    bottom: unset;
    top: 8px;
    right: 2.5%;
    width: ~"calc(100vw - 5.6%)";

    .popup-message {
        &__icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
            &-container {
                width: 24px;
                height: 24px;
            }
        }

        &__title-container {
            &__title {
                font-size: 14rem;
                font-family: "Montserrat-SemiBold";
                color: #3c4061;
            }
        }

        &__desc-container {
            &__desc {
                font-size: 12rem;
                font-family: "Montserrat";
                color: #3c4061;
            }
        }
    }
}
</style>
