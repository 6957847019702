<template>
    <div class="page-tab user-tab">
        <div class="page-tab__title__field">
            <h1 class="page-tab__title">Редактирование партнера</h1>
            <c-button
                class="page-tab__title__back-btn"
                @click="goBack"
                text="Назад"
                buttonStyle="transparent"
            >
            </c-button>
        </div>

        <div class="page-tab__tile">
            <div class="page-tab__group page-tab__group_main">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title"
                        >Управление подпиской</span
                    >
                </div>

                <div class="page-tab__group__content">
                    <div
                        class="checkbox-container checkbox-container_vertical-direction"
                    >
                        <checkbox
                            v-model="udata.isSubscribe"
                            type="toggle"
                        ></checkbox>
                        <label class="checkbox-container__label"
                            >Состояние подписки:
                            <strong>{{ currentSubscribeState }}</strong></label
                        >
                    </div>
                    <c-input
                        label="Дата завершения подписки"
                        class="page-tab__edit__input page-tab__edit__input_subscription-expiration"
                        v-model.trim="udata.subscriptionExpiration"
                        :disable="!udata.isSubscribe"
                        :err="error.name"
                    ></c-input>
                    <custom-datepicker
                        v-model="udata.subscriptionExpiration"
                        :minDate="minDate"
                        valueType="date"
                        :isDropDownModeActive="true"
                        :attachQuerySelector="'.page-tab__edit__input_subscription-expiration .input'"
                        :dropDownTopOffset="12"
                        :dropDownLeftOffset="-15"
                    >
                    </custom-datepicker>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_main">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title">Основные данные</span>
                </div>

                <div class="page-tab__group__content">
                    <c-input
                        label="Имя"
                        class="page-tab__edit__input page-tab__edit__input_name"
                        v-model.trim="udata.name"
                        :err="error.name"
                    ></c-input>

                    <c-input
                        label="Телефон"
                        class="page-tab__edit__input page-tab__edit__input_phone"
                        inputType="phone"
                        v-model.trim="udata.phone"
                        :err="error.phone"
                    ></c-input>

                    <c-select
                        class="page-tab__edit__input page-tab__edit__input_role"
                        label="Роль"
                        :options="view.roles"
                        selectOptionField="value"
                        viewLabel="text"
                        v-model="udata.role"
                    ></c-select>

                    <div class="avatar__field">
                        <label class="avatar__label">Аватар</label>
                        <image-picker
                            class="avatar__picker"
                            @input="setAvatar"
                            @error="errorOfUploadAvatar"
                            :preview="avatarPreview"
                        ></image-picker>
                    </div>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_address">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title">Местоположение</span>
                </div>
                <div class="page-tab__group__content">
                    <c-input
                        label="Город"
                        v-model.trim="udata.city"
                        class="page-tab__edit__input page-tab__edit__input_city"
                    ></c-input>

                    <c-input
                        label="Отображаемый адрес магазина"
                        v-model.trim="udata.textAddress"
                        class="page-tab__edit__input page-tab__edit__input_text-address"
                    ></c-input>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_settings">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title"
                        >Настройка уведомлений</span
                    >
                </div>

                <div class="page-tab__group__content">
                    <number-input
                        label="Радиус SOS сигналов"
                        class="page-tab__edit__input page-tab__edit__input_radiusOfSosRequest"
                        postFix="м"
                        :min="0"
                        v-model.number="udata.radiusOfSosRequest"
                    ></number-input>

                    <number-input
                        label="Радиус получения уведомлений о событиях"
                        class="page-tab__edit__input page-tab__edit__input_radiusOfEventRequest"
                        postFix="м"
                        :min="0"
                        v-model.number="udata.radiusOfEventRequest"
                    ></number-input>

                    <div class="checkbox-container">
                        <checkbox v-model="udata.pushOfEvents"></checkbox>
                        <label class="checkbox-container__label"
                            >Уведоления о событиях</label
                        >
                    </div>
                    <div class="checkbox-container">
                        <checkbox v-model="udata.pushOfSos"></checkbox>
                        <label class="checkbox-container__label"
                            >Уведоления о SOS-сигналах</label
                        >
                    </div>
                </div>
            </div>

            <div class="page-tab__group page-tab__group_contacts">
                <div class="page-tab__group__title__field">
                    <span class="page-tab__group__title">Контакты</span>
                </div>

                <div class="page-tab__group__content">
                    <c-input
                        label="Whatsapp"
                        :value="whatsappLink"
                        @input="setLink($event, 'whatsapp')"
                        key="whatsapp"
                        :err="error.whatsapp"
                        class="page-tab__edit__input page-tab__edit__input_whatsapp"
                    ></c-input>
                    <c-input
                        label="Telegram"
                        :value="telegramLink"
                        @input="setLink($event, 'telegram')"
                        key="telegram"
                        :err="error.telegram"
                        class="page-tab__edit__input page-tab__edit__input_telegram"
                    ></c-input>
                    <c-input
                        label="Facebook"
                        :value="facebookLink"
                        @input="setLink($event, 'facebook')"
                        key="facebook"
                        :err="error.facebook"
                        class="page-tab__edit__input page-tab__edit__input_facebook"
                    ></c-input>
                    <c-input
                        label="Instagram"
                        :value="instagramLink"
                        @input="setLink($event, 'instagram')"
                        key="instagram"
                        :err="error.instagram"
                        class="page-tab__edit__input page-tab__edit__input_instagram"
                    ></c-input>
                    <c-input
                        label="VK"
                        :value="vkLink"
                        @input="setLink($event, 'vk')"
                        key="vk"
                        :err="error.vk"
                        class="page-tab__edit__input page-tab__edit__input_vk"
                    ></c-input>
                    <c-input
                        label="Ссылка на сайт"
                        :value="webLink"
                        @input="setLink($event, 'weblink')"
                        key="weblink"
                        :err="error.weblink"
                        class="page-tab__edit__input page-tab__edit__input_facebook"
                    ></c-input>
                    <c-input
                        label="Контактный номер телефона"
                        :value="phoneLink"
                        @input="setLink($event, 'phone')"
                        key="phoneLink"
                        :err="error.phonelink"
                        class="page-tab__edit__input page-tab__edit__input_facebook"
                    ></c-input>
                </div>
            </div>

            <creatable-list
                label="Гараж"
                mode="object"
                :value="udata.garage"
                v-on:input="setGarage"
                class="page-tab__cl-garage page-tab__edit__creatable-list page-tab__edit__creatable-list_garage"
                :itemTemplate="generateEmptyTransport"
            >
                <template v-slot="data">
                    <div class="cl-item__content page-tab__cl-garage__content">
                        <div class="cl-item__number__field">
                            <div class="cl-item__number__container">
                                <span class="cl-item__number">{{
                                    data.item._listId + 1
                                }}</span>
                            </div>
                        </div>
                        <c-select
                            placeholder="Тип"
                            :value="data.item.icon"
                            :options="view.transportTypes"
                            :closeOnSelect="true"
                            :searchable="false"
                            selectOptionField="value"
                            imageField="icon"
                            :toCustomLabel="(data) => {return data.text}"
                            @input="
                                setTypeOfTransport(data.item._listId, $event)
                            "
                            :imageInSelected="true"
                            class="cl-item__select page-tab__cl-garage__input_icon"
                        >
                        </c-select>

                        <c-input
                            class="cl-item__select page-tab__cl-garage__input_description"
                            placeholder="Название"
                            :value="data.item.description"
                            @input="
                                setDescriptionOfTransport(
                                    data.item._listId,
                                    $event
                                )
                            "
                        ></c-input>

                        <div class="cl-item__controlls">
                            <c-button
                                v-if="data.item._onRemove"
                                class="cl-item__btn cl-item__btn-remove"
                                text="Удалить"
                                @click.native="
                                    data.item._onRemove(data.item._listId)
                                "
                            ></c-button>
                        </div>
                    </div>
                </template>
            </creatable-list>

            <c-select
                label="Категории"
                :options="view.categories"
                v-model="udata.categories"
                :multiple="true"
                :searchable="true"
                :closeOnSelect="false"
                viewMode="flexible"
                class="page-tab__edit__input page-tab__edit__input_categories"
            ></c-select>

            <div class="page-tab__edit__photos__field" ref="photoContainer">
                <div class="c-drag-drop__label__field">
                    <span class="c-drag-drop__label">Фотографии</span>
                </div>

                <drag-and-drop
                    class="page-tab__edit__photo__d-a-d c-drag-drop"
                    :inputClickTrigger="states.dragAndDropTrigger"
                    :fileUploadedHandler="handleDragDrop"
                    :class="{
                        'c-drag-drop_invisible': photos.length != 0,
                    }"
                    :isMultiple="true"
                >
                    <c-button
                        class="c-drag-drop__upload__btn"
                        text="Загрузить файл"
                        @click="uploadFileViaDAD"
                    ></c-button>
                    <span class="c-drag-drop__upload__tips"
                        >или переместите сюда</span
                    >
                </drag-and-drop>

                <div
                    class="page-tab__edit__photos__preview photo-collection"
                    v-if="photos.length > 0"
                >
                    <transition-group
                        name="photo-collection__preview_animation"
                    >
                        <div
                            class="photo-collection__preview__item"
                            v-for="(photo, index) in photos"
                            :key="`photo-${index}`"
                        >
                            <div
                                class="photo-collection__preview__item__container"
                            >
                                <img
                                    class="photo-collection__preview__item__img"
                                    :src="getPhotoPreview(photo)"
                                />
                            </div>
                            <div
                                class="photo-collection__preview__item__remove"
                                v-on:click="removePhoto(photo)"
                                title="Удалить изображение"
                            ></div>
                        </div>
                        <c-button
                            v-if="photos.length < 10"
                            class="photo-collection__preview__item photo-collection__preview__item_add"
                            key="add"
                            @click="uploadFileViaDAD"
                            text="Добавить"
                            buttonStyle="upload"
                        ></c-button>
                    </transition-group>
                </div>
            </div>

            <c-textarea
                label="Описание"
                v-model.trim="udata.description"
            ></c-textarea>

            <div class="page-tab__edit__controlls">
                <c-button
                    class="page-tab__edit__controll"
                    text="Отменить"
                    @click="resetData"
                    buttonStyle="red"
                ></c-button>
                <c-button
                    class="page-tab__edit__controll"
                    text="Сохранить"
                    @click="updateUserData"
                    buttonStyle="green"
                ></c-button>
            </div>
        </div>
    </div>
</template>

<script>
//  Mixins
import PartnerMixin from "./../mixins/partner";

//  Components
import CustomDatepicker from "./../components/CustomDatepicker.vue";
import DragAndDrop from "../components/DragAndDrop.vue";
import NumberInput from "./../components/NumberInput.vue";
import Input from "./../components/Input.vue";
import Select from "./../components/Select.vue";
import CheckBox from "./../components/CheckBox.vue";
import CreatableList from "./../components/CreatableList.vue";
import Textarea from "./../components/Textarea.vue";
import SingleImagePicker from "./../components/SingleImageUploader.vue";
import Button from "../components/Button.vue";

import Pipe from "./../pipe";

//  CSS
import {} from "@/assets/style/edituser.less";

export default {
    name: "EditPartner",
    mixins: [PartnerMixin],
    components: {
        "c-input": Input,
        "number-input": NumberInput,
        "c-select": Select,
        checkbox: CheckBox,
        "creatable-list": CreatableList,
        "c-textarea": Textarea,
        "image-picker": SingleImagePicker,
        "c-button": Button,
        "custom-datepicker": CustomDatepicker,
        "drag-and-drop": DragAndDrop,
    },
    data() {
        return {
            states: {
                dragAndDropTrigger: 0,
            },
        };
    },
    computed: {
        userId() {
            return this.$route.params.id;
        },
        minDate() {
            return Date.now();
        },
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        //  handlers for user data

        //  user data
        afterUpdateUserData() {
            if (this.user.role != "partner") {
                this.$router.replace({
                    name: "EditUser",
                    params: {
                        id: this.userId,
                    },
                });
            }
        },

        //  photo
        afterRemovePhoto() {
            Pipe.popUp(
                Pipe.pipeList.popUp.types.complete,
                "Успешно",
                "Фотография успешно удаления"
            );
        },
        afterFailRemvePhoto(photo, reason) {
            Pipe.popUp(
                Pipe.pipeList.popUp.types.error,
                "Ошибка",
                "Не удалось удалить фотографию"
            );
            console.log(reason);
        },

        //  Drag and Drop handlers
        uploadFileViaDAD() {
            this.states.dragAndDropTrigger = Math.random();
        },
        handleDragDrop(list, file) {
            this.udata.photos.push({
                file: file.blob,
                filename: file.blob.name,
                preview: file.b64,
            });
        },
    },
};
</script>

<style lang='less'>
</style>