<template>
    <div class="page__column__wrapper">
        <span class="page__title">Забыли пароль</span>
        <div class="page__panel panel panel_reset">
            <form class="panel__content" v-on:submit.prevent="submitLogin">
                <c-input
                    class="panel__content__input"
                    label="Логин"
                    name="login"
                    v-model.trim="login"
                    :isRequired="true"
                    inputType="phone"
                    :err="error.login"
                />
                <c-button
                    class="panel__content__submit"
                    text="Запросить код"
                    type="input"
                    @click="submitLogin"
                ></c-button>
            </form>
            <div class="panel__cancel">
                <router-link :to="{ name: 'Login' }">
                    <div class="panel__cancel__content" title="Назад">
                        <img
                            class="panel__cancel__icon"
                            src="@/assets/img/back.svg"
                        />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
//  Extenstions
import { required } from "vuelidate/lib/validators";
import { isPhone } from "../../assets/js/vuelidateExtensions";

//  Components
import Input from "../../components/Input.vue";
import Button from "../../components/Button.vue";
export default {
    name: "ResetIdentifying",
    components: {
        "c-input": Input,
        "c-button": Button,
    },
    data() {
        return {
            states: {
                inited: false,
            },
            login: "",
            error: {
                login: "",
            },
        };
    },
    methods: {
        async submitLogin() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this._checkField("login");
                return;
            }
            try {
                let data = await this.$connector.resetStep1(this.login);
                this.$store.commit("setResetData", {
                    id: data.id,
                    login: this.login,
                });
                this.$router.push({ name: "ResetCode" });
            } catch (err) {
                console.log(err);
                this.error.login = "Не найден пользователь с данным логином";
            }
        },
        _checkField(field) {
            if (!this.$v[field].required) {
                this.error[field] = "Поле обязательно для заполнения";
                return;
            }
            if (!this.$v[field].isPhone) {
                this.error[field] = "Укажите корректный номер";
                return;
            }
            this.error[field] = "";
        },
    },
    validations: {
        login: { required, isPhone },
    },
    watch: {
        login() {
            if (this.states.inited) this._checkField("login");
        },
    },
    created() {
        this.$store.commit("clearReset");
    },
    async mounted() {
        this.states.inited = true;
    },
};
</script>

<style lang="less">
.panel.panel_reset {
    padding: 23px 40px 45px;
    width: 360px;
    min-width: 360px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .panel {
        &__header {
            text-align: center;
            margin-bottom: 40px;

            &__subtitle {
                color: #3c4061;
                font-size: 16rem;
                font-family: "Montserrat";
            }
        }

        &__content {
            width: 100%;

            &__submit {
                margin-top: 20px;
                width: 50%;
            }
        }

        &__cancel {
            position: absolute;
            background: #fff;
            border: 1px solid #c6cbd6;
            border-radius: 99999px;
            top: 50px;
            left: -25px;
            width: 50px;
            height: 50px;
            overflow: hidden;

            &__content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__icon {
                width: 40%;
                height: 40%;
                object-fit: contain;
                object-position: center center;
            }
        }
    }
}
</style>
