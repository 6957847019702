<template>
    <div class="page page-login">
        <div class="page__column">
            <div class="page__column__wrapper">
                <span class="page__title">Вход</span>
                <div class="page__panel panel">
                    <c-input
                        ref="login"
                        class="page__input"
                        label="Логин"
                        inputType="phone"
                        v-model="form.login"
                        :err="error.login"
                    ></c-input>
                    <c-input
                        ref="password"
                        label="Пароль"
                        class="page__input page-login__input_password"
                        inputType="password"
                        v-model="form.password"
                        :err="error.password"
                    ></c-input>
                    <div class="page__submit__field">
                        <c-button
                            class="page__submit__btn"
                            text="Войти"
                            @click="submit"
                        ></c-button>
                    </div>

                    <div class="page__reset__field">
                        <router-link
                            class="page__reset__link"
                            :to="{ name: 'ResetStep1' }"
                        >
                            <span class="page__reset__link__text"
                                >Сбросить пароль</span
                            >
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//  Components
import Input from "./../components/Input";
import Button from "../components/Button";

//  Vuelidate
import { isPhone } from "../assets/js/vuelidateExtensions";
import { required } from "vuelidate/lib/validators";

export default {
    name: "LoginPanel",
    components: {
        "c-input": Input,
        "c-button": Button,
    },
    data() {
        return {
            form: {
                login: "",
                password: "",
            },
            error: {
                login: "",
                password: "",
            },
        };
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$_checkForm();
                return;
            }

            try {
                let res = await this.$connector.authorization(
                    this.form.login,
                    this.form.password
                );
                this.$store.commit("setUserData", res.user);
                this.$store.commit("setDeviceData", {
                    deviceId: res.deviceId,
                    authdata: res.authdata,
                });
                this.$router.push({
                    name: "AdminUsers",
                });
            } catch (err) {
                if (err.response && err.response) {
                    this.error.login =
                        "Пользователь с данным логином и паролем не найден";
                    return;
                }
                console.log(err);
            }
        },

        //==================//
        //     PRIVATE      //
        //==================//

        $_checkForm() {
            let focused = false;
            if (this.$_checkPhone(true)) {
                this.$refs.login.$el.focus();
                focused = true;
            }

            if (this.$_checkPassword() && !focused) {
                this.$refs.password.$el.focus();
                focused = true;
            }
        },
        $_checkPassword() {
            const field = "password";
            if (!this.$v.form[field].required) {
                this.error[field] = "Поле обязательно для заполнения";
                return true;
            }
            this.error[field] = "";
        },
        $_checkPhone(isSubmit) {
            const field = "login";
            if (!this.$v.form[field].required) {
                this.error[field] = "Поле обязательно для заполнения";
                return true;
            }

            if (isSubmit && !this.$v.form[field].isPhone) {
                this.error[field] = "Введите корректный номер телефона";
                return true;
            }
            this.error[field] = "";
        },
    },
    watch: {
        "form.login"() {
            if (this.$_checkPhone()) {
                this.$refs.login.$el.focus();
            }
        },
        "form.password"() {
            if (this.$_checkPassword()) {
                this.$refs.password.$el.focus();
            }
        },
    },
    validations: {
        form: {
            login: {
                required,
                isPhone,
            },
            password: {
                required,
            },
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.page.page-login {
    background: #f0f0f1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page {
        &__title {
            font-family: @font-family;
            font-style: normal;
            font-weight: bolder;
            font-size: 24rem;
            line-height: 170%;
            color: #3c4061;
            margin-bottom: 10px;
        }

        &__panel {
            box-shadow: none;
        }

        &__column {
            width: 20vw;
            min-width: 320px;
            align-items: center;
            justify-content: center;

            &__wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
            }
        }

        &__submit {
            &__field {
                margin-top: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }

            &__btn {
                width: auto;
            }
        }

        &__reset {
            &__field {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }

            &__link {
                text-decoration: none;
                border-bottom: 1px #3c4061 dashed;
                cursor: pointer;
                user-select: none;
                &__text {
                    font-family: @font-family;
                    font-size: 14rem;
                    color: #3c4061;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .page-login {
        &__input {
            &_password {
                align-self: flex-start;
                margin-top: 20px;
            }
        }
    }
}
</style>