<template>
    <div class="page-menu" :class="{ 'page-menu_mobile': false }">
        <div
            class="page-menu__item-wrapper"
            v-for="(el, index) in items"
            :key="el.text + index"
        >
            <router-link
                v-if="!el.linkType || el.linkType === 'router-link'"
                :to="Array.isArray(el.link) ? el.link[0] : el.link"
                class="page-menu__item item"
                :class="{
                    item_selected: checkSelected(el.link),
                }"
            >
                <div class="item__icon-text-wrapper">
                    <img :src="el.icon" class="item__icon" />
                    <span class="item__link-text">{{ el.text }}</span>
                </div>
            </router-link>

            <a
                v-if="el.linkType && el.linkType === 'a'"
                :href="Array.isArray(el.link) ? el.link[0] : el.link"
                class="page-menu__item item"
                :class="{
                    item_selected: checkSelected(el.link, true),
                }"
            >
                <div class="item__icon-text-wrapper">
                    <img :src="el.icon" class="item__icon" />
                    <span class="item__link-text">{{ el.text }}</span>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageMenu",
    methods: {
        checkSelected(link, external) {
            if (external) {
                if (Array.isArray(link)) return link.includes(this.$route.path);
                return link == this.$route.path;
            }
            if (Array.isArray(link))
                return link.find((l) => {
                    return this.$route.path.includes(l);
                });
            return this.$route.path.includes(link);
        },
    },
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.page-menu {
    width: 100%;
    display: block;

    &__item-wrapper {
        display: block;
        width: 100%;
    }

    .item {
        display: block;
        width: 100%;
        text-decoration: none;

        &__icon-text-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-top: 18px;
            padding-bottom: 18px;
            border-bottom: 1px solid #e4e5f0;
        }

        &__icon {
            display: inline-block;
            width: 20px;
            height: auto;
        }

        &__link-text {
            display: inline-block;
            margin-left: 14px;
            font-family: @font-family;
            transition: color 100ms ease-in-out 0ms;
            font-weight: normal;
            font-size: 16rem;
            color: #3c4061;
            text-align: left;

            &:hover {
                color: #58DEAB;
            }
        }
    }

    .cabinet-menu__item-wrapper:first-child .item__icon-text-wrapper {
        padding-top: 0;
    }

    .cabinet-menu__item-wrapper:last-child .item__icon-text-wrapper {
        border: none;
    }

    .item.item_selected {
        .item__{
            &link-text {
                font-family: @font-family;
                color: #20D38F;
            }

            &__icon {
                fill: #20D38F;
            }
        }
    }
}

.cabinet-menu_mobile {
    .item {
        display: grid;
        grid-template-columns: 0.44fr repeat(12, 1fr 0.44fr);
        grid-auto-rows: auto;

        &__icon-text-wrapper {
            grid-area: ~"1/2/2/25";
            padding-top: 14px;
            padding-bottom: 14px;
            position: relative;
        }

        &__icon-text-wrapper::after {
            content: "";
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
            bottom: 17px;
            right: 0;
            width: 6px;
            height: 10px;
        }

        &__icon {
            width: 16px;
        }

        &__link-text {
            margin-left: 9px;
            font-family: "Montserrat-SemiBold";
            font-size: 14rem;
            line-height: 140%;
        }
    }

    .cabinet-menu__item-wrapper:first-child .item__icon-text-wrapper {
        padding-top: 14px;
    }

    .item:hover {
        background: #e2e5ea;

        .item__icon-text-wrapper::after {
            filter: brightness(0.85);
        }

        .item__link-text {
            font-weight: normal;
            color: #3c4061;
        }
    }

    .item.item_selected {
        .item__link-text {
            font-weight: normal;
            color: #3c4061;
        }
    }
}
</style>
