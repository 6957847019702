<template>
    <div
        class="select"
        :class="{ select_error: err, select_disabled: disable }"
    >
        <label v-if="label" class="select__label">{{ label }}</label>
        <multi-select
            class="select__input"
            :class="{
                select__select_multiple: this.multiple,
                select__select_flexible:
                    this.multiple && this.viewMode == 'flexible',
            }"
            ref="multiselect"
            :value="temporaryValue"
            :options="options"
            @input="select"
            :trackBy="selectOptionField"
            :label="viewLabel"
            :show-labels="showLabels"
            :multiple="multiple"
            :searchable="searchable"
            :groupLabel="groupLabel"
            :groupValues="groupValues"
            :groupSelect="groupSelect"
            :closeOnSelect="closeOnSelect"
            :selectLabel="selectLabel"
            :selectGroupLabel="selectGroupLabel"
            :selectedLabel="selectedLabel"
            :deselectLabel="deselectLabel"
            :deselectGroupLabel="deselectGroupLabel"
            :placeholder="placeholder"
            :inernalSearch="false"
            @:search-change="changed"
            @:open="opened"
            @:remove="removed"
            @:select="selected"
            @close="closed"
            :custom-label="toCustomLabel"
            :option-height="optionHeight"
            :limit="limit"
            :limitText="limitText"
            :disabled="disable"
        >
            <span slot="noOptions">{{ emptyLabel }} </span>
            <span slot="noResult">Не найдено </span>
            <template
                slot="option"
                slot-scope="props"
                v-if="imageField.length > 0"
            >
                <div class="select__option__container">
                    <img
                        class="select__option__image"
                        v-if="props.option[imageField].length > 0"
                        :src="props.option[imageField]"
                        :alt="props.option[viewLabel]"
                    />
                    <div class="select__option__desc">
                        <span
                            class="select__options__custom-label"
                            v-if="toCustomLabel"
                            >{{ toCustomLabel(props.option) }}</span
                        >
                    </div>
                </div>
            </template>
            <template
                v-if="imageInSelected"
                slot="singleLabel"
                slot-scope="props"
            >
                <div class="select-single">
                    <div class="select-single__image__container">
                        <img
                            class="select-single__image"
                            :src="props.option.icon"
                            :alt="props.option.text"
                        />
                    </div>
                    <span class="select-single__label" v-if="viewLabel">{{
                        props.option[viewLabel]
                    }}</span>
                </div>
            </template>
        </multi-select>
        <div class="select__error-field">
            <transition name="error-field_animation">
                <div v-if="err" class="error-field">
                    <span class="error-field__message">{{ err }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
//  Components
import VueMultiSelect from "vue-multiselect";

function defaultArray() {
    return [];
}

export default {
    name: "Select",
    components: {
        "multi-select": VueMultiSelect,
    },
    data() {
        return {
            temporaryValue: null,
            localOptions: [],
            startOptions: [],
            isOpened: false,
            tagsField: null,
        };
    },
    mounted() {
        if (this.viewMode == "flexible" && this.searchPlaceholder) {
            let elem = this.$el.querySelector("input.multiselect__input");
            if (elem) elem.setAttribute("placeholder", this.searchPlaceholder);
        }
        this.$_setValue();
    },
    methods: {
        select(data) {
            if (this.selectOptionField) {
                if (!this.multiple) data = data[this.selectOptionField];
                else data = data.map((item) => item[this.selectOptionField]);
            }
            if (this.value != data) this.$emit("input", data);
        },
        changed(query) {
            if (query.length) {
                query = query.toLowerCase();
                const field = this.viewLabel;
                if (!this.groupLabel || !this.groupValues) {
                    this.localOptions.sort((a) => {
                        if (a[field].toLowerCase().startsWith(query)) return 1;
                        return -1;
                    });
                } else {
                    for (let i = 0; i < this.localOptions.length; i++) {
                        this.localOptions[i][this.groupValues].sort((a) => {
                            if (a[field].toLowerCase().startsWith(query))
                                return -1;
                            return 1;
                        });
                    }
                    return;
                }
            }
            this.localOptions = this.startOptions.slice();
        },
        opened() {
            this.isOpened = true;
            if (this.searchable && this.viewMode != "flexible")
                this.$_resizeSearchField();
        },
        removed() {
            if (this.searchable && this.isOpened && this.viewMode != "flexible")
                this.$_resizeSearchField();
        },
        selected() {
            if (this.searchable && this.viewMode != "flexible")
                this.$_resizeSearchField();
        },
        closed() {
            this.isOpened = false;
            if (this.searchable && this.viewMode != "flexible")
                this.$_closeSearchField();
        },
        async $_resizeSearchField() {
            await this.$nextTick();
            if (!this.tagsField) {
                let elem = this.$el.querySelector(".multiselect__tags");
                this.tagsField = elem;
            }
            if (this.value && this.value.length > 0 && this.multiple) {
                if (
                    !this.tagsField.classList.contains("multiselect__tags_high")
                )
                    this.tagsField.classList.add("multiselect__tags_high");
                this.tagsField.style = `grid-template-rows: calc(50% - 4px) calc(50% - 4px); grid-row-gap: 8px; padding-top: 8px; padding-bottom: 8px;`;
            } else {
                this.tagsField.style = `grid-template-rows: 0% 100%; grid-row-gap: 0px; padding-top: undefined; padding-bottom: undefined;`;
                this.tagsField.classList.remove("multiselect__tags_high");
            }
        },
        $_closeSearchField() {
            if (!this.tagsField) {
                let elem = this.$el.querySelector(".multiselect__tags");
                this.tagsField = elem;
            }
            this.tagsField.classList.remove("multiselect__tags_high");
            if (this.multiple)
                this.tagsField.style = `grid-template-rows: 100% 0%; grid-row-gap: 0px; padding-top: undefined; padding-bottom: undefined;`;
            else {
                this.tagsField.style = `grid-template-rows: 0% 100%; grid-row-gap: 0px; padding-top: undefined; padding-bottom: undefined;`;
            }
        },
        $_setValue() {
            if (this.selectOptionField) {
                if (!this.multiple) {
                    let item = this.options.find(
                        (item) => item[this.selectOptionField] == this.value
                    );
                    if (item) this.temporaryValue = item;
                    else this.temporaryValue = undefined;
                } else {
                    let list = [];
                    for (let elem of this.value) {
                        let item = this.options.find(
                            (item) => item[this.selectOptionField] == elem
                        );
                        if (item) list.push(item);
                    }
                    this.temporaryValue = list;
                }
                return;
            }

            this.temporaryValue = this.value;
        },
    },
    watch: {
        options() {
            this.localOptions = this.options;
            this.startOptions = this.options.slice();
        },
        value() {
            this.$_setValue();
        },
    },
    props: {
        value: {
            type: [Array, String, Object, Number],
        },
        options: {
            type: Array,
            default: defaultArray,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: undefined,
        },
        viewLabel: {
            type: String,
            default: "",
        },
        selectOptionField: {
            type: String,
            default: "",
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: "",
        },
        err: {
            type: String,
            default: "",
        },
        selectLabel: {
            type: String,
            default: "Выбрать",
        },
        selectGroupLabel: {
            type: String,
            default: undefined,
        },
        selectedLabel: {
            type: String,
            default: "Выбрано",
        },
        deselectLabel: {
            type: String,
            default: "Удалить",
        },
        deselectGroupLabel: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
        emptyLabel: {
            type: String,
            default: "Список пуст",
        },
        imageField: {
            type: String,
            default: "",
        },
        toCustomLabel: {
            type: Function,
            default: undefined,
        },
        optionHeight: {
            type: Number,
            default: undefined,
        },
        groupLabel: {
            type: String,
            default: undefined,
        },
        groupValues: {
            type: String,
            default: undefined,
        },
        groupSelect: {
            type: Boolean,
            default: false,
        },
        limit: {
            type: Number,
            default: undefined,
        },
        limitText: {
            type: Function,
            default: (count) => `и ${count} др.`,
        },
        viewMode: {
            type: String, // ['simple','flexible']
            default: "simple",
        },
        disable: {
            type: Boolean,
            default: undefined,
        },
        imageInSelected: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
@error-color: #ff5050;
@text-color: #3c434a;
@disable-color: #e5e9ea;
@height: 51px;
.select {
    display: block;
    padding: 0;
    margin: 0;
    text-align: left;

    &_disabled {
        .multiselect--disabled {
            background: transparent;
            opacity: 1;
            .multiselect {
                &__select {
                    background: transparent;
                    color: transparent;
                    opacity: 0.6;
                }

                &__tags {
                    background: @disable-color;
                }

                &__single {
                    background: transparent;
                }
            }
        }
    }

    &_error {
        .select__select {
            .multiselect {
                &__tags {
                    border-color: @error-color;
                }
            }
        }
    }

    &__label {
        font-family: @font-family;
        font-size: 14rem;
        color: @text-color;
        margin-bottom: 8px;
        display: block;
    }

    .multiselect--active {
        .multiselect {
            &__select {
                transform: none;

                &::before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &-single {
        width: 100%;
        height: 100%;
        min-height: @height;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &__image {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 100%;
            &__container {
                width: 25px;
                height: auto;
                display: flex;
            }
        }
    }

    &__input {
        min-height: 51px;
    }

    &__option {
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
        }

        &__image {
            height: 100%;
            object-fit: contain;
            object-position: center center;
            border-radius: 7px;
            margin-right: 10px;
        }
    }

    &__input {
        box-sizing: border-box;
        font-size: 16rem;
        font-family: @font-family;
        color: @text-color;
        min-height: @height;

        &.multi-select__select_multiple {
            .multiselect {
                &__tags {
                    display: grid;
                    grid-template-columns: 75% 25%;

                    grid-template-areas: "tags other" "search search";
                    grid-template-rows: 0px 100%;
                    grid-template-rows: 100% 100%;
                }

                &__placeholder {
                    grid-area: search;
                }
            }
        }

        &.select__select_flexible {
            .multiselect {
                &__placeholder {
                    padding-left: 10px;
                    line-height: 100%;
                }
                &__tag {
                    margin-bottom: 5px;
                    margin-left: 5px;
                    margin-right: 0px;
                }

                &__tags {
                    padding-left: 0px;
                    display: block;
                }

                &__tags-wrap {
                    margin-top: 10px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    align-items: center;
                    flex-wrap: wrap;
                }

                &__input {
                    border-top: 1px solid #c6cbd6;
                    padding: 21px 0px 25px 5px;
                    border-radius: 0px;
                    height: 21rem;
                    border-bottom-left-radius: 7px;
                    border-bottom-right-radius: 7px;
                }
            }
        }

        .multiselect {
            &__select {
                height: 100%;
                padding-top: 0px;
                padding-bottom: 0px;
                width: 44px;
                padding-right: 20px;
                right: 1px;
                box-sizing: border-box;

                &::before {
                    transition: transform 0.2s ease;
                    background-image: url("~@/assets/img/arrow-down.png");
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: 14px;
                    height: 10px;
                    border: none;
                    content: "f";
                    color: transparent;
                    display: inline-block;
                    top: 33%;
                }
            }

            &__single {
                margin: 0px;
                font-family: @font-family;
                font-style: normal;
                font-weight: 500;
                font-size: 16rem;
                line-height: 27rem;
                color: @text-color;
            }

            &__input {
                padding-top: 6px;
            }

            &__tags {
                border: 1px solid #c6cbd6;
                border-radius: 7px;
                padding-top: 0px;
                font-size: 16rem;
                padding-right: 48px;
                height: 100%;
                min-height: @height;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                &_high {
                    height: calc(~"100% * 2 + 8px");
                }

                &-wrap {
                    grid-area: tags;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                }
            }

            &__option {
                font-family: @font-family;
                color: @text-color;
                font-weight: bold;
                &::after {
                    background: inherit;
                    color: inherit;
                }
                &--disabled {
                    background: transparent !important;
                    color: #abafb0 !important;
                }
                &--group.multiselect__option--disabled {
                }

                &--selected {
                    background: #bcecdc;
                    color: #000;
                    font-weight: bolder;
                }

                &--highlight {
                    background: #88e4c2 !important;
                    color: #000;
                }

                &--selected.multiselect__option--highlight {
                    background: #54dca9 !important;
                    color: #000 !important;
                }
            }

            &__tag {
                margin: 0px 10px 0px 0px;
                padding-top: 4px;
                padding-bottom: 4px;
                padding-right: 8px;
                border-radius: 8px;
                background: #54dca9;
                text-overflow: clip;
                font-size: 0px;
                display: inline-grid;
                grid-template-columns: minmax(auto, calc(~"100% - 22px")) 22px;
                grid-auto-rows: minmax(auto, 100%);

                > span {
                    grid-area: ~"1/1/2/2";
                    box-sizing: border-box;
                    font-size: 16rem;
                    align-self: center;
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &-icon {
                    grid-area: ~"1/2/2/3";
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &::after {
                        color: #fff;
                    }

                    &:hover {
                        background: #54dca9;
                    }
                }
            }

            &__strong {
                grid-area: other;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0px;
                margin-left: 5px;
            }

            &__input {
                grid-area: search;
                padding: 0px;
                margin: 0px;
            }

            &__placeholder {
                padding: 0px;
                margin: 0px;
                grid-area: tags;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

            &__content-wrapper {
                &::-webkit-scrollbar {
                    width: 18px;
                    height: 100%;
                    background: rgb(232, 234, 239);
                }

                &::-webkit-scrollbar-button {
                    width: 18px;
                    height: 18px;
                }
                &::-webkit-scrollbar-button:vertical:decrement {
                    // background-image: url("~@/assets/img/scrollbar-up-arrow.svg");
                    background-position: 50% 70%;
                    background-repeat: no-repeat;
                }
                &::-webkit-scrollbar-button:vertical:increment {
                    // background-image: url("~@/assets/img/scrollbar-down-arrow.svg");
                    background-position: 50% 30%;
                    background-repeat: no-repeat;
                }
                &::-webkit-scrollbar-thumb {
                    background: #54dca9;
                    border-radius: 10px;
                    border: 4px solid rgb(232, 234, 239);
                    box-sizing: border-box;
                }
            }
        }
    }

    &__error-field {
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        height: 20px;
        margin-top: 6px;
    }
}

.error-field {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &__message {
        color: #ff7676;
        font-size: 12rem;
        line-height: 15rem;
        font-family: @font-family;
    }
}

.error-field_animation {
    &-enter-active,
    &-leave-active {
        transition-property: all;
        transition-timing-function: ease-in-out;
        transition-duration: 250ms;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
