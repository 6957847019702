var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup ticket-popup",class:{
        'ticket-popup_open': _vm.states.isOpen,
    }},[_c('div',{staticClass:"popup__substrate",on:{"click":_vm.closePopup}}),_c('div',{staticClass:"popup__panel"},[_c('div',{staticClass:"popup__panel__header"},[_c('h3',{staticClass:"popup__panel__header__text"},[_vm._v("Заявка")]),_c('div',{staticClass:"popup__panel__close-btn",on:{"click":_vm.closePopup}},[_c('img',{staticClass:"popup__panel__close-btn__image",attrs:{"src":require("@/assets/img/close.svg")}})])]),_c('div',{staticClass:"popup__panel__body"},[(_vm.ticket.category)?_c('div',{staticClass:"popup__panel__field"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Категория")]),_c('p',{staticClass:"popup__panel__text"},[_vm._v(_vm._s(_vm.ticket.category))])]):_vm._e(),(_vm.ticket.subject)?_c('div',{staticClass:"popup__panel__field"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Тема")]),_c('p',{staticClass:"popup__panel__text"},[_vm._v(_vm._s(_vm.ticket.subject))])]):_vm._e(),(_vm.ticket.email)?_c('div',{staticClass:"popup__panel__field"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Email")]),_c('a',{staticClass:"popup__panel__text popup__panel__text_link",attrs:{"href":("mailto:" + (_vm.ticket.email))}},[_vm._v(_vm._s(_vm.ticket.email))])]):_vm._e(),(_vm.ticket.description)?_c('div',{staticClass:"popup__panel__field"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Описание")]),_c('p',{staticClass:"popup__panel__text",class:{
                        popup__panel__text_scroll: _vm.states.longDescription,
                    }},[_vm._v(" "+_vm._s(_vm.ticket.description)+" ")])]):_vm._e(),(_vm.ticket.creator)?_c('div',{staticClass:"popup__panel__field popup__panel__field_with-top-devider popup__panel__field_with-bottom-devider popup__panel__field_user-info user-info",class:{
                    'user-info_without-avatar':
                        !_vm.ticket.creator.avatar ||
                        !_vm.ticket.creator.avatar.original,
                },on:{"click":function($event){return _vm.goToProfile(_vm.ticket.creator)}}},[(
                        _vm.ticket.creator.avatar &&
                        _vm.ticket.creator.avatar.original
                    )?_c('div',{staticClass:"popup__panel__field popup__panel__field_avatar"},[_c('img',{staticClass:"popup__panel__image",attrs:{"src":_vm.ticket.creator.avatar.original,"alt":_vm.ticket.creator.name}})]):_vm._e(),(_vm.ticket.creator.name)?_c('div',{staticClass:"popup__panel__field popup__panel__field_name"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Имя")]),_c('p',{staticClass:"popup__panel__text"},[_vm._v(" "+_vm._s(_vm.ticket.creator.name)+" ")])]):_vm._e(),(_vm.ticket.creator.role)?_c('div',{staticClass:"popup__panel__field popup__panel__field_role"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Роль")]),_c('p',{staticClass:"popup__panel__text"},[_vm._v(" "+_vm._s(_vm.getViewRole(_vm.ticket.creator.role))+" ")])]):_vm._e()]):_vm._e(),(_vm.ticket.created)?_c('div',{staticClass:"popup__panel__field"},[_c('p',{staticClass:"popup__panel__label"},[_vm._v("Дата")]),_c('p',{staticClass:"popup__panel__text"},[_vm._v(_vm._s(_vm.getViewDate(_vm.ticket)))])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }