<template>
    <div
        class="custom-datepicker"
        :class="{
            'custom-datepicker_drop-down-mode': isDropDownModeActive,
            'custom-datepicker_drop-downed': isDropDowned,
        }"
        :style="
            isDropDownModeActive
                ? `top: ${dropDownModePosition.top}px; left: ${dropDownModePosition.left}px;`
                : undefined
        "
        :id="datepickerId"
        v-on:pointerenter="onCustomDatepickerFocused"
        v-on:pointerleave="onCustomDatepickerUnfocused"
    >
        <div class="custom-datepicker__controls controls">
            <div class="controls__month-year-wrapper">
                <span class="controls__month" v-on:click="selectionOf = 'month'">{{
                    monthName
                }}</span>
                <span class="controls__year" v-on:click="selectionOf = 'year'">{{
                    year
                }}</span>
            </div>
            <div v-if="selectionOf != 'month'" class="controls__arrows-wrapper">
                <div
                    class="controls__left-arrow"
                    v-on:click="prevButtonClick"
                    :class="{
                        'controls__left-arrow_disabled':
                            selectionOf === 'year' && yearsPage <= 0,
                    }"
                >
                    <img
                        class="controls__arrow-image"
                        src="@/assets/img/datepicker-left-arrow.svg"
                    />
                </div>
                <div
                    class="controls__right-arrow"
                    v-on:click="nextButtonClick"
                    :class="{
                        'controls__right-arrow_disabled':
                            selectionOf === 'year' &&
                            yearsPage >= Math.floor(years.length / 35),
                    }"
                >
                    <img
                        class="controls__arrow-image"
                        src="@/assets/img/datepicker-right-arrow.svg"
                    />
                </div>
            </div>
        </div>
        <div
            class="custom-datepicker__month-grid month-grid"
            v-if="selectionOf == 'weekDay'"
        >
            <div class="month-grid__weekday-title-wrapper month-grid__mond">
                <span class="month-grid__weekday-title month-grid__mond">Пн</span>
            </div>
            <div class="month-grid__weekday-title-wrapper month-grid__tue">
                <span class="month-grid__weekday-title">Вт</span>
            </div>
            <div class="month-grid__weekday-title-wrapper month-grid__wed">
                <span class="month-grid__weekday-title">Ср</span>
            </div>
            <div class="month-grid__weekday-title-wrapper month-grid__thur">
                <span class="month-grid__weekday-title">Чт</span>
            </div>
            <div class="month-grid__weekday-title-wrapper month-grid__frid">
                <span class="month-grid__weekday-title">Пт</span>
            </div>
            <div class="month-grid__weekday-title-wrapper month-grid__sat">
                <span class="month-grid__weekday-title">Сб</span>
            </div>
            <div class="month-grid__weekday-title-wrapper month-grid__sun">
                <span class="month-grid__weekday-title">Вс</span>
            </div>
            <div
                class="month-grid__weekday-wrapper"
                v-for="(day, index) in month"
                :key="index"
                :style="`grid-column-start: ${day.weekNumber}; grid-column-end: ${
                    day.weekNumber + 1
                };`"
            >
                <div
                    class="month-grid__weekday-span-wrapper"
                    :class="{
                        'month-grid__weekday-span-wrapper_selected': day.isSelected,
                        'month-grid__weekday-span-wrapper_today': day.isToday,
                        'month-grid__weekday-span-wrapper_disabled': day.isDisabled,
                    }"
                    v-on:click="weekdaySelected(index)"
                >
                    <span class="month-grid__weekday">{{ day.digit }}</span>
                </div>
            </div>
        </div>
        <div
            class="custom-datepicker__months-grid months-grid"
            v-if="selectionOf == 'month'"
        >
            <div
                class="months-grid__month-wrapper"
                v-for="(month, index) in months"
                :key="month + index"
            >
                <div
                    class="months-grid__month-span-wrapper"
                    v-on:click="monthSelected(index)"
                >
                    <span class="months-grid__month">{{ month }}</span>
                </div>
            </div>
        </div>
        <div
            class="custom-datepicker__years-grid years-grid"
            v-if="selectionOf == 'year'"
        >
            <div
                class="years-grid__year-wrapper"
                v-for="(year, index) in years.slice(yearsPage * 35, yearsPage * 35 + 35)"
                :key="year.toString() + index"
            >
                <div
                    class="years-grid__year-span-wrapper"
                    v-on:click="yearSelected(yearsPage * 35 + index)"
                >
                    <span class="years-grid__year">{{ year }}</span>
                </div>
            </div>
        </div>
        <div
            class="custom-datepicker__drop-down-mode-controls drop-down-mode-controls"
            v-if="isDropDownModeActive && selectionOf == 'weekDay'"
        >
            <span
                class="drop-down-mode-controls__close-button"
                v-on:click="closeDatepicker"
                >Закрыть</span
            >
        </div>
    </div>
</template>

<script>
import Moment from "moment-timezone";

export default {
    name: "CustomDatepicker",
    data() {
        return {
            startPoint: null,
            month: [],
            months: [],
            years: [],
            yearsPage: 0,
            monthName: "",
            year: "",
            selected: null,
            selectedWeek: null,
            dropDownModePosition: {
                top: 0,
                left: 0,
            },
            isDropDowned: false,
            selectionOf: "weekDay", // enum['weekday', 'month', 'year'],
            userInCustomDatepicker: false,
        };
    },
    props: {
        value: [String, Number],
        valueType: {
            type: String, // enum['timestamp', 'date']
            default: "timestamp",
        },
        isDropDownModeActive: {
            type: Boolean,
            default: false,
        },
        attachQuerySelector: {
            type: String,
            default: "body",
        },
        dropDownTopOffset: {
            type: Number,
            default: 0,
        },
        dropDownLeftOffset: {
            type: Number,
            default: 0,
        },
        ignoreScroll: {
            type: Boolean,
            default: false,
        },
        selectMode: {
            type: String, // enum["day", "week", "time"],
            default: "day",
        },
        minDate: {
            type: Number,
            default: 0,
        },
        maxDate: {
            type: Number,
            default: 0,
        },
        timeZone: {
            type: String,
            default: Moment.tz.guess(),
        },
    },
    computed: {
        datepickerId() {
            return `custom-datepicker-${Math.trunc(Math.random() * 1000000)}`;
        },
    },
    methods: {
        onCustomDatepickerFocused() {
            this.userInCustomDatepicker = true;
        },
        onCustomDatepickerUnfocused() {
            if (this.$isMobile)
                setTimeout(() => {
                    this.userInCustomDatepicker = false;
                }, 20);
            else this.userInCustomDatepicker = false;
        },
        emitInputEvent() {
            let dataToEmit = this.formatDate(this.selected);

            this.$emit("input", dataToEmit);
            if (this.userInCustomDatepicker) this.$emit("inputByDatepicker", dataToEmit);
        },
        closeDatepicker() {
            if (!this.isDropDowned) return;
            this.isDropDowned = false;
        },
        async openDatepicker() {
            if (this.isDropDowned) return;

            this.isDropDowned = true;
            this.selectionOf = "weekDay";
            this.selected = this.value
                ? this.parseDate(this.value)
                : Moment().tz(this.timeZone).startOf("day").unix() * 1000;
            this.startPoint =
                Moment(this.selected).tz(this.timeZone).startOf("month").unix() * 1000;
            this.yearsPage = 0;

            await this.$nextTick();
            this.updateStartPointDeps();
        },
        async weekdaySelected(index) {
            this.month.forEach((day) => {
                day.isSelected = false;
            });

            this.month[index].isSelected = true;
            this.selected =
                Moment(this.startPoint)
                    .tz(this.timeZone)
                    .add(this.month[index].digit - 1, "days")
                    .startOf("day")
                    .unix() * 1000;

            await this.$nextTick();
            this.updateStartPointDeps();

            this.emitInputEvent();
        },
        async monthSelected(index) {
            let dateAfterSelect =
                Moment(this.selected)
                    .tz(this.timeZone)
                    .set("month", index)
                    .startOf("day")
                    .unix() * 1000;

            if (this.minDate && dateAfterSelect < this.minDate)
                this.selected =
                    Moment(this.minDate).tz(this.timeZone).startOf("day").unix() * 1000;
            else if (this.maxDate && dateAfterSelect > this.maxDate)
                this.selected =
                    Moment(this.maxDate).tz(this.timeZone).startOf("day").unix() * 1000;
            else this.selected = dateAfterSelect;

            this.selectionOf = "weekDay";

            await this.$nextTick();
            this.updateStartPointDeps();

            this.emitInputEvent();
        },
        async yearSelected(index) {
            let dateAfterSelect =
                Moment(this.selected)
                    .tz(this.timeZone)
                    .set("year", this.years[index])
                    .startOf("day")
                    .unix() * 1000;

            if (this.minDate && dateAfterSelect < this.minDate)
                this.selected =
                    Moment(this.minDate).tz(this.timeZone).startOf("day").unix() * 1000;
            else if (this.maxDate && dateAfterSelect > this.maxDate)
                this.selected =
                    Moment(this.maxDate).tz(this.timeZone).startOf("day").unix() * 1000;
            else this.selected = dateAfterSelect;

            this.selectionOf = "weekDay";

            await this.$nextTick();
            this.updateStartPointDeps();

            this.emitInputEvent();
        },
        nextButtonClick() {
            switch (this.selectionOf) {
                case "weekDay":
                    this.nextMonth();
                    break;
                case "month":
                case "year":
                    this.nextYearPage();
                    break;
            }
        },
        nextMonth() {
            this.startPoint =
                Moment(this.startPoint)
                    .tz(this.timeZone)
                    .add(1, "months")
                    .startOf("month")
                    .unix() * 1000;
            this.updateStartPointDeps();
        },
        nextYearPage() {
            if (this.yearsPage < Math.floor(this.years.length / 35)) this.yearsPage++;
        },
        prevButtonClick() {
            switch (this.selectionOf) {
                case "weekDay":
                    this.prevMonth();
                    break;
                case "month":
                case "year":
                    this.prevYearPage();
                    break;
            }
        },
        prevMonth() {
            this.startPoint =
                Moment(this.startPoint)
                    .tz(this.timeZone)
                    .subtract(1, "months")
                    .startOf("month")
                    .unix() * 1000;
            this.updateStartPointDeps();
        },
        prevYearPage() {
            if (this.yearsPage > 0) this.yearsPage--;
        },
        updateStartPointDeps() {
            this.monthName = Moment(this.startPoint).tz(this.timeZone).format("MMMM");
            this.monthName =
                this.monthName.charAt(0).toUpperCase() + this.monthName.slice(1);
            this.year = Moment(this.startPoint).tz(this.timeZone).format("YYYY");

            let daysInMonth = Moment(this.startPoint).tz(this.timeZone).daysInMonth();
            this.month = [];

            for (let i = 0; i < daysInMonth; i++) {
                let isSel = false;
                let isDisabled = false;
                let isToday = false;

                let curDay = Moment(this.startPoint).tz(this.timeZone).add(i, "days");

                // selected check
                if (this.selectMode === "day")
                    isSel = this.selected
                        ? Moment(this.selected).tz(this.timeZone).isSame(curDay)
                        : false;
                if (this.selectMode === "week")
                    isSel = this.selectedWeek
                        ? curDay.unix() * 1000 >= this.selectedWeek.startOfWeek &&
                          curDay.unix() * 1000 <= this.selectedWeek.endOfWeek
                        : false;

                // today check
                isToday = Moment().tz(this.timeZone).startOf("day").isSame(curDay);

                // disabled check
                if (
                    (this.maxDate && curDay.unix() * 1000 > this.maxDate) ||
                    (this.minDate && curDay.unix() * 1000 < this.minDate)
                )
                    isDisabled = true;

                this.month.push({
                    digit: i + 1,
                    weekNumber: curDay.isoWeekday(),
                    isSelected: isSel,
                    isToday,
                    isDisabled,
                });
            }
        },
        initAutoCloseEvent() {
            if (!this.attachQuerySelector) return;

            let attachElement = document.querySelector(this.attachQuerySelector);

            if (!attachElement) return;

            if (Array.isArray(attachElement)) attachElement = attachElement[0];

            document.addEventListener(
                "click",
                (event) => {
                    let isPartOfDatepicker = false;
                    const path = event.composedPath();

                    for (let p of path)
                        if (p && p.id === this.datepickerId) {
                            isPartOfDatepicker = true;
                            break;
                        }

                    if (
                        !isPartOfDatepicker &&
                        !attachElement.contains(event.target) &&
                        this.isDropDowned
                    ) {
                        this.closeDatepicker();
                        event.preventDefault();
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                    }
                },
                true
            );
        },
        initAutoOpenEvent() {
            if (!this.attachQuerySelector) return;

            let attachElement = document.querySelector(this.attachQuerySelector);

            if (!attachElement) return;

            if (Array.isArray(attachElement)) attachElement = attachElement[0];

            attachElement.addEventListener("click", () => {
                this.openDatepicker();
            });
        },
        initAutoBlurEvent() {
            if (!this.attachQuerySelector) return;

            let attachElement = document.querySelector(this.attachQuerySelector);

            if (!attachElement) return;

            if (Array.isArray(attachElement)) attachElement = attachElement[0];

            attachElement.addEventListener("blur", () => {
                let dataToEmit = this.formatDate(this.selected);
                this.$emit("input", dataToEmit);
                this.$emit("inputByAttachElement", dataToEmit);
            });
        },
        initMonthsList() {
            let startOfYear = Moment()
                .tz(this.timeZone)
                .startOf("year")
                .subtract(1, "month");
            this.months = [];

            for (let i = 0; i < 12; i++) {
                startOfYear = startOfYear.add(1, "month");

                let monthName = startOfYear.format("MMMM");
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

                this.months.push(monthName);
            }
        },
        initYearsList() {
            let curYear = Number(Moment().tz(this.timeZone).format("YYYY"));
            this.years = [];

            for (let i = curYear; i > curYear - 121; i--) this.years.push(i);
        },
        parseDate(date) {
            let defaultFullDate = Moment().tz(this.timeZone).startOf("day").unix() * 1000;
            let defaultDay = Moment(defaultFullDate).tz(this.timeZone).get("date");
            let defaultMonth = Moment(defaultFullDate).tz(this.timeZone).get("month");
            let defaultYear = Moment(defaultFullDate).tz(this.timeZone).get("year");

            if (this.valueType === "timestamp")
                return date === undefined || date === null || Number.isNaN(Number(date))
                    ? defaultFullDate
                    : Number(date);

            if (!date) return defaultFullDate;

            let splittedDate = this.splitDate(date);

            if (!splittedDate || !splittedDate.length) return defaultFullDate;

            let dayToSet =
                Number.isNaN(splittedDate[0]) ||
                splittedDate[0] < 1 ||
                splittedDate[0] > 31
                    ? defaultDay
                    : splittedDate[0];

            let monthToSet =
                Number.isNaN(splittedDate[1]) ||
                splittedDate[1] < 1 ||
                splittedDate[1] > 12
                    ? defaultMonth
                    : splittedDate[1] - 1;

            let yearToSet =
                Number.isNaN(splittedDate[2]) ||
                splittedDate[2] < 1900 ||
                splittedDate[2] > 9999
                    ? defaultYear
                    : splittedDate[2];

            let dateAfterParse =
                Moment()
                    .tz(this.timeZone)
                    .set("year", yearToSet)
                    .set("month", monthToSet)
                    .set("date", dayToSet)
                    .startOf("day")
                    .unix() * 1000;

            if (this.minDate && dateAfterParse < this.minDate)
                dateAfterParse =
                    Moment(this.minDate).tz(this.timeZone).startOf("day").unix() * 1000;
            else if (this.maxDate && dateAfterParse > this.maxDate)
                dateAfterParse =
                    Moment(this.maxDate).tz(this.timeZone).startOf("day").unix() * 1000;

            return dateAfterParse;
        },
        formatDate(date) {
            return this.valueType === "timestamp"
                ? date
                : Moment(date).tz(this.timeZone).format("DD.MM.YYYY");
        },
        splitDate(date) {
            if (!date) return null;

            let splittedDate = date
                .toString()
                .trim()
                .replace(/ |-|:/g, ".")
                .replace(/\.+/g, ".")
                .split(".");

            return [
                splittedDate[0] ? Number(splittedDate[0]) : NaN,
                splittedDate[1] ? Number(splittedDate[1]) : NaN,
                splittedDate[2] ? Number(splittedDate[2]) : NaN,
            ];
        },
    },
    mounted: async function () {
        Moment.locale("ru");

        this.selected = this.value
            ? this.parseDate(this.value)
            : Moment().tz(this.timeZone).startOf("day").unix() * 1000;
        this.startPoint =
            Moment(this.selected).tz(this.timeZone).startOf("month").unix() * 1000;

        await this.$nextTick();

        this.updateStartPointDeps();
        this.initMonthsList();
        this.initYearsList();

        if (this.isDropDownModeActive) {
            this.initAutoCloseEvent();
            this.initAutoOpenEvent();
            this.initAutoBlurEvent();
        }
    },
    watch: {
        value: async function () {
            this.selected = this.parseDate(this.value);

            await this.$nextTick();
            this.updateStartPointDeps();
        },
        selected: function () {
            this.selectedWeek = {
                startOfWeek:
                    Moment(this.selected).tz(this.timeZone).startOf("isoWeek").unix() *
                    1000,
                endOfWeek:
                    Moment(this.selected).tz(this.timeZone).endOf("isoWeek").unix() *
                    1000,
            };

            if (
                this.selected >
                    Moment(this.startPoint)
                        .tz(this.timeZone)
                        .add(this.month.length - 1, "days")
                        .startOf("day")
                        .unix() *
                        1000 ||
                this.selected <
                    Moment(this.startPoint).tz(this.timeZone).startOf("day").unix() * 1000
            ) {
                this.startPoint =
                    Moment(this.selected).tz(this.timeZone).startOf("month").unix() *
                    1000;

                this.updateStartPointDeps();
            }
        },
        async timeZone() {
            this.selected = this.value
                ? this.parseDate(this.value)
                : Moment().tz(this.timeZone).startOf("day").unix() * 1000;
            this.startPoint =
                Moment(this.selected).tz(this.timeZone).startOf("month").unix() * 1000;

            await this.$nextTick();
            this.updateStartPointDeps();
        },
        selectMode() {
            this.updateStartPointDeps();
        },
        minDate() {
            this.updateStartPointDeps();
        },
        maxDate() {
            this.updateStartPointDeps();
        },
        isDropDowned(newValue) {
            if (!newValue) return;

            let attachElement = document.querySelector(this.attachQuerySelector);

            if (!attachElement) return;

            if (Array.isArray(attachElement)) attachElement = attachElement[0];

            let attachElementPosition = attachElement.getBoundingClientRect();
            let scrollTop = this.ignoreScroll
                ? 0
                : window.pageYOffset || document.documentElement.scrollTop;
            let scrollLeft = this.ignoreScroll
                ? 0
                : window.pageXOffset || document.documentElement.scrollLeft;

            this.dropDownModePosition.top =
                attachElementPosition.top +
                scrollTop +
                attachElementPosition.height +
                this.dropDownTopOffset;

            this.dropDownModePosition.left =
                attachElementPosition.left + scrollLeft + this.dropDownLeftOffset;
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.custom-datepicker {
    display: block;
    width: 230px;
    height: 276px;
    padding: 12px;
    text-align: center;

    .controls {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;

        &__month-year-wrapper {
            display: inline-block;
        }

        &__month {
            display: inline-block;
            font-family: @font-family;
            font-size: 14rem;
            font-weight: 800;
            color: #3c4061;
            cursor: pointer;
            border-radius: 10px;
            margin-right: 12px;
        }

        &__year {
            display: inline-block;
            font-family: @font-family;
            font-size: 14rem;
            font-weight: 800;
            color: #3c4061;
            cursor: pointer;
            border-radius: 10px;
        }

        &__month:hover,
        &__year:hover {
            box-shadow: 0 0 0px 3px #f1f1f1, -4px 0 0px 3px #f1f1f1, 4px 0 0px 3px #f1f1f1,
                0 0 0px 20px #f1f1f1 inset;
        }

        &__arrows-wrapper {
            display: inline-block;
            user-select: none;
        }

        &__left-arrow {
            display: inline-block;
            cursor: pointer;
        }

        &__right-arrow {
            display: inline-block;
            cursor: pointer;
            margin-left: 24px;
        }

        &__left-arrow_disabled,
        &__right-arrow_disabled {
            pointer-events: none;
            opacity: 0.35;
        }

        &__arrow-image {
            display: block;
            width: auto;
            height: 11rem;
            filter: grayscale(1) brightness(0.4);
        }
    }

    &__month-grid {
        margin-top: 14px;
    }

    .month-grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(7, 1fr);
        width: 100%;
        height: ~"calc(100% - 50px)";

        &__mond {
            grid-area: ~"1/1/2/2";
        }

        &__tue {
            grid-area: ~"1/2/2/3";
        }

        &__wed {
            grid-area: ~"1/3/2/4";
        }

        &__thur {
            grid-area: ~"1/4/2/5";
        }

        &__frid {
            grid-area: ~"1/5/2/6";
        }

        &__sat {
            grid-area: ~"1/6/2/7";
        }

        &__sun {
            grid-area: ~"1/7/2/8";
        }

        &__weekday-title-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: auto;
            height: auto;
        }

        &__weekday-title {
            display: inline-block;
            width: auto;
            font-family: @font-family;
            font-size: 10rem;
            font-weight: normal;
            color: #3c4061;
        }

        &__weekday-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        &__weekday-span-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 80%;
            height: 80%;
            cursor: pointer;
            border-radius: 100%;
        }

        &__weekday {
            display: inline-block;
            width: auto;
            font-family: @font-family;
            font-size: 10rem;
            font-weight: bold;
            color: #3c4061;
        }

        &__weekday-span-wrapper:hover {
            background: #f1f1f1;

            .month-grid__weekday {
                color: #3c4061;
            }
        }

        &__weekday-span-wrapper_today,
        &__weekday-span-wrapper_today:hover {
            background: #9085e154;

            .month-grid__weekday {
                color: #20D38F;
            }
        }

        &__weekday-span-wrapper_selected,
        &__weekday-span-wrapper_selected:hover {
            background: #20D38F;

            .month-grid__weekday {
                color: white;
            }
        }

        &__weekday-span-wrapper_disabled {
            pointer-events: none;
            background: transparent;

            .month-grid__weekday {
                color: #3c4061;
                opacity: 0.35;
            }
        }
    }

    &__months-grid {
        margin-top: 14px;
    }

    .months-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        width: 100%;
        height: ~"calc(100% - 50px)";

        &__month-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        &__month-span-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            height: 40%;
            cursor: pointer;
            border-radius: 20px;
        }

        &__month {
            display: inline-block;
            width: auto;
            font-family: @font-family;
            font-size: 12rem;
            font-weight: bold;
            color: #3c4061;
        }

        &__month-span-wrapper:hover {
            background: #f1f1f1;

            .months-grid__month {
                color: #3c4061;
            }
        }
    }

    &__years-grid {
        margin-top: 14px;
    }

    .years-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(7, 1fr);
        width: 100%;
        height: ~"calc(100% - 50px)";

        &__year-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        &__year-span-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 95%;
            height: 65%;
            cursor: pointer;
            border-radius: 20px;
        }

        &__year {
            display: inline-block;
            width: auto;
            font-family: "Montserrat";
            font-size: 12rem;
            font-weight: bold;
            color: #3c4061;
        }

        &__year-span-wrapper:hover {
            background: #f1f1f1;

            .years-grid__year {
                color: #3c4061;
            }
        }
    }

    &__drop-down-mode-controls {
        margin-top: 6px;
    }

    .drop-down-mode-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 92%;
        margin: 0 4% 0 4%;

        &__close-button,
        &__accept-selected-weekday-button {
            font-family: @font-family;
            font-weight: 800;
            font-size: 14rem;
            line-height: 140%;
            color: #3c4061;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    &_drop-down-mode {
        display: none;
        position: absolute;
        z-index: 10;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background: white;
    }

    &_drop-downed {
        display: block;
    }
}
</style>
