<template>
    <div
        class="input code-input"
        :class="{
            'code-input_error': err.length > 0,
        }"
    >
        <div class="code-input__value-container">
            <div
                v-for="(elem, index) in parts"
                :key="elem.id"
                :index="index"
                class="code-input__part"
            >
                <input
                    v-if="!isPhone"
                    v-model="parts[index].value"
                    class="code-input__part__input"
                    v-on:keydown.prevent="partFill($event, index)"
                    maxlength="1"
                />

                <input
                    v-else
                    :value="parts[index].value"
                    v-on:input="partFillByAndroid($event, index)"
                    class="code-input__part__input"
                    maxlength="2"
                />
            </div>
        </div>
        <div class="code-input__error-field">
            <transition name="error-field-animation">
                <div v-if="err" class="error-field">
                    <span class="error-field__message">{{ err }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "CodeInput",
    data() {
        return {
            parts: [],
        };
    },
    computed: {
        isPhone() {
            if (window.innerWidth < 768)
                return true;
            return false;
        }
    },
    mounted() {
        let template = "";
        if (this.$isAndroid) {
            template = ' ';
        }
        for (let i = 0; i < this.length; i++) {
            this.parts.push({ id: `code-${i}`, value: template });
        }
    },
    watch: {
        code: function () {
            if (this.code && this.code.length > 0)
                this.parts = this.code.split("").map((item, index) => {
                    return { id: `code-${index}`, value: item };
                });
        },
    },
    methods: {
        partFill: function (e, index) {
            if (e.keyCode == 8 || e.keyCode == 46) {
                this.parts[index].value = "";
                if (index > 0) index -= 1;
                this._focusPart(index);
                return;
            }

            if (e.keyCode < 48 || e.keyCode > 90) {
                return;
            }

            this.parts[index].value = e.key;

            let tempCode = this.parts.map((item) => item.value).join("");
            if (tempCode.length == this.length) {
                this.$emit("input", tempCode);
            }

            if (index + 1 != this.length) {
                this._focusPart(index + 1);
                return;
            }

            return;
        },
        partFillByAndroid: function (e, index) {
            if (!e.data || e.data.length == 0) {
                this.parts[index].value = " ";
                if (index > 0) index -= 1;
                this._focusPart(index);
                return;
            }

            e.keyCode = e.data.charCodeAt(0);
            if (e.keyCode < 48 || e.keyCode > 90) {
                return;
            }

            this.parts[index].value = e.data;

            let tempCode = this.parts
                .map((item) => item.value)
                .filter((item) => item != " ")
                .join("");
            if (tempCode.length == this.length) {
                this.$emit("input", tempCode);
            }

            if (index + 1 != this.length) {
                this._focusPart(index + 1);
                return;
            }

            return;
        },
        _focusPart: function (index) {
            this.$el
                .querySelector(`div.code-input__part[index="${index}"] input`)
                .focus();
            return;
        },
    },
    props: {
        code: String,
        length: {
            type: Number,
            default: 4,
        },
        err: String,
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.code-input {
    &__value-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__part {
        &__input {
            border-radius: 10px;
            border: 1px solid #c6cbd6;
            width: calc(~"100% - 20px");
            padding: 10px;
            text-align: center;
            font-size: 32rem;
            width: 32rem;
            font-family: @font-family;
            font-weight: bold;
            transition: border-color 0.25s;

            &:focus {
                outline: none;
            }
        }
    }

    &__error-field {
        margin-top: 6px;
        height: 20px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        &__message {
            font-family: @font-family;
        }
    }

    &_mobile {
        .code-input {
            &__part {
                &__input {
                    font-size: 24rem;
                    width: 24rem;
                }
            }
        }

        .error-field {
            &__message {
                font-size: 10rem;
                line-height: 12rem;
            }
        }
    }
}

.code-input_error {
    .code-input__part {
        &__input {
            border-color: #ff7676;
        }
    }
}

.error-field {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &__message {
        color: #ff7676;
        font-size: 12rem;
        line-height: 15rem;
        font-family: @font-family;
        font-weight: 600;
        font-family: @font-family;
    }
}

.error-field_animation {
    &-enter-active,
    &-leave-active {
        transition-property: all;
        transition-timing-function: ease-in-out;
        transition-duration: 250ms;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
