<template>
    <div class="page page-reset">
        <div class="page__column">
            <router-view :key="$router.path" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Reset",
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.page.page-reset{

    background: #f0f0f1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page {
        &__title {
            font-family: @font-family;
            font-style: normal;
            font-weight: bolder;
            font-size: 24rem;
            line-height: 170%;
            color: #3c4061;
            margin-bottom: 10px;
        }

        &__panel {
            box-shadow: none;
        }

        &__column {
            width: 20vw;
            min-width: 360px;
            align-items: center;
            justify-content: center;

            &__wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
            }
        }
    }
}
</style>
