<template>
    <div class="form__page">
        <div class="form__column">
            <div class="form__header">
                <h1 class="form__header__text">Оплата</h1>
            </div>

            <div class="form__body">
                <c-input
                    label="Номер телефона"
                    v-model="form.phone"
                    inputType="phone"
                    :err="error.phone"
                    ref="phone"
                    :disable="processing"
                    class="form__input"
                ></c-input>
                <c-select
                    label="Количество месяцев"
                    v-model="form.month"
                    :options="monthRange"
                    placeholder="Выберите количество месяцев"
                    :err="error.month"
                    class="form__select"
                    :disable="processing"
                    selectOptionField="value"
                    viewLabel="text"
                ></c-select>
                <span class="form__error">{{ generalError }}</span>
                <c-button
                    text="Перейти к оплате"
                    @click="submit"
                    class="form__submit-btn"
                    buttonStyle="greenForm"
                ></c-button>
            </div>
            <div class="form__footer">
                <a
                    href="https://freekassa.ru"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="form__footer-href"
                >
                    <img
                        src="https://cdn.freekassa.ru/banners/small-dark-2.png"
                        title="Прием платежей на сайте для физических лиц и т.д."
                        class="form__footer-img"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
//  Components
import Input from "../components/Input.vue";
import Button from "../components/Button.vue";
import Select from "../components/Select.vue";

//  Vuelidate
import { isPhone } from "../assets/js/vuelidateExtensions";
import { required, between } from "vuelidate/lib/validators";

//  Tools
import axios from "axios";

export default {
    name: "Form",
    components: {
        "c-input": Input,
        "c-button": Button,
        "c-select": Select,
    },
    data() {
        return {
            form: {
                phone: "",
                month: 1,
            },
            error: {
                phone: "",
                month: "",
            },
            generalError: "",
            processing: false,
        };
    },
    computed: {
        monthRange() {
            let res = [];
            for (let i = 1; i <= 24; i++) {
                res.push({
                    text: i,
                    value: i,
                });
            }

            return res;
        },
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        async submit() {
            this.processing = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$_checkForm();
                this.processing = false;
                return;
            }

            try {
                const url = `${this.$hostname}/payment/link`;
                let res = await axios.post(
                    url,
                    {
                        phone: this.form.phone,
                        month: this.form.month,
                    },
                    {
                        withCredentials: true,
                    }
                );
                let link = res.data.link;
                if (link) {
                    this.$router.push(link);
                }
            } catch (err) {
                console.log(err);
            }
        },

        //==================//
        //     PRIVATE      //
        //==================//

        $_checkForm() {
            let focused = false;
            if (this.$_checkPhone(true)) {
                this.$refs.phone.$el.focus();
                focused = true;
            }

            if (this.$_checkMonth() && !focused) {
                this.$refs.month.$el.focus();
                focused = true;
            }
        },
        $_checkMonth() {
            const field = "month";
            if (!this.$v.form[field].required) {
                this.error[field] = "Поле обязательно для заполнения";
                return true;
            }
            if (!this.$v.form[field].between) {
                this.error[field] = "Выберите количество месяцев от 1 до 24";
                return true;
            }
            this.error[field] = "";
        },
        $_checkPhone(isSubmit) {
            const field = "phone";
            if (!this.$v.form[field].required) {
                this.error[field] = "Поле обязательно для заполнения";
                return true;
            }

            if (isSubmit && !this.$v.form[field].isPhone) {
                this.error[field] = "Введите корректный номер телефона";
                return true;
            }
            this.error[field] = "";
        },
    },
    watch: {
        "form.phone"() {
            if (this.$_checkPhone()) {
                this.$refs.phone.$el.focus();
            }
        },
        "form.month"() {
            if (this.$_checkMonth()) {
                this.$refs.month.$el.focus();
            }
        },
    },
    validations: {
        form: {
            phone: {
                required,
                isPhone,
            },
            month: {
                required,
                between: between(1, 24),
            },
        },
    },
};
</script>

<style lang='less'>
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
.form {
    &__page {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 100vh;
        background: #f0f4f5;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    &__column {
        min-width: 320px;
        max-width: 512px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }

    &__header {
        padding: 30px 30px;
        box-sizing: border-box;
        text-align: center;

        &__text {
            font-size: 24px;
            font-family: @font-family;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: #4f4f4f;
            padding: 0px;
            margin: 0px;
        }
    }

    &__body {
        margin-top: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    &__footer {
        font-size: 16rem;
        padding: 14px 30px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #383b46;
        &-href {
            text-decoration: none;
            font-size: inherit;
            color: #fff;
        }

        &-img {
            height: 20px;

        }
    }

    &__input,
    &__select {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(~"100% - 20px");
    }

    &__select {
        margin-top: 20px;
    }

    &__submit-btn {
        margin-top: 20px;
        height: 50px;
    }
}
</style>