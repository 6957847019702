<template>
    <div class="page__column__wrapper">
        <span class="page__title"
            >На номер *{{ phone4 }} был отправлен код подтверждения, введите его
            для сброса пароля</span
        >
        <div class="page__panel panel panel_reset">
            <form class="panel__content">
                <code-input
                    class="panel__content__code-input"
                    v-model="code"
                    :err="error.code"
                    :length="4"
                ></code-input>
                <c-button
                    class="panel__content__submit"
                    text="Отправить"
                    @click="submit"
                ></c-button>
            </form>
            <div class="panel__link-container">
                <div
                    class="panel__link link"
                    :class="{ panel__link_disable: time > 0 }"
                    v-on:click="resendCode"
                >
                    <span class="link__text">Отправить еще раз</span>
                </div>
                <div class="panel__timer__container">
                    <span class="panel__timer__value" v-if="time > 0"
                        >Повторно отправить код можно через
                        {{ viewTime() }}</span
                    >
                </div>
            </div>
            <div class="panel__cancel">
                <router-link :to="{ name: 'ResetStep1' }">
                    <div class="panel__cancel__content">
                        <div class="panel__cancel__content" title="Назад">
                            <img
                                class="panel__cancel__icon"
                                src="@/assets/img/back.svg"
                            />
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
//  Extenstions
import { required } from "vuelidate/lib/validators";

//  Components
import CodeInput from "../../components/CodeInput.vue";
import Button from "../../components/Button.vue";

//  Pipe
import Pipe from "../../pipe";

export default {
    name: "Code",
    components: {
        "code-input": CodeInput,
        "c-button": Button,
    },
    data() {
        return {
            id: null,
            login: null,
            code: "",
            error: {
                code: "",
            },
            timerPointer: null,
            time: null,
            constTime: 2 * 60 * 1000,
        };
    },
    created() {
        this.$store.commit("restore");
        this.id = this.$store.state.resetData.id;
        this.login = this.$store.state.resetData.login;
        if (!this.id || !this.login) {
            this.$router.replace({ name: "ResetStep1" });
            return;
        }
        this.$_startTimer();
    },
    computed: {
        phone4() {
            if (this.login)
                return `${this.login.slice(-4, -2)}-${this.login.slice(-2)}`;
            return '';
        },
    },
    methods: {
        viewTime() {
            let min = Math.floor(this.time / (60 * 1000));
            let sec = Math.round((this.time / 1000) % 60);
            let res = "";
            if (min > 0) {
                res += `${min} мин. `;
            }

            return res + `${sec} сек.`;
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$_checkField("code");
                return;
            }
            try {
                await this.$connector.validateCode(this.login, this.code);
                this.$store.commit("setResetCode", this.code);
                this.$router.push({ name: "NewPassword" });
            } catch (error) {
                this.error.code = "Указан неправильный код";
            }
        },
        $_checkField: function (field) {
            if (!this.$v[field].required) {
                this.error[field] = "Поле обязательно для заполнения";
                return;
            }
            this.error[field] = "";
        },
        $_startTimer: function () {
            if (this.timerPointer) {
                cancelAnimationFrame(this.timerPointer);
            }
            this.time = this.constTime;
            let start = performance.now();
            const that = this;
            function step(timestamp) {
                var progress = timestamp - start;
                that.time = that.constTime - progress;
                if (that.time <= 0) {
                    that.timerPointer = null;
                    that.time = 0;
                    return;
                }
                that.timerPointer = requestAnimationFrame(step);
            }
            that.timerPointer = requestAnimationFrame(step);
        },
        async resendCode() {
            if (this.time) return;
            try {
                await this.$connector.sendCode(this.login);
                this._startTimer();
                Pipe.popUp(
                    Pipe.pipeList.popUp.types.complete,
                    "Успешно",
                    "Код успешно отправлен"
                );
            } catch (err) {
                Pipe.popUp(
                    Pipe.pipeList.popUp.types.error,
                    "Ошибка",
                    "Не удалось отправить код. Попробуйте снова"
                );
            }
        },
    },
    validations: {
        code: { required },
    },
    watch: {
        code() {
            this.$_checkField("code");
        },
    },
};
</script>

<style lang="less">
.panel.panel_reset {
    padding: 23px 40px 45px;
    width: 360px;
    min-width: 360px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .panel {
        &__header {
            text-align: center;
            margin-bottom: 40px;

            &__subtitle {
                color: #3c4061;
                font-size: 16rem;
                font-family: "Montserrat";
            }
        }

        &__content {
            width: 100%;

            &__submit {
                margin-top: 20px;
                width: 50%;
            }
        }

        &__cancel {
            position: absolute;
            background: #fff;
            border: 1px solid #c6cbd6;
            border-radius: 99999px;
            top: 50px;
            left: -25px;
            width: 50px;
            height: 50px;
            overflow: hidden;

            &__content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__icon {
                width: 40%;
                height: 40%;
                object-fit: contain;
                object-position: center center;
            }
        }
    }
}
</style>
