<template>
    <div class="page admin-page">
        <div class="page__column">
            <div class="admin-page__left-menu__wrapper">
                <div class="admin-page__left-menu">
                    <div class="admin-page__controlls">
                        <cabinet-menu :items="items"></cabinet-menu>
                    </div>
                    <div class="admin-page__exit__wrapper">
                        <div
                            class="admin-page__exit__button"
                            v-on:click="exitCabinet"
                        >
                            <img
                                class="admin-page__exit__button__icon"
                                src="@/assets/img/logout.svg"
                            />
                            <span class="admin-page__exit__button__text"
                                >Выйти</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="page__content">
                <router-view :key="$router.path" />
            </div>
        </div>
        <pop-up></pop-up>
        <decision-pop-up></decision-pop-up>
        <ticket-pop-up></ticket-pop-up>
    </div>
</template>

<script>
//  Components
import PageMenu from "../components/PageMenu.vue";
import PopUp from "@/components/PopUps/PopUp.vue";
import DecisionPopUp from "@/components/PopUps/DecisionPopUp.vue";
import TicketPopUp from "../components/PopUps/TicketPopUp.vue";

//  Libs
import { checkAuth } from "../assets/js/authChecker";

export default {
    name: "AdminPanel",
    components: {
        "cabinet-menu": PageMenu,
        "pop-up": PopUp,
        "decision-pop-up": DecisionPopUp,
        "ticket-pop-up": TicketPopUp
    },
    data() {
        return {
            items: [
                {
                    icon: require("@/assets/img/user.svg"),
                    text: "Пользователи",
                    link: "/admin/panel/users",
                },
                {
                    icon: require("@/assets/img/deal.svg"),
                    text: "Партнеры",
                    link: "/admin/panel/partners",
                },
                {
                    icon: require('@/assets/img/support.svg'),
                    text: "Поддержка",
                    link: "/admin/panel/tickets"
                }
            ],
        };
    },
    created() {
        this.$store.commit("restore");
        try {
            checkAuth(this.$store);
        } catch (err) {
            //  redir
            this.$router.replace('/');
        }
    },
    methods: {
        async exitCabinet() {
            try {
                let deviceData = this.$store.state.headers;
                await this.$connector.logout(deviceData);
                this.$router.push({
                    name: "Login",
                });
            } catch (err) {
                this.$router.push({
                    name: "Login",
                });
            } finally {
                localStorage.clear();
            }
        },
    },
};
</script>

<style lang="less">
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

.admin-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-width: 1280px;
    .page {
        &__column {
            flex-grow: 1;
            display: grid;
            grid-template-columns: 3.66fr 17.6fr;
            grid-template-rows: 100%;
        }

        &__content {
            grid-area: ~"1/2/2/3";
        }
    }

    &__left-menu {
        width: 100%;
        height: 100%;
        padding-top: 84px;
        box-sizing: border-box;
        display: inline-grid;
        grid-template-columns: 1fr 2.66fr;
        grid-auto-rows: min-content;
        background: #f7f7fb;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

        &__wrapper {
            grid-area: ~"1/1/2/2";
            text-align: left;
            width: 100%;
            padding-right: 5px;

            box-sizing: border-box;
        }
    }

    &__controlls {
        grid-area: ~"1/2/2/3";
    }

    &__exit {
        &__wrapper {
            margin-top: 10px;
            grid-area: ~"2/2/3/3";
            margin-top: 50px;
            margin-bottom: 50px;
        }

        &__button {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            cursor: pointer;
            user-select: none;

            &__icon {
                display: inline-block;
                width: 18px;
                &_rotate-180 {
                    transform: rotate(180deg);
                }
            }

            &__text {
                font-family: @font-family;
                font-size: 16rem;
                line-height: 20px;
                color: #3c4061;
                margin-left: 15px;
                margin-top: 2px;
            }
        }
    }
}
</style>