//  Libs
import { isEqual } from "lodash";
import Beauty from "../../assets/js/beauty";

export default {
    data() {
        return {
            pagination: {
                skip: 0,
                limit: 10,
            },
            sort: {
                sortField: "created",
                sortDirection: -1,
            },
            view: {
                paginationTemplate: [],
                states: [
                    {
                        text: "Активированные аккаунты",
                        value: "active",
                    },
                    {
                        text: "Ожидают подтверждения",
                        value: "prereg",
                    },
                ],
            },
        };
    },
    computed: {
        currentPage() {
            return this.pagination.skip;
        },
    },
    methods: {
        //==================//
        //      PUBLIC      //
        //==================//
        //  view
        getStatus(user) {
            if (user.isDeleted) {
                return "Удален";
            }
            if (user.isBlocked) {
                return "Заблокирован";
            }
            if (user.platform == "local" && user.type == "prereg") {
                return "Ожидает";
            }
            return "Активирован";
        },
        getViewName(user) {
            if (user && user.name)
                return Beauty.transformToParagraphView(user.name);
            return "Не установлено";
        },
        getViewEmail(user) {
            if (user && user.email) return user.email;
            return "Не установлено";
        },
        getViewPhone(user) {
            if (user && user.phone) return Beauty.beautyPhone(user.phone);
            return "Не установлено";
        },
        getViewRole(user) {
            if (user && user.role) {
                switch (user.role) {
                    case "user":
                        return "Пользователь";
                    case "partner":
                        return "Партнер";
                    case "admin":
                        return "Администратор";
                }
            }
            return "Не установлено";
        },
        getPlatformAsText(user) {
            if (!user) return "Не установлено";
            switch (user.platform) {
                case "local": {
                    return "Сервис";
                }
                default:
                    return user.platform;
            }
        },
        getImageByPlatform(user) {
            switch (user.platform) {
                case "local":
                    return require("../../assets/img/server.svg");
                case "vk":
                    return require("../../assets/img/vk.svg");
                case "facebook":
                    return require("../../assets/img/facebook.svg");
                case "instagram":
                    return require("../../assets/img/instagram.svg");
            }
        },

        //==================//
        //     PROTECTED    //
        //==================//

        //  handlers
        $_rebuildPagination() {
            let page = this.currentPage;
            let countPage = Math.ceil(
                this.count / this.pagination.limit
            );
            if (countPage == 0) countPage++;
            if (page < 3) {
                this.view.paginationTemplate = [];
                for (let i = 0; i < Math.min(5, countPage); i++) {
                    this.view.paginationTemplate.push({
                        text: (i + 1).toString(),
                        value: i * this.pagination.limit,
                    });
                }
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Следующая",
                        value: (page + 1) * this.pagination.limit,
                    });
            } else if (page >= countPage - 3) {
                this.view.paginationTemplate = [];
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Предыдущая",
                        value: (page - 1) * this.pagination.limit,
                    });
                for (let i = Math.max(0, countPage - 5); i < countPage; i++) {
                    this.view.paginationTemplate.push({
                        text: (i + 1).toString(),
                        value: i * this.pagination.limit,
                    });
                }
            } else {
                this.view.paginationTemplate = [];
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Предыдущая",
                        value: (page - 1) * this.pagination.limit,
                    });
                for (let i = Math.max(0, page - 2); i < page + 3; i++) {
                    this.view.paginationTemplate.push({
                        text: (i + 1).toString(),
                        value: i * this.pagination.limit,
                    });
                }
                if (countPage > 5)
                    this.view.paginationTemplate.push({
                        text: "Следующая",
                        value: (page + 1) * this.pagination.limit,
                    });
            }
        },

        //  query
        $_setFilter() {
            if (!this.states.inited) return;
            let query = Object.assign(
                {},
                this.filter,
                this.pagination,
                this.sort
            );
            for (let key in query) {
                if (query[key] instanceof Array) {
                    if (query[key].length == 1) query[key] = query[key][0];
                    if (query[key].length == 0) delete query[key];
                } else if (query[key] !== 0 && !query[key]) delete query[key];
                if (typeof query[key] == "number")
                    query[key] = query[key].toString();
            }

            let lastQuery = this.$route.query;

            if (!isEqual(lastQuery, query))
                this.$router
                    .push({
                        query,
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        },

        async $_deleteUser(id) {
            try {
                let headers = this.$store.state.headers;
                await this.$connector.deleteUser(id, headers);
            } catch (err) {
                console.log(err);
            }

            let index = this.list.findIndex((user) => user.id == id);
            if (!~index) return;
            this.list.splice(index, 1);
        },
        async $_changeStateOfUser(id, newState) {
            let user;
            try {
                let headers = this.$store.state.headers;
                user = await this.$connector.changeStateUser(
                    id,
                    newState,
                    headers
                );
            } catch (err) {
                console.log(err);
            }

            let index = this.list.findIndex((user) => user.id == id);
            if (!~index) return;
            this.list[index].isBlocked = user.isBlocked;
        },
    },
};
