<template>
    <div class="page__column__wrapper">
        <span class="page__title">Установка нового пароля</span>
        <div class="page__panel panel panel_reset panel_new-password">
            <form class="panel__content" novalidate="true">
                <c-input
                    label="Пароль"
                    name="password"
                    inputType="password"
                    class="panel__content__password main"
                    :isRequired="true"
                    v-model.trim="password"
                    :err="error.password"
                ></c-input>
                <c-input
                    label="Подтверждение пароля"
                    name="password-verify"
                    inputType="password"
                    class="panel__content__password slave"
                    :isRequired="true"
                    v-model.trim="passwordVerify"
                    :err="error.passwordVerify"
                ></c-input>
                <c-button
                    class="panel__content__submit"
                    text="Отправить"
                    type="input"
                    @click="submit"
                ></c-button>
            </form>
            <div class="panel__cancel">
                <router-link to="/reset/code">
                    <div class="panel__cancel__content" title="Назад">
                        <img
                            class="panel__cancel__icon"
                            src="@/assets/img/back.svg"
                        />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
//  Components
import Input from "../../components/Input.vue";
import Button from "../../components/Button.vue";

//  Extensions
import { required, minLength, sameAs } from "vuelidate/lib/validators";

//  Pipe
import Pipe from "../../pipe";

export default {
    name: "NewPassword",
    components: {
        "c-input": Input,
        "c-button": Button,
    },
    data() {
        return {
            code: null,
            id: null,
            login: null,
            password: "",
            passwordVerify: "",
            error: {
                password: "",
                passwordVerify: "",
            },
        };
    },
    async mounted() {
        await this.$store.commit("restore");
        await this.$nextTick();
        this.id = this.$store.state.resetData.id;
        this.login = this.$store.state.resetData.login;
        this.code = this.$store.state.resetData.code;
        if (!this.id || !this.login || !this.code) {
            this.$router.replace({ name: "ResetCode" });
            return;
        }
    },
    validations: {
        password: {
            required,
            minLength: minLength(6),
        },
        passwordVerify: {
            sameAsPassword: sameAs("password"),
        },
    },
    watch: {
        password() {
            this.$_checkPassword();
        },
        passwordVerify() {
            this.$_checkPasswordVerify();
        },
    },
    methods: {
        $_checkPassword() {
            if (!this.$v.password.required) {
                this.error.password = "Поле обязательно для заполнения";
                return;
            } else if (!this.$v.password.minLength) {
                this.error.password =
                    "Пароль должен содержать минимум 6 символов";
                return;
            }
            this.error.password = "";
        },
        $_checkPasswordVerify() {
            if (!this.$v.password.minLength) {
                this.error.passwordVerify =
                    "Пароль должен содержать минимум 6 символов";
                return;
            } else if (!this.$v.passwordVerify.sameAsPassword) {
                this.error.passwordVerify = "Пароли не совпадают";
                return;
            }
            this.error.passwordVerify = "";
            return;
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this._checkPassword();
                this._checkPasswordVerify();
                return false;
            }

            let data = {
                login: this.$data.login,
                code: this.$data.code,
                password: this.$data.password,
                system: "web",
            };
            try {
                let res = await this.$connector.resetConfirm(data);
                if (res.code == 200) {
                    this.$store.commit("clearReset");
                    this.$store.commit("setUserData", res.data.user);
                    this.$store.commit("setDeviceData", {
                        deviceId: res.data.deviceId,
                        authdata: res.data.authdata,
                    });
                    this.$router.push({
                        name: "AdminUsers",
                    });
                } else if (res.code == 202) {
                    Pipe.popUp(
                        Pipe.pipeList.popUp.types.complete,
                        "Что-то пошло не так",
                        "Обратитесь к администратору"
                    );
                    setTimeout(() => {
                        this.$router.push({ name: "Login" });
                    }, 500);
                }
            } catch (err) {

                if (!err.response) {
                    Pipe.popUp(
                        Pipe.pipeList.popUp.types.error,
                        "Ошибка",
                        "Не удалось сохранить новый пароль. Попробуйте снова"
                    );
                } else if (err.response.status == 400) {
                    Pipe.popUp(
                        Pipe.pipeList.popUp.types.error,
                        "Ошибка",
                        "Некорректные данные"
                    );
                } else {
                    Pipe.popUp(
                        Pipe.pipeList.popUp.types.error,
                        "Ошибка",
                        "Не удалось сохранить новый пароль. Попробуйте снова"
                    );
                }
            }
        },
    },
};
</script>

<style lang="less">
.panel.panel_new-password {
    .input__container {
        &:first-child {
            margin-bottom: 20px;
        }
    }
}
</style>
