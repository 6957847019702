import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from 'vuelidate';

Vue.config.productionTip = false;
if (process.env.NODE_ENV != 'production')
    Vue.prototype.$hostname = 'http://localhost:3000';
else
    Vue.prototype.$hostname = 'https://controlridersapp.ru/api';

import Connector from './assets/connector/';
Vue.prototype.$connector = Connector;
Connector._setDomain(Vue.prototype.$hostname);
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
