import Vue from "vue";
import VueRouter from "vue-router";
import AuthRelay from '../views/AuthRelay.vue';
import Login from "../views/Login.vue";
import Reset from '../views/Reset.vue';
import Identifying from '../views/Reset/Identifying.vue';
import Code from '../views/Reset/Code.vue';
import NewPassword from '../views/Reset/NewPassword.vue';
import Form from "../views/Form.vue";
import AdminPanel from "../views/AdminPanel.vue";
import aUsers from "../views/Users.vue";
import aPartners from '../views/Partners.vue';
import aEditUser from '../views/EditUser.vue';
import aEditPartner from '../views/EditPartner.vue';
import aTickets from '../views/Tickets.vue';


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Form",
        component: Form,
    },
    {
        path: "/subscription",
        name: "FormDirect",
        component: Form
    },
    {
        path: "/auth/relay",
        name: "AuthRelay",
        component: AuthRelay
    },
    {
        path: "/wp-login",
        name: "Login",
        component: Login,
    },
    {
        path: "/wp-reset",
        component: Reset,
        children: [{
            path: '/',
            name: 'ResetStep1',
            component: Identifying
        }, {
            path: '/code',
            name: 'ResetCode',
            component: Code
        }, {
            path: '/new-password',
            name: 'NewPassword',
            component: NewPassword
        }]
    },
    {
        path: "/admin/panel",
        name: "AdminPanel",
        component: AdminPanel,
        redirect: {
            name: "AdminUsers",
        },
        children: [
            {
                name: "AdminUsers",
                path: "users",
                component: aUsers,
            },
            {
                name: 'EditUser',
                path: 'users/:id',
                component: aEditUser
            },
            {
                name: 'AdminPartners',
                path: 'partners',
                component: aPartners
            },
            {
                name: 'EditPartner',
                path: 'partners/:id',
                component: aEditPartner
            },
            {
                name: 'AdminTickets',
                path: 'tickets',
                component: aTickets
            }
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
