<template>
    <div
        class="popup ticket-popup"
        :class="{
            'ticket-popup_open': states.isOpen,
        }"
    >
        <div class="popup__substrate" @click="closePopup"></div>
        <div class="popup__panel">
            <div class="popup__panel__header">
                <h3 class="popup__panel__header__text">Заявка</h3>
                <div class="popup__panel__close-btn" @click="closePopup">
                    <img
                        class="popup__panel__close-btn__image"
                        src="@/assets/img/close.svg"
                    />
                </div>
            </div>
            <div class="popup__panel__body">
                <div class="popup__panel__field" v-if="ticket.category">
                    <p class="popup__panel__label">Категория</p>
                    <p class="popup__panel__text">{{ ticket.category }}</p>
                </div>
                <div class="popup__panel__field" v-if="ticket.subject">
                    <p class="popup__panel__label">Тема</p>
                    <p class="popup__panel__text">{{ ticket.subject }}</p>
                </div>
                <div class="popup__panel__field" v-if="ticket.email">
                    <p class="popup__panel__label">Email</p>
                    <a
                        class="popup__panel__text popup__panel__text_link"
                        :href="`mailto:${ticket.email}`"
                        >{{ ticket.email }}</a
                    >
                </div>
                <div class="popup__panel__field" v-if="ticket.description">
                    <p class="popup__panel__label">Описание</p>
                    <p
                        class="popup__panel__text"
                        :class="{
                            popup__panel__text_scroll: states.longDescription,
                        }"
                    >
                        {{ ticket.description }}
                    </p>
                </div>
                <div
                    class="popup__panel__field popup__panel__field_with-top-devider popup__panel__field_with-bottom-devider popup__panel__field_user-info user-info"
                    :class="{
                        'user-info_without-avatar':
                            !ticket.creator.avatar ||
                            !ticket.creator.avatar.original,
                    }"
                    v-if="ticket.creator"
                    @click="goToProfile(ticket.creator)"
                >
                    <div
                        class="popup__panel__field popup__panel__field_avatar"
                        v-if="
                            ticket.creator.avatar &&
                            ticket.creator.avatar.original
                        "
                    >
                        <img
                            class="popup__panel__image"
                            :src="ticket.creator.avatar.original"
                            :alt="ticket.creator.name"
                        />
                    </div>
                    <div
                        class="popup__panel__field popup__panel__field_name"
                        v-if="ticket.creator.name"
                    >
                        <p class="popup__panel__label">Имя</p>
                        <p class="popup__panel__text">
                            {{ ticket.creator.name }}
                        </p>
                    </div>

                    <div
                        class="popup__panel__field popup__panel__field_role"
                        v-if="ticket.creator.role"
                    >
                        <p class="popup__panel__label">Роль</p>
                        <p class="popup__panel__text">
                            {{ getViewRole(ticket.creator.role) }}
                        </p>
                    </div>
                </div>
                <div class="popup__panel__field" v-if="ticket.created">
                    <p class="popup__panel__label">Дата</p>
                    <p class="popup__panel__text">{{ getViewDate(ticket) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//  Libs
import Beauty from "./../../assets/js/beauty";

//  Pipe
import Pipe from "./../../pipe";

//  CSS
import {} from "@/assets/style/popup.less";

export default {
    name: "TicketPopUp",
    data() {
        return {
            states: {
                isOpen: false,
                longDescription: false,
            },
            ticket: {
                category: "",
                subject: "",
                description: "",
                email: "",
                created: "",
                creator: {
                    avatar: {
                        original: "",
                    },
                    name: "",
                    role: "",
                },
            },
        };
    },
    created() {
        Pipe.$on(Pipe.pipeList.ticketPopUp.name, this.handleEvent);
    },
    methods: {
        handleEvent(event) {
            if (event.action == "view") {
                this.states.longDescription =
                    event.ticket.description.length > 510 ? true : false;
            }

            if (event.eventType == "open") {
                this.ticket = event.ticket;
                this.$_open();
            }
        },
        closePopup() {
            this.$_close();
        },

        getViewDate(ticket) {
            if (ticket && ticket.created) {
                return Beauty.beautyDate(ticket.created);
            }
            return "Не установлено";
        },
        goToProfile(creator) {
            this.$_close();
            let routeName = "EditUser";
            if (creator.role == "partner") {
                routeName = "EditPartner";
            }
            this.$router.push({
                name: routeName,
                params: {
                    id: creator.id,
                },
            });
        },
        getViewRole(role) {
            if (role) {
                switch (role) {
                    case "user":
                        return "Пользователь";
                    case "partner":
                        return "Партнер";
                    case "admin":
                        return "Администратор";
                }
            }
            return "Не установлено";
        },
        $_open() {
            this.states.isOpen = true;
        },
        $_close() {
            this.states.isOpen = false;
            this.ticket = {};
        },
    },
};
</script>

<style lang="less">
.ticket-popup {
    font-size: 14px;
    width: 0px;
    height: 0px;
    top: -9999px;
    left: -1000px;
    opacity: 0;

    &_open {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        opacity: 1;
    }

    .popup__panel {
        &__text {
            &_scroll {
                overflow-y: scroll;
                height: 200px;
                &::-webkit-scrollbar {
                    width: 18px;
                    height: 100%;
                    background: rgb(232, 234, 239);
                }

                &::-webkit-scrollbar-button {
                    width: 18px;
                    height: 18px;
                }
                &::-webkit-scrollbar-button:vertical:decrement {
                    background-image: url("~@/assets/img/scrollbar-up-arrow.svg");
                    background-position: 50% 70%;
                    background-repeat: no-repeat;
                }
                &::-webkit-scrollbar-button:vertical:increment {
                    background-image: url("~@/assets/img/scrollbar-down-arrow.svg");
                    background-position: 50% 30%;
                    background-repeat: no-repeat;
                }
                &::-webkit-scrollbar-thumb {
                    background: #54dca9;
                    border-radius: 10px;
                    border: 4px solid rgb(232, 234, 239);
                    box-sizing: border-box;
                }
            }
        }
    }

    .user-info {
        padding: 20px 0px;
        display: grid;
        grid-template-areas: "avatar . name" "avatar . role";
        grid-template-columns: minmax(0, 80rem) 20px 1fr;
        grid-auto-rows: min-content;
        cursor: pointer;

        &.user-info_without-avatar {
            grid-template-areas: "name" "role";
            grid-template-columns: 1fr;
        }
        .popup__panel {
            &__field {
                &_avatar {
                    grid-area: avatar;
                    width: 80rem;
                    height: 80rem;
                    border-radius: 99999px;
                    overflow: hidden;
                    padding: 0px;
                    margin: 0px;

                    .popup__panel__image {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        object-position: center center;
                    }
                }

                &_name {
                    grid-area: name;
                }

                &_role {
                    grid-area: role;
                }

                &_name,
                &_role {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 0px;

                    .popup__panel {
                        &__label {
                            margin: 0px 10px 0px 0px;
                        }
                        &__text {
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
}
</style>