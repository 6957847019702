import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {},
        headers: {},
        resetData: {
            login: undefined,
            id: undefined,
            code: undefined
        },
    },
    mutations: {
        setUserData(state, userData) {
            state.user = userData;
            localStorage.setItem("user", JSON.stringify(userData));
        },
        setDeviceData(state, deviceData) {
            state.headers = deviceData;
            localStorage.setItem("headers", JSON.stringify(deviceData));
        },
        setResetData(state, data) {
            state.resetData.id = data.id;
            state.resetData.login = data.login;
            localStorage.setItem("reset", JSON.stringify(state.resetData));
        },
        setResetCode(state, code) {
            state.resetData.code = code;
            localStorage.setItem("reset", JSON.stringify(state.resetData));
        },
        clearReset(state) {
            state.resetData = {};
            localStorage.removeItem("reset");
        },
        restore(state) {
            if (!state.headers || Object.keys(state.headers).length == 0)
                state.headers = JSON.parse(localStorage.getItem("headers"));
            if (!state.user || Object.keys(state.user).length == 0)
                state.user = JSON.parse(localStorage.getItem("user"));
            if (!state.resetData || Object.keys(state.resetData).length == 0) {
                state.resetData = JSON.parse(localStorage.getItem("reset"));
            }
        },
    },
    actions: {},
    modules: {},
});
